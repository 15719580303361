import { useEffect, useContext, useState } from "react";
import UserContext from "../../UserContext";
import { useNavigate } from "react-router-dom";
import PreguntasRespuestaConCasilla from "../../components/Cuestionarios/PreguntasRespuestaConCasilla";
import axios from 'axios';
import { useTranslation } from "react-i18next";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import Card from "../../components/Card";
import TituloyFlecha from "../../components/TituloyFlecha";
const Cuestionario10 = () => {

  const navigate = useNavigate();
  const email = useContext(UserContext);
  const [celdasMarcadas, setCeldasMarcadas] = useState({});

  const [t] = useTranslation("global");
  const numero_cuestionario = 10;
  const [todoRespondido, SetTodoRespondido] = useState(true);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [navigate]);


  const preguntas = [
    t("Cuestionario10.Preguntas.1"),
    t("Cuestionario10.Preguntas.2"),
    t("Cuestionario10.Preguntas.3"),
    t("Cuestionario10.Preguntas.4"),
    t("Cuestionario10.Preguntas.5"),
    t("Cuestionario10.Preguntas.6"),
    t("Cuestionario10.Preguntas.7"),
    t("Cuestionario10.Preguntas.8"),
    t("Cuestionario10.Preguntas.9"),
    t("Cuestionario10.Preguntas.10"),
    t("Cuestionario10.Preguntas.11"),
  ];
  const opcionesRespuesta = [
    t("Cuestionario10.Respuestas.1"),
    t("Cuestionario10.Respuestas.2"),
    t("Cuestionario10.Respuestas.3"),
    t("Cuestionario10.Respuestas.4"),
    t("Cuestionario10.Respuestas.5"),
  ];


  const getCeldasMarcadas = (celdasSeleccionadas) => {
    const celdasMarcadasTexto = preguntas.reduce((acc, pregunta, filaIndex) => {
      const respuestaIndex = celdasSeleccionadas[filaIndex];
      const respuestaTexto =
        respuestaIndex !== undefined ? opcionesRespuesta[respuestaIndex] : ""; // Si no se ha respondido, asigna una cadena vacía
      return { ...acc, [filaIndex]: respuestaTexto };
    }, {});

    setCeldasMarcadas(celdasMarcadasTexto);
  };


  const mostrarRespuestasEnConsola = async () => {
    const respuestasEnConsola = (Object.values(celdasMarcadas).map((respuesta) => `"${respuesta}"`)).join(', ');

    console.log('Respuestas:', respuestasEnConsola);
    const hayDosComillasSinValor = /""|\s*""\s*|\[object Object\]/.test(
      respuestasEnConsola
    );

    if (hayDosComillasSinValor) {

      SetTodoRespondido(false);
    } else {

      SetTodoRespondido(true);
      try {
        const response = await axios.post(t("Recursos.ip") + "/respuesta", {
          numeroCuestionario: numero_cuestionario,
          arrayCuestionario: respuestasEnConsola,
          email: email.email,
        });

        console.log(response.data);
        navigate("/Cuestionario/10/intentos");
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  return (
    <div>
      <TituloyFlecha
        title={t("Cuestionario10.Titulo")}
        link={"/Cuestionario/10/intentos"}
      />

      <p>{t("Cuestionario10.Explicacion")}</p>
      <PreguntasRespuestaConCasilla
        listaDePreguntas={preguntas}
        getCeldasMarcadas={getCeldasMarcadas}
        opcionesRespuesta={opcionesRespuesta}
      />
      <br></br>
      {!todoRespondido && (
        <div>
          <p style={{ color: "red" }}>{t("Responde-todo")}</p>
        </div>
      )}
      <br></br>
      <button
        onClick={mostrarRespuestasEnConsola}
        className="link-button"
        style={{ width: "20%", marginTop: "-20%" }}
      >
        {t("Confirmar")}
      </button>
    </div>
  );
};

export default Cuestionario10;
