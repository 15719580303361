import React, { useEffect, useContext, useState } from "react";
import UserContext from "../UserContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import "./PantalladeCarga.css";

const PantalladeCarga = () => {
  const bearer = useContext(UserContext);
  const [t] = useTranslation("global");
  const email = useContext(UserContext);
  const [una, setUna] = useState(false);
  const navigate = useNavigate(); // Usamos useNavigate para la navegación

  useEffect(() => {
    if (email.email && !una) {
      setUna(true);
      enviaralaBD();
    }
  }, [email]); //

  const enviaralaBD = async () => {
    try {
      const response = await axios.post(
        t("Recursos.ip") + "/accounts/authenticate",
        {
          firebaseAccessTokenJSON: bearer.bearer,
          email: email.email,
        }
      );

      console.log("Acabo:",response.data);


      // Navegar a la siguiente página cuando la solicitud sea exitosa
      navigate('/'); // Reemplaza '/siguiente-pagina' con la ruta de tu siguiente página
    } catch (error) {
      console.error("Error:", error);
      //navigate('/'); //Si no conecta que hacemos?
    }
  };

  return (
    <div className="loading-screen">
      <div className="loader"></div>
      <br></br>
      <h3>Sincronizando datos</h3>
    </div>
  );
};

export default PantalladeCarga;
