import React from 'react';
import './InicioAdmin.css';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';

function InicioAdmin() {
  const { t } = useTranslation("global");
  const navigate = useNavigate();
  
  return (
    <div className="InicioAdmin">
      <div className="admin-text"><h1>Administración</h1></div>
      <div className="button-containerInicioAdmin">
        <button className="big-buttonInicioAdmin" onClick={() => navigate('/usuariosAdmin')}>Datos Usuarios</button>
        <button className="big-buttonInicioAdmin" onClick={() => navigate('/CuestionarioAdmin')}>Datos Globales</button>
      </div>
    </div>
  );
}

export default InicioAdmin;
