import axios from "axios";
import "./MisAvisos.css";
import { useState, useContext, useEffect } from "react";
import UserContext from "../../UserContext";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Card from "../../components/Card";
import TituloyFlecha from "../../components/TituloyFlecha";

const MisAvisos = () => {
  const [avisos, setAvisos] = useState([]);
  const email = useContext(UserContext);
  const [t] = useTranslation("global");
  const location = useLocation();

  useEffect(() => {
    if (email) {
      obtenerAvisos();
    }
  }, [email]); // El segundo parámetro [] asegura que se ejecute solo una vez al montar el componente

  function enlaces(nombre, contenido) {
    let enlace;
    if (contenido == "video") {
      //Cambiar aqui en caso de más visualizaciones de contenidos
      enlace = "/video/" + nombre;
    } else {
      enlace = "/pdf/" + nombre;
    }
    return enlace;
  }

  function NombresConEnlaces({ archivos }) {
    return (
      <div>
        <span>{t("Texto-archivos-avisos")} </span>
        {archivos.map((archivo, index) => (
          <span key={index}>
            <Link to={enlaces(archivo.nombre, archivo.contenido)}>
              <a>{archivo.nombreFront}</a>
            </Link>
            {index < archivos.length - 2 ? ", " : ""}
            {index === archivos.length - 2 ? " y " : ""}
          </span>
        ))}
      </div>
    );
  }

  const encontrarCoincidencia = (cadenaTexto) => {
    // Definir las cadenas predefinidas y sus correspondientes respuestas
    const predefinidas = {
      " cuestionario semanal": [" cuestionario semanal", "/Cuestionario/9/intentos"],
      " Cuidado de otras personas": [" Cuidado de otras personas", "/Tecuido/Recomendados"],
      " Mi Autocuidado": [" Mi Autocuidado", "/Mecuido/Recomendados"],
      " Mi planificado": [" Mi autocuidado planificado", "/Mecuido/Tema3.1"],
      " Mi saludable": [" Mi autocuidado saludable", "/Mecuido/Tema2.1"],
      " Mi consciente": [" Mi autocuidado consciente", "/Mecuido/Tema1.1"],
      " Te cuido": [" Te cuido", "/Tecuido/Recomendados"],
      " Te principio": [" Principios del cuidado", "/Tecuido/Tema3.1"],
      " Te planificacion": [" Planificación del cuidado", "/Tecuido/Tema2.1"],
      " Te estrategias": [" Estrategias del cuidado", "/Tecuido/Tema3.1"],

      // Añade más cadenas predefinidas según lo necesites
    };

    // Inicializa una variable para almacenar las respuestas
    let respuestas = [];
    let cadenaOriginal = cadenaTexto;

    // Recorre las cadenas predefinidas
    for (const clave in predefinidas) {
      // Comprueba si la cadena de texto incluye alguna parte de la cadena predefinida
      if (cadenaOriginal.includes(clave)) {
        // Si hay coincidencia, asigna la respuesta correspondiente
        respuestas = predefinidas[clave];
        // Remueve la parte de la cadena predefinida de la cadena original
        cadenaOriginal = cadenaOriginal.replace(clave, '');
        // Rompe el bucle si ya se encontró una coincidencia
        break;
      }
    }

    // Devuelve un objeto con la cadena original y las respuestas
    return { cadenaOriginal, respuestas };
  }

  const obtenerAvisos = async () => {
    try {
      //Console.log(email.email);

      const response = await axios.get(
        t("Recursos.ip") + "/notificacion/historico",
        {
          params: {
            email: email.email,
          },
        }
      );
      console.log("Mis avisos ", response.data);
      const avisosConEnlaces = response.data.map((aviso) => {
        aviso.texto = aviso.texto.replace(/^"(.*)"$/, "$1");
        if (aviso.archivoDTOS.length > 0) {
          return {
            ...aviso,
            extra: true,
          };
        } else {
          return {
            ...aviso,
            extra: false,
          };
        }
      });

      setAvisos(avisosConEnlaces);

      // Marcar los avisos como leídos después de mostrarlos
      try {
        //Linea para ignorar el warning, realmente es necesario delcrar esto aunque podria usarse para saber si se envio correctamente
        // eslint-disable-next-line no-unused-vars
        const response = await axios.post(
          t("Recursos.ip") + "/notificacion/leidos",
          {
            email: email.email,
          }
        );

        //Console.log(response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    } catch (error) {
      console.error("Error al obtener los avisos:", error);
    }
  };

  function renderizarHTML(html) {
    return `${html}`;
  }

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split('-');
    return `${day}-${month}-${year}`;
  };


  return (
    <div className="mis-avisos-container">

      <TituloyFlecha
        title={t("Mis avisos")}
        link={"/"}
      />

      <h4
        style={{
          marginTop: "2.5%",
          marginBottom: "20px",
          fontWeight: "normal",
        }}
      >
        {t("Avisos-recibido")}:
      </h4>
      <ul style={{ marginBottom: "1%", textAlign: "center", padding: 0 }}>
        {avisos.map((aviso, index) => (
          <li key={index} className="aviso-item">
            <strong>{encontrarCoincidencia(renderizarHTML(aviso.texto)).cadenaOriginal + " "}<Link style={{ color: "#518ea6" }} to={encontrarCoincidencia(renderizarHTML(aviso.texto)).respuestas[1]}>{encontrarCoincidencia(renderizarHTML(aviso.texto)).respuestas[0]}</Link></strong>{" "}
            {formatDate(aviso.fecha)}

          </li>
        ))}
      </ul>
    </div>
  );
};

export default MisAvisos;
