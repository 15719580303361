import { useEffect, useContext, useState } from "react";
import UserContext from "../../UserContext";
import Cuadrado from "../../components/Graficos/Cuadrado";
import EscalaHoraria from "../../components/Graficos/EscalaHoraria";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import Card from "../../components/Card";
import "./SuenoPage.css";
import { FaCircle } from "react-icons/fa";
import TituloyFlecha from "../../components/TituloyFlecha";
const SuenoPage = () => {
  const email = useContext(UserContext);
  const [t] = useTranslation("global");
  const [fecha, setFecha] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [nfecha, setNfecha] = useState(0);
  const [listasesiones, setListasesiones] = useState(null);
  const [una, setUna] = useState(false);
  const [sesion, setSesion] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const arrayDeHoras = [
    { valor: "A", minutos: 17, color: "purple", horaInicio: "10:00 AM" },
  ];

  const [cuadradoHovered, setCuadradoHovered] = useState(null);

  // Calcula totalMinutos sumando los minutos de todos los objetos en arrayDeHoras
  const totalMinutos = arrayDeHoras.reduce(
    (total, item) => total + item.minutos,
    0
  );

  const handleCuadradoHover = (
    color,
    minutos,
    horaInicio,
    horaFin,
    valor,
    index
  ) => {
    setCuadradoHovered({ color, minutos, horaInicio, horaFin, valor, index });
  };

  useEffect(() => {
    const consulta_sueno = async () => {
      const currentDate = new Date();
      const oneWeekAgo = new Date();
      oneWeekAgo.setDate(currentDate.getDate() - 365);
      const formattedCurrentDate = currentDate.toISOString().split("T")[0];
      const formattedOneWeekAgo = oneWeekAgo.toISOString().split("T")[0];

      try {
        const response_durancion = await axios.get(
          t("Recursos.ip") + "/sueno",
          {
            params: {
              email: email.email,
              startTime: formattedOneWeekAgo,
              endTime: formattedCurrentDate,
            },
          }
        );
        console.log("Datos: ", response_durancion.data);
        //Console.log(
        //   "Datos procesados: ",
        //   procesarArray(response_durancion.data)
        // );
      
        setSesion(encontrarSesionSuenoN(procesarArray(response_durancion.data), nfecha));
        //Console.log(
        //   "Sesion",
        //   encontrarSesionSuenoN( procesarArray(response_durancion.data), nfecha)
        // );
      } catch (error) {
        console.error("Error:", error);
      }
    };

    function redondearHaciaArriba(fecha) {
      // Obtenemos los minutos y los segundos de la fecha
      const minutos = fecha.getMinutes();
      const segundos = fecha.getSeconds();

      // Redondeamos hacia arriba los segundos dividiendo entre 60 y sumando 1
      const segundosRedondeados = Math.ceil(segundos / 60) * 60;

      // Creamos una nueva fecha con los minutos originales y los segundos redondeados
      const nuevaFecha = new Date(fecha);
      nuevaFecha.setSeconds(0); // Reiniciamos los segundos
      nuevaFecha.setMinutes(
        minutos + Math.floor((segundos + segundosRedondeados) / 60)
      ); // Sumamos los minutos y el cociente de la división
      return nuevaFecha;
    }

    // Función para formatear la fecha al formato 'YYYY-MM-DDThh:mm:ss'
    function formatearFechas(fecha) {
      if (!(fecha instanceof Date)) {
        // Si no es un objeto Date, puedes manejar el error de alguna manera
        console.error("Error: Se esperaba un objeto Date");
        return null;
      }

      const year = fecha.getFullYear();
      const month = String(fecha.getMonth() + 1).padStart(2, "0");
      const day = String(fecha.getDate()).padStart(2, "0");
      const hours = String(fecha.getHours()).padStart(2, "0");
      const minutes = String(fecha.getMinutes()).padStart(2, "0");
      const seconds = String(fecha.getSeconds()).padStart(2, "0");

      return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    }

    // Función para procesar el array de fechas
    function procesarArray(array) {
      // Iteramos sobre cada objeto en el array
      const nuevoArray = array
        .map(item => {
          // Convertimos las cadenas de fecha a objetos Date
          const fechaInicio = new Date(item.t_inicio);
          const fechaFin = new Date(item.t_fin);

          // Redondeamos hacia arriba las fechas
          const nuevaFechaInicio = redondearHaciaArriba(fechaInicio);
          const nuevaFechaFin = redondearHaciaArriba(fechaFin);

          // Formateamos las fechas redondeadas al formato original
          const fechaInicioFormateada = formatearFechas(nuevaFechaInicio);
          const fechaFinFormateada = formatearFechas(nuevaFechaFin);

          // Verificamos si el formateo fue exitoso antes de devolver el nuevo objeto
          if (fechaInicioFormateada && fechaFinFormateada) {
            return {
              ...item,
              t_inicio: fechaInicioFormateada,
              t_fin: fechaFinFormateada,
            };
          } else {
            // Puedes manejar el error de alguna manera
            console.error("Error al formatear la fecha");
            return null;
          }
        })
        .filter(Boolean) // Filtramos los elementos nulos que pueden haber sido devueltos en caso de error
        .filter(item => item.t_inicio !== item.t_fin); // Filtramos los objetos donde t_inicio sea igual a t_fin

      return nuevoArray;
    }

    function encontrarSesionSuenoN(fasesSueno, n) {
      //No lee bien los segundos
      if (n < 0 || n >= fasesSueno.length) {
        return null; // Valor de n no válido.
      }

      // Ordena las fases de sueño por fecha de fin en orden descendente.
      fasesSueno.sort((a, b) => new Date(b.t_fin) - new Date(a.t_fin));

      const sesiones = [];
      let currentSession = [fasesSueno[0]];
      fasesSueno.push(fasesSueno[0])

      for (let i = 0; i < fasesSueno.length; i++) {
        if (i === 0) {
          currentSession.push(fasesSueno[i]);
        } else {
          const fechaFinActual = new Date(fasesSueno[i].t_fin);
          const fechaInicioAnterior = new Date(fasesSueno[i - 1].t_inicio);
          if (fechaInicioAnterior.getTime() === fechaFinActual.getTime()) {
            currentSession.push(fasesSueno[i]);
          } else {
            sesiones.push(currentSession);

            currentSession = [fasesSueno[i]];
          }
        }

      }

      // Ordena las sesiones por fecha de finalización en orden descendente.
      sesiones.sort((a, b) => new Date(b[0].t_fin) - new Date(a[0].t_fin));
      setListasesiones(sesiones);
      if (n < sesiones.length) {
        setFecha(formatearFecha(sesiones[n][0].t_inicio));
        return sesiones[n]; // Devuelve la sesión de sueño solicitada.
      } else {
        return null; // No hay suficientes sesiones para obtener la solicitada.
      }
    }

    // Verificar si el correo electrónico no es nulo antes de llamar a la función
    if (email.email !== null && !una) {
      setUna(true);
      consulta_sueno();
    }
  }, [email, nfecha, t, una]);

  useEffect(() => {
    function transformarFasesSueno(fasesSueno) {
      const colores = {
        SUENO_LIGERO: "#DFCCFB",
        SUENO_REM: "#756AB6",
        SUENO_PROFUNDO: "#BEAFFF",
        DESPIERTO: "#FFF8C9",
      };
      const valores = {
        SUENO_LIGERO: t("Sueño-Ligero"),
        SUENO_REM: "REM",
        SUENO_PROFUNDO: t("Sueño-Profundo"),
        DESPIERTO: "Despierto/a",
      };

      const arrayDeHoras = [];
      let currentValor = null;
      let currentColor = null;
      let currentHoraInicio = null;
      let currentHoraFin = null;
      let currentMinutos = 0;

      fasesSueno.forEach((fase) => {
        if (fase.fasesSuenoEnum === currentValor) {
          currentMinutos += calcularDiferenciaMinutos(
            currentHoraFin,
            fase.t_fin
          );
          currentHoraFin = fase.t_fin; // Actualiza la hora de fin
        } else {
          if (currentValor !== null) {
            arrayDeHoras.push({
              valor: currentValor,
              minutos: currentMinutos,
              color: currentColor,
              horaInicio: currentHoraInicio,
              horaFin: currentHoraFin,
            });
          }

          currentValor = valores[fase.fasesSuenoEnum];
          currentColor = colores[fase.fasesSuenoEnum];
          currentHoraInicio = fase.t_inicio;
          currentHoraFin = fase.t_fin;
          currentMinutos = calcularDiferenciaMinutos(
            currentHoraInicio,
            currentHoraFin
          );
        }
      });

      if (currentValor !== null) {
        arrayDeHoras.push({
          valor: currentValor,
          minutos: currentMinutos,
          color: currentColor,
          horaInicio: currentHoraInicio,
          horaFin: currentHoraFin,
        });
      }

      return arrayDeHoras;
    }
    // Verificar si el correo electrónico no es nulo antes de llamar a la función
    if (sesion) {

      setChartData(transformarFasesSueno(sesion).reverse());
    }
  }, [sesion]);

  function obtenerHora(fechaYHora) {
    const fecha = new Date(fechaYHora);
    const hora = fecha.getHours();
    const minutos = fecha.getMinutes();

    // Si quieres un formato específico (por ejemplo, "05:34"), puedes formatearlo así:
    const horaFormateada = `${hora.toString().padStart(2, "0")}:${minutos
      .toString()
      .padStart(2, "0")}`;

    return horaFormateada;
  }

  function formatearFecha(dateString) {
    const date = new Date(dateString); // Convertir la cadena a un objeto Date
    if (isNaN(date.getTime())) {
      return "Fecha no válida"; // Manejar el caso en que la cadena no sea una fecha válida
    }

    const dia = date.getDate().toString().padStart(2, "0");
    const mes = (date.getMonth() + 1).toString().padStart(2, "0");
    const anio = date.getFullYear();

    return `${dia}-${mes}-${anio}`;
  }

  function cambiarNfecha(n) {
    ////Console.log("lista", listasesiones);
    if ((n < 0 && nfecha > 0) || (n > 0 && nfecha < listasesiones.length - 1)) {
      setSesion(listasesiones[nfecha + n]);
      setFecha(formatearFecha(listasesiones[nfecha + n][0].t_inicio));
      setNfecha(nfecha + n);
    }
  }

  const calcularTiempoTotalSueño = (datos, faseSuenoEnum1, faseSuenoEnum2, faseSuenoEnum3) => {
    // Filtrar los datos para obtener solo aquellos de las fases específicas
    const datosFiltrados = datos.filter((dato) => {
      return dato.valor === faseSuenoEnum1 || dato.valor === faseSuenoEnum2 || dato.valor === faseSuenoEnum3;
    });

    // Calcular el tiempo total en minutos
    const tiempoTotalEnMinutos = datosFiltrados.reduce((total, dato) => {
      const inicio = new Date(dato.horaInicio);
      const fin = new Date(dato.horaFin);
      const duracionEnMinutos = (fin - inicio) / (1000 * 60); // Convertir de milisegundos a minutos
      return total + duracionEnMinutos;
    }, 0);

    // Convertir el tiempo total a horas y minutos
    const horas = Math.floor(tiempoTotalEnMinutos / 60);
    const minutos = tiempoTotalEnMinutos % 60;

    return { horas, minutos };
  };

  const calcularTiempoTotalFase = (datos, faseSuenoEnum) => {
    // Filtrar los datos para obtener solo aquellos de la fase específica
    const datosFiltrados = datos.filter((dato) => dato.valor === faseSuenoEnum);

    // Calcular el tiempo total en minutos
    const tiempoTotalEnMinutos = datosFiltrados.reduce((total, dato) => {
      const inicio = new Date(dato.horaInicio);
      const fin = new Date(dato.horaFin);
      const duracionEnMinutos = (fin - inicio) / (1000 * 60); // Convertir de milisegundos a minutos
      return total + duracionEnMinutos;
    }, 0);

    // Convertir el tiempo total a horas y minutos
    const horas = Math.floor(tiempoTotalEnMinutos / 60);
    const minutos = tiempoTotalEnMinutos % 60;

    return { horas, minutos };
  };

  function calcularDiferenciaMinutos(horaInicio, horaFin) {
    const inicio = new Date(horaInicio);
    const fin = new Date(horaFin);
    return (fin - inicio) / (1000 * 60);
  }

  function calcularDiferenciaHoras(horaInicio, horaFin) {
    const horaInicioParts = horaInicio.split(":");
    const horaFinParts = horaFin.split(":");

    let inicio = new Date(0, 0, 0, horaInicioParts[0], horaInicioParts[1]);
    let fin = new Date(0, 0, 0, horaFinParts[0], horaFinParts[1]);

    if (fin < inicio) {
      fin.setHours(fin.getHours() + 24);
    }

    const diferenciaMs = fin - inicio;

    const horas = Math.floor(diferenciaMs / 3600000); // 1 hora = 3600000 ms
    const minutos = Math.floor((diferenciaMs % 3600000) / 60000); // 1 minuto = 60000 ms

    // Formatear la diferencia en "HH:MM"
    const diferenciaFormateada = `${horas
      .toString()
      .padStart(2, "0")}h ${minutos.toString().padStart(2, "0")}min`;

    return diferenciaFormateada;
  }

  return (
    <div>
      <TituloyFlecha
        title={t("Rutina-Sueno")}
        link={"/MiSalud"}
      />
      <div style={{ marginTop: "1%", marginBottom: "1%", margin: window.innerWidth <= 768 ? "3%" : "1%" }}>
        <button
          className="botongranuralidadSuenoPage"
          style={{ backgroundColor: "#518ea6", color: "white" }}
        >
          {t("Dia")}{" "}
        </button>
        <Link to="/RutinaSueno/semana">
          <button className="botongranuralidadSuenoPage">{t("Semana")}</button>
        </Link>
        <Link to="/RutinaSueno/mes">
          <button className="botongranuralidadSuenoPage"> {t("Mes")}</button>
        </Link>
        <Link to="/RutinaSueno/ano">
          <button className="botongranuralidadSuenoPage">{t("Ano")}</button>
        </Link>
      </div>
      {chartData && chartData.length >= 0 && fecha ? (
        <>
          <div className="centerSuenoPage1">
            {nfecha < listasesiones.length - 1 ? (
              <div style={{ visibility: "visible" }}>
                <button
                  className="botoncircSuenoPage"
                  onClick={() => cambiarNfecha(1)}
                >
                  {t("Anterior")}
                </button>
              </div>
            ) : (
              <div style={{ visibility: "hidden" }}>
                <button onClick={() => cambiarNfecha(1)}>
                  {t("Anterior")}
                </button>
              </div>
            )}
            <p style={{ fontSize: "1.2rem", color: "black" }}>
              <b>{fecha}</b>
            </p>
            {nfecha > 0 ? (
              <div style={{ visibility: "visible" }}>
                <button
                  className="botoncircSuenoPage"
                  onClick={() => cambiarNfecha(-1)}
                >
                  {t("Siguiente")}
                </button>
              </div>
            ) : (
              <div style={{ visibility: "hidden" }}>
                <button onClick={() => cambiarNfecha(-1)}>
                  {t("Siguiente")}
                </button>
              </div>
            )}
          </div>

          <div className="centerSuenoPage2">
            <Card
              content={
                <>
                  <div
                    style={{
                      width: "96%",
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "2%",
                      height: "10%",
                      marginBottom: "40px",
                      marginLeft: "2%",
                    }}
                  >
                    {chartData.map((item, index) => (
                      <Cuadrado
                        key={index}
                        minutos={item.minutos}
                        color={item.color}
                        horaInicio={item.horaInicio}
                        horaFin={item.horaFin}
                        valor={item.valor}
                        widthPercentage={(item.minutos / totalMinutos) * 100}
                        onCuadradoHover={handleCuadradoHover}
                        index={index}
                      />
                    ))}
                  </div>
                  <div
                    className={
                      window.innerWidth > 1000
                        ? ""
                        : "esconderEnPantallaPequena"
                    }
                  >

                    <EscalaHoraria
                      horaInicio={chartData[0].horaInicio}
                      horaFin={chartData[chartData.length - 1].horaFin}
                      ancho={"102%"}
                    />
                  </div>
                  <div>
                    <div
                      style={{
                        marginLeft: "5%",
                        color: "black",
                        marginTop: "0%",
                      }}
                    >
                      {cuadradoHovered && cuadradoHovered.color ? (
                        <p>
                          <b>{t("Hora-Inicio")}:</b>{" "}
                          {obtenerHora(cuadradoHovered.horaInicio)}
                          <b> {t("Hora-Fin")}:</b>{" "}
                          {obtenerHora(cuadradoHovered.horaFin)}
                          <br />
                          <b>{t("Duracion")}:</b> {cuadradoHovered.minutos}min{" "}
                          <b>{t("Fase")}:</b> {cuadradoHovered.valor}
                        </p>
                      ) : (
                        <div>
                          <br />
                          <p>
                            {windowWidth > 1200 ? (
                              t("Selecciona-grafico")
                            ) : (
                              t("Selecciona-grafico2")
                            )}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              }
              className={"cardFasesSuenoSuenoPage"}
            >
              {" "}
            </Card>

            <div style={{ color: "black" }}></div>
          </div>

          <div className="centerSuenoPage3" style={{ marginTop: "-8%" }}>
            <div style={{ marginLeft: "2%", marginRight: "2%" }}>
              <Card
                content={
                  <div style={{ color: "black" }}>
                    <h4 style={{ textAlign: "center", marginTop: "0.5%" }}>
                      {t("Duracion-sueno")}{" "}
                    </h4>
                    <div
                      style={{

                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "1%",
                      }}
                    >
                      <div
                        style={{ flex: 1, textAlign: "left", marginLeft: "3%" }}
                      >
                        <b>{t("Hora-de-acostarse")}</b>
                        <br />
                        {obtenerHora(chartData[0].horaInicio)}
                      </div>
                      <div
                        style={{
                          flex: 1,
                          textAlign: "right",
                          marginRight: "3%",
                        }}
                      >
                        <b>{t("Hora-de-despertarse")}</b>
                        <br />
                        {obtenerHora(chartData[chartData.length - 1].horaFin)}
                      </div>
                    </div>
                    <div style={{ textAlign: "center", marginTop: "0%" }}>
                      <b>{t("Tiempo-durmiendo")}</b>
                      <br />
                      {
                        calcularTiempoTotalSueño(chartData, t("Sueño-Ligero"), t("Sueño-Profundo"), "REM")
                          .horas
                      }
                      h{" "}
                      {
                        calcularTiempoTotalSueño(chartData, t("Sueño-Ligero"), t("Sueño-Profundo"), "REM")
                          .minutos
                      }
                      min
                    </div>
                  </div>
                }
                className={"cardInfoSueno"}
              ></Card>
            </div>
            <br></br>
            <div style={{ marginRight: "2%", marginLeft: "2%" }}>
              <Card
                content={
                  <div style={{ color: "black" }}>
                    <h4 className="titulocardsSuenoPageDuracion"
                      style={{

                        textAlign: "center",
                        marginBottom: "1%",
                        marginTop: "0.5%",
                      }}
                    >
                      {t("Datos-sobre-etapas")}
                    </h4>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(2, 1fr)",
                        gridTemplateRows: "repeat(2, auto)",
                        gridGap: "10%",
                        marginTop: "1%",
                      }}
                    >
                      <div>
                        <b>
                          {" "}
                          <FaCircle style={{ color: "#DFCCFB", }} />
                          {" "}{t("Sueño-Ligero")}
                        </b>
                        <br />
                        {
                          calcularTiempoTotalFase(chartData, t("Sueño-Ligero"))
                            .horas
                        }
                        h{" "}
                        {
                          calcularTiempoTotalFase(chartData, t("Sueño-Ligero"))
                            .minutos
                        }
                        min
                      </div>
                      <div>

                        <b>
                          <FaCircle style={{ color: "#BEAFFF" }} />
                          {" "}{t("Sueño-Profundo")}
                        </b>
                        <br />
                        {
                          calcularTiempoTotalFase(chartData, t("Sueño-Profundo"))
                            .horas
                        }
                        h{" "}
                        {
                          calcularTiempoTotalFase(chartData, t("Sueño-Profundo"))
                            .minutos
                        }
                        min
                      </div>
                      <div>
                        <b>
                          <FaCircle style={{ color: "#FFF8C9" }} />
                          {" "}{t("Despierto/a")}
                        </b>
                        <br />
                        {
                          calcularTiempoTotalFase(chartData, "Despierto/a")
                            .horas
                        }
                        h{" "}
                        {
                          calcularTiempoTotalFase(chartData, "Despierto/a")
                            .minutos
                        }
                        min
                      </div>
                      <div>
                        <b>
                          <FaCircle style={{ color: "#756AB6" }} />
                          {" "}{t("REM")}
                        </b>
                        <br />
                        {calcularTiempoTotalFase(chartData, "REM").horas}h{" "}
                        {calcularTiempoTotalFase(chartData, "REM").minutos}min
                      </div>
                    </div>
                  </div>
                }
                className={"cardInfoSueno"}
              ></Card>
            </div>
          </div>
        </>
      ) : (
        <div style={{ color: "black", marginTop: "20%" }}>
          <b>{t("Conectando-BD")}</b>
        </div>
      )}

      <br></br>
    </div>
  );
};

export default SuenoPage;
