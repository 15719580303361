import React, { useState } from 'react';
import './PreguntasColumnasIntercaladas.css';

function PreguntasColumnasIntercaladas({ questions, onRespuestasChange, answerOptions }) {

  const [selectedAnswers, setSelectedAnswers] = useState(Array.from({ length: questions.length }, () => null));

  const handleAnswerClick = (questionIndex, answer) => {
    const newSelectedAnswers = [...selectedAnswers];
    newSelectedAnswers[questionIndex] = answer;
    setSelectedAnswers(newSelectedAnswers);

    // Llama a la función de devolución de llamada con las nuevas respuestas
    onRespuestasChange(newSelectedAnswers);
  };
  const formatAnswerText = (text) => {
    return text.split('\n').map((item, index) => (
      <React.Fragment key={index}>
        {item}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <table className="custom-table">
      <thead>
        <tr>
          <th style={{ textAlign: "center" }}> </th> {/*Primera Columna*/}
          {answerOptions.map((answer, answerIndex) => (
            <th style={{ textAlign: "center", fontSize: window.innerWidth < 768 ? "10px" : "inherit" }} key={answerIndex}>
              {formatAnswerText(answer)}
            </th>
          ))}
          <th style={{ textAlign: "center" }}> </th> {/*//Ultima Columna */}
        </tr>
      </thead>
      <tbody>
        {questions.map((question, questionIndex) => (
          <tr key={questionIndex}>
            <td style={{fontSize: window.innerWidth < 768 ? "10px" : "inherit"}}>{question}</td>
            {answerOptions.map((answer, answerIndex) => (
              <td key={answerIndex}>
                <div className="answer-box-container" >
                  <div
                    className={`answer-box ${selectedAnswers[questionIndex] === answer ? 'selected' : ''}`}
                    onClick={() => handleAnswerClick(questionIndex, answer)}
                    
                  >
                    {selectedAnswers[questionIndex] === answer && (
                      <span style={{fontSize: window.innerWidth < 768 ? "10px" : "inherit"}} className="answer-text">{formatAnswerText(answer)}</span>
                    )}
                  </div>
                </div>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>

  );
}

export default PreguntasColumnasIntercaladas;
