import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './EstadoAnimoGrafico.css';

const EstadoAnimoGrafico = ({ yValues, xValues, images, granularity }) => {
  const { t } = useTranslation("global");
  const [tooltip, setTooltip] = useState({ display: false, x: 0, y: 0, value: 0, date: '' });
  const [pathLength, setPathLength] = useState(null);

  const width = 800;
  const height = 400;
  const padding = 60;

  useEffect(() => {
    const path = document.querySelector('.graph-line');
    if (path) {
      setPathLength(path.getTotalLength());
    }
  }, [yValues, xValues]);

  const locale = {
    dateTime: "%A, %e de %B de %Y, %X",
    date: "%d/%m/%Y",
    time: "%H:%M:%S",
    periods: ["AM", "PM"],
    days: [
      t("DiasSemana.Domingo"),
      t("DiasSemana.Lunes"),
      t("DiasSemana.Martes"),
      t("DiasSemana.Miercoles"),
      t("DiasSemana.Jueves"),
      t("DiasSemana.Viernes"),
      t("DiasSemana.Sabado"),
    ],
    shortDays: [
      t("shortDiasSemana.Domingo"),
      t("shortDiasSemana.Lunes"),
      t("shortDiasSemana.Martes"),
      t("shortDiasSemana.Miercoles"),
      t("shortDiasSemana.Jueves"),
      t("shortDiasSemana.Viernes"),
      t("shortDiasSemana.Sabado"),
    ],
    months: [
      t("Meses.Enero"),
      t("Meses.Febrero"),
      t("Meses.Marzo"),
      t("Meses.Abril"),
      t("Meses.Mayo"),
      t("Meses.Junio"),
      t("Meses.Julio"),
      t("Meses.Agosto"),
      t("Meses.Septiembre"),
      t("Meses.Octubre"),
      t("Meses.Noviembre"),
      t("Meses.Diciembre"),
    ],
    shortMonths: [
      t("shortMeses.Enero"),
      t("shortMeses.Febrero"),
      t("shortMeses.Marzo"),
      t("shortMeses.Abril"),
      t("shortMeses.Mayo"),
      t("shortMeses.Junio"),
      t("shortMeses.Julio"),
      t("shortMeses.Agosto"),
      t("shortMeses.Septiembre"),
      t("shortMeses.Octubre"),
      t("shortMeses.Noviembre"),
      t("shortMeses.Diciembre"),
    ],
  };

  const xScale = (value) => {
    const index = xValues.indexOf(value);
    return padding + index * ((width - 2 * padding) / (xValues.length - 1));
  };

  const yScale = (value) => {
    const clampedValue = Math.max(value, 0); // Aseguramos que el valor no baje de 0
    return height - padding - clampedValue * ((height - 2 * padding) / 5); // Ajustamos la escala de 0 a 5
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (granularity === "semana") {
      const dayName = locale.shortDays[date.getDay()];
      const dayNumber = date.getDate();
      return `${dayName} ${dayNumber}`;
    } else if (granularity === "mes") {
      return date.getDate();
    } else if (granularity === "ano") {
      const monthName = locale.shortMonths[date.getMonth()];
      return monthName;
    }
    return dateString; // Fallback por si no se reconoce la granularidad
  };

  const points = yValues.map((y, i) => `${xScale(xValues[i])},${yScale(y)}`).join(' ');

  const fillAreaPoints = `${xScale(xValues[0])},${height - padding} ${points} ${xScale(xValues[xValues.length - 1])},${height - padding}`;

  const handleMouseOver = (e, value, date) => {
    setTooltip({
      display: true,
      x: e.clientX,
      y: e.clientY,
      value,
      date,
    });
  };

  const handleMouseOut = () => {
    setTooltip({
      display: false,
      x: 0,
      y: 0,
      value: 0,
      date: '',
    });
  };

  const moodLabels = {
    1: t("Estado-Animo.Muy-mal"),
    2: t("Estado-Animo.Mal"),
    3: t("Estado-Animo.Normal"),
    4: t("Estado-Animo.Bien"),
    5: t("Estado-Animo.Muy-bien"),
  };

  const formatDateForTooltip = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <svg width="100%" height="100%" viewBox={`0 0 ${width} ${height}`}>
        {/* Ejes */}
        <line x1={padding} y1={height - padding} x2={width - padding} y2={height - padding} stroke="black" />
        <line x1={padding} y1={height - padding} x2={padding} y2={padding / 2} stroke="black" /> {/* Extender la línea del eje Y */}

        {/* Líneas horizontales */}
        {Array.from({ length: 5 }, (_, i) => i + 1).map((y) => (
          <line
            key={y}
            x1={padding}
            y1={yScale(y)}
            x2={width - padding}
            y2={yScale(y)}
            stroke="#ddd"
            strokeDasharray="5,5"
          />
        ))}

        {/* Relleno del área debajo de la línea */}
        <polygon
          key={`fill-${xValues}-${yValues}`}
          points={fillAreaPoints}
          fill="#518ea6"
          opacity="0.3"
          className="fill-area"
        />

        {/* Línea del gráfico con animación */}
        <polyline
          key={`line-${xValues}-${yValues}`}
          className="graph-line"
          fill="none"
          stroke="#518ea6"
          strokeWidth="2"
          points={points}
          style={{ strokeDasharray: pathLength, strokeDashoffset: pathLength }}
        />

        {/* Puntos redondos en los datos */}
        {yValues.map((y, i) => (
          y !== 0 && (
            <circle
              key={`point-${i}`}
              cx={xScale(xValues[i])}
              cy={yScale(y)}
              r={4}
              fill="#518ea6"
              className="data-point"
              onMouseOver={(e) => handleMouseOver(e, y, xValues[i])}
              onMouseOut={handleMouseOut}
            />
          )
        ))}

        {/* Etiquetas de los ejes X */}
        {xValues.map((x, i) => (
          <text key={i} x={xScale(x)} y={height - padding + 20} textAnchor="middle" style={{ fontSize: '12px' }}>
            {formatDate(x)}
          </text>
        ))}

        {/* Imágenes del eje Y */}
        {Array.from({ length: 6 }, (_, i) => i).map((y, i) => (
          <image key={i} href={images[i]} x={padding - 40} y={yScale(y) - 20} height="30px" width="30px" />
        ))}
      </svg>
      {tooltip.display && (
        <div
          className="tooltip"
          style={{
            top: tooltip.y - 120,
            left: tooltip.x + 10,
          }}
        >
          <div>
            {t("Estado-Animo-Grafico.El-dia") +
              " " +
              formatDateForTooltip(tooltip.date) +
              " " +
              t("Estado-Animo-Grafico.te-encontrabas") +
              " " +
              moodLabels[tooltip.value].toLowerCase() +
              "."}
          </div>
        </div>
      )}
    </div>
  );
};

export default EstadoAnimoGrafico;
