import React from 'react';
import Recomendaciones_Icono from "../../Images/Recomendaciones_Icono.png";
import './RecomendacionesCarta.css';
import Card from '../Card';
import { useTranslation } from "react-i18next";
function RecomendacionesCarta() {
  const [t] = useTranslation("global");
  return (
    <div style={{ fontSize: "2vh" }}>
    <div style={{ width: "30vh", margin: "0 auto", marginTop: "-2.5vh" }}>
      <Card content={<h1 style={{
        fontSize: "3vh",
        marginTop: "0.5vh",
        color: "white"
      }}>{t("El-Cuidado")} </h1>}
          alto="50%"
          ancho="100%" className={"cardRecomendacionesCarta"} />
      </div>
      <div style={{  marginTop:"0.5vh"}}>
      {/* Texto en la parte superior */}
      <p style={{ color:"black"}}>{t("TeCuido.texto")}</p>
      
      <img style={{ marginBottom: "1vh"}} src={Recomendaciones_Icono} alt={t("TeCuido.Icono")}  className="imagenRecomendacionesCarta"/>
    </div>
    </div>
  )
}
export default RecomendacionesCarta;