import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import "./CircularProgressBar.css"
import { easeQuadInOut } from "d3-ease";
import AnimatedProgressProvider from "./AnimatedProgressProvider"
const CircularProgressBarSueno = ({ progress,maxValue, texto ,colortexto, colorfondo, colorpath, ancho, alto}) => {
  const trailColor = "#E5B8F4";  
  function convertirMinutosAHorasYMinutos(minutos) {
    if (typeof minutos !== 'number' || minutos < 0) {
      return "Entrada no válida";
    }
  
    const horas = Math.floor(minutos / 60);
    const minutosRestantes = minutos % 60;
  
    const horaPlural =  "h";
    const minutoPlural =  "min";
  
    const resultado = `${horas}${horaPlural} ${minutosRestantes}${minutoPlural}`;
  
    return resultado;
  }

  return (
    <div className="circular-progress-bar" style={{width:ancho, height:alto}} > {/* Aplica la clase CSS al contenedor */}
          <AnimatedProgressProvider
        valueStart={0}
        valueEnd={progress}
        duration={1.4}
        easingFunction={easeQuadInOut}
        // repeat
      >
        {progress => {
          const roundedValue = Math.round(progress);
          return (
            <CircularProgressbar
              value={progress}
              maxValue={maxValue}
              
              text={`${convertirMinutosAHorasYMinutos(roundedValue)}`}
              background
              backgroundPadding={6}
              /* This is important to include, because if you're fully managing the
        animation yourself, you'll want to disable the CSS animation. */

        styles={
          buildStyles({
          backgroundColor: colorfondo,
          textColor: colortexto,
          textSize:"0.95rem",
          pathColor: colorpath,
          trailColor: trailColor,
           pathTransition: "none"})}
            />
          );
        }}
      </AnimatedProgressProvider>
      </div>
      
      );
        }

export default CircularProgressBarSueno;