import React, { useState } from 'react';

function PreguntaRespuestaSiNo({ pregunta, onRespuestaSubmit, si, no }) {
  // Utilizamos el estado para almacenar la respuesta del usuario
  const [respuesta, setRespuesta] = useState(null);

  // Manejar el clic en el botón "Sí"
  const handleSiClick = () => {
    setRespuesta("Sí");
    onRespuestaSubmit(pregunta, "Sí");
  }

  // Manejar el clic en el botón "No"
  const handleNoClick = () => {
    setRespuesta("No");
    onRespuestaSubmit(pregunta, "No");
  }

  return (
    <table style={{ width: '100%',  borderCollapse: 'collapse' }}>
      <tbody>
        <tr>
          <td style={{ width: '90%', height: "5vh", fontSize:window.innerWidth <= 768 ? "12px" : "16px", textAlign: 'justify'}}>
            <b>{pregunta}</b>
          </td>
          <td style={{ width: '10%', fontSize: '16px', textAlign: 'justify' }}>
          <div style={{display:"flex", flexDirection:"row"}}>
            <button
              onClick={handleSiClick}
              style={{ marginRight: '10px',marginLeft: '10px', backgroundColor: respuesta === 'Sí' ? 'lightblue' : 'white',minWidth:window.innerWidth <= 768 ? "100%" : "3rem", borderColor: respuesta === 'Sí' ? 'lightblue' : '#D3D3D3' }}
            >
              {si}
            </button>
            <button
              onClick={handleNoClick}
              style={{ backgroundColor: respuesta === 'No' ? 'lightblue' : 'white',marginLeft: '10px',minWidth:window.innerWidth <= 768 ? "100%" : "3rem", borderColor: respuesta === 'No' ? 'lightblue' : '#D3D3D3' }}
            >
              {no}
            </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
  

}

export default PreguntaRespuestaSiNo;
