import { useEffect, useContext, useState } from "react";
import UserContext from "../../UserContext";
import { useNavigate } from "react-router-dom";
import PreguntasRespuestaConCasilla from "../../components/Cuestionarios/PreguntasRespuestaConCasilla";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import Card from "../../components/Card";
import TextoConCard from "../../components/Cuestionarios/TextoConCard";
import PreguntasColumnasIntercaladas from "../../components/Cuestionarios/PreguntasColumnasIntercaladas";
import TituloyFlecha from "../../components/TituloyFlecha";
import CheckboxesCuestionarioSD from "../../components/Cuestionarios/CheckboxesCuestionarioSD";
const Cuestionario3 = () => {

  const navigate = useNavigate();
  const email = useContext(UserContext);
  const [t] = useTranslation("global");
  const [todoRespondido, SetTodoRespondido] = useState(true);
  const numero_cuestionario = 3;
  const [parte2, setParte2] = useState(false);
  const [respuestas1, setRespuestas1] = useState({});
  const [respuestas2, setRespuestas2] = useState({});
  const [respuestas3, setRespuestas3] = useState({});
  const [respuestas4, setRespuestas4] = useState({});
  const [respuestas, setRespuestas] = useState({});
  const [pagina, sePagina] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [navigate]);


  const handleRespuestasChange1 = (nuevasRespuestas) => {
    setRespuestas1(nuevasRespuestas);
    // Aquí puedes realizar cualquier otra acción con las respuestas
    console.log("Respuestas: " + nuevasRespuestas);
  };
  const handleRespuestasChange2 = (nuevasRespuestas) => {
    setRespuestas2(nuevasRespuestas);
    // Aquí puedes realizar cualquier otra acción con las respuestas
    console.log("Respuestas: " + nuevasRespuestas);
  };
  const handleRespuestasChange3 = (nuevasRespuestas) => {
    setRespuestas3(nuevasRespuestas);
    // Aquí puedes realizar cualquier otra acción con las respuestas
    console.log("Respuestas: " + nuevasRespuestas);
  };

  const handleRespuestasChange4 = (nuevasRespuestas) => {
    setRespuestas4(nuevasRespuestas);
    // Aquí puedes realizar cualquier otra acción con las respuestas
    console.log("Respuestas: " + nuevasRespuestas);
  };

  function paginar(valor) {
    SetTodoRespondido(true);
    if (valor > 0 && pagina < 6) {
      sePagina(pagina + valor);
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else if (valor < 0 && pagina > 0) {
      sePagina(pagina + valor);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }

  const answerOptions = [
    t("Cuestionario3.Respuestas1.1"),
    t("Cuestionario3.Respuestas1.2"),
    t("Cuestionario3.Respuestas1.3"),
    t("Cuestionario3.Respuestas1.4"),
    t("Cuestionario3.Respuestas1.5"),
  ];

  const answerOptions2 = [
    t("Cuestionario3.Respuestas2.1"),
    t("Cuestionario3.Respuestas2.2"),
    t("Cuestionario3.Respuestas2.3"),
    t("Cuestionario3.Respuestas2.4"),
    t("Cuestionario3.Respuestas2.5"),
  ];

  const preguntas = [
    t("Cuestionario3.Preguntas.1"),
    t("Cuestionario3.Preguntas.2"),
    t("Cuestionario3.Preguntas.3"),
    t("Cuestionario3.Preguntas.4"),
    t("Cuestionario3.Preguntas.5"),
    t("Cuestionario3.Preguntas.6"),
    t("Cuestionario3.Preguntas.7"),
    t("Cuestionario3.Preguntas.8"),
    t("Cuestionario3.Preguntas.9"),
    t("Cuestionario3.Preguntas.10"),
    t("Cuestionario3.Preguntas.11"),
    t("Cuestionario3.Preguntas.12"),
    t("Cuestionario3.Preguntas.13"),
    t("Cuestionario3.Preguntas.14"),
    t("Cuestionario3.Preguntas.15"),
    t("Cuestionario3.Preguntas.16"),
    t("Cuestionario3.Preguntas.17"),
    t("Cuestionario3.Preguntas.18"),
    t("Cuestionario3.Preguntas.19"),
    t("Cuestionario3.Preguntas.20"),
    t("Cuestionario3.Preguntas.21"),
    t("Cuestionario3.Preguntas.22"),
  ];
  const preguntas1 = [
    t("Cuestionario3.Preguntas.1"),
    t("Cuestionario3.Preguntas.2"),
    t("Cuestionario3.Preguntas.3"),
    t("Cuestionario3.Preguntas.4"),
    t("Cuestionario3.Preguntas.5"),
    t("Cuestionario3.Preguntas.6"),
    t("Cuestionario3.Preguntas.7"),
  ];

  const preguntas2 = [
    t("Cuestionario3.Preguntas.8"),
    t("Cuestionario3.Preguntas.9"),
    t("Cuestionario3.Preguntas.10"),
    t("Cuestionario3.Preguntas.11"),
    t("Cuestionario3.Preguntas.12"),
    t("Cuestionario3.Preguntas.13"),
    t("Cuestionario3.Preguntas.14"),
  ];
  const preguntas3 = [
    t("Cuestionario3.Preguntas.15"),
    t("Cuestionario3.Preguntas.16"),
    t("Cuestionario3.Preguntas.17"),
    t("Cuestionario3.Preguntas.18"),
    t("Cuestionario3.Preguntas.19"),
    t("Cuestionario3.Preguntas.20"),
    t("Cuestionario3.Preguntas.21"),
  ];

  const preguntas4 = [t("Cuestionario3.Preguntas.22")];
  const opcionesRespuesta1 = [
    t("Cuestionario3.Respuestas1.1"),
    t("Cuestionario3.Respuestas1.2"),
    t("Cuestionario3.Respuestas1.3"),
    t("Cuestionario3.Respuestas1.4"),
    t("Cuestionario3.Respuestas1.5"),
  ];
  const opcionesRespuesta2 = [
    t("Cuestionario3.Respuestas2.1"),
    t("Cuestionario3.Respuestas2.2"),
    t("Cuestionario3.Respuestas2.3"),
    t("Cuestionario3.Respuestas2.4"),
    t("Cuestionario3.Respuestas2.5"),
  ];
  const mostrarRespuestasEnConsolaMovil = async () => {
    const respuestasEnTexto = preguntas
      .map((pregunta) => `"${respuestas[pregunta] || " "}"`)
      .join(",");
    const puntuacion =
      '"' +
      traducirArray(
        preguntas.map((pregunta) => `${respuestas[pregunta] || " "}`)
      ).join('","') +
      '"';
    console.log("Respuestas en texto:", respuestasEnTexto, puntuacion);
    const hayDosComillasSinValor = /""|\s*" "\s*|\[object Object\]/.test(
      respuestasEnTexto
    );

    if (hayDosComillasSinValor) {
      SetTodoRespondido(false);
    } else {
      SetTodoRespondido(true);
      try {
        const response = await axios.post(t("Recursos.ip") + "/respuesta", {
          numeroCuestionario: numero_cuestionario,
          arrayCuestionario: respuestasEnTexto,
          arrayPuntuacionCuestionario: puntuacion,
          email: email.email,
        });

        console.log(response.data);
        navigate("/Cuestionario/3/intentos");
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };
  const mostrarRespuestasEnConsola = async () => {
    try {
      const puntuacion =
        '"' +
        traducirArray(respuestas1).join('","') +
        '","' +
        traducirArray(respuestas2).join('","') +
        '","' +
        traducirArray(respuestas3).join('","') +
        '","' +
        traducirArray(respuestas4).join('","') +
        '"';

      const resultado =
        '"' +
        respuestas1.join('","') +
        '","' +
        respuestas2.join('","') +
        '","' +
        respuestas3.join('","') +
        '","' +
        respuestas4.join('","') +
        '"';

      console.log(resultado, puntuacion);
      const hayDosComillasSinValor = /""|\s*""\s*|\[object Object\]/.test(
        resultado
      );

      if (hayDosComillasSinValor) {
        SetTodoRespondido(false);
      } else {
        SetTodoRespondido(true);
        try {
          const response = await axios.post(t("Recursos.ip") + "/respuesta", {
            numeroCuestionario: numero_cuestionario,
            arrayCuestionario: resultado,
            arrayPuntuacionCuestionario: puntuacion,
            email: email.email,
          });

          console.log(response.data);
          navigate("/Cuestionario/3/intentos");
        } catch (error) {
          console.error("Error:", error);
        }
      }
    } catch (error) {
      SetTodoRespondido(false);
    }
  };

  function cambiarparte(valor) {
    SetTodoRespondido(true);
    setParte2(valor);
  }

  const traducirArray = (array) => {
    return array.map((elemento) => traducirAValor(elemento));
  };
  const traducirAValor = (cadena) => {
    switch (cadena) {
      case t("Cuestionario3.Respuestas1.1"):
        return 1;
      case t("Cuestionario3.Respuestas1.2"):
        return 2;
      case t("Cuestionario3.Respuestas1.3"):
        return 3;
      case t("Cuestionario3.Respuestas1.4"):
        return 4;
      case t("Cuestionario3.Respuestas1.5"):
        return 5;
      case t("Cuestionario3.Respuestas2.1"):
        return 1;
      case t("Cuestionario3.Respuestas2.2"):
        return 2;
      case t("Cuestionario3.Respuestas2.3"):
        return 3;
      case t("Cuestionario3.Respuestas2.4"):
        return 4;
      case t("Cuestionario3.Respuestas2.5"):
        return 5;
      default:
        return cadena; // Valor por defecto o manejo de error
    }
  };
  // Función para manejar las respuestas de cada instancia de PreguntaRespuesta
  const handleRespuestaSubmit = (pregunta, respuesta) => {
    // Actualizar el objeto de respuestas con la respuesta correspondiente
    setRespuestas((prevRespuestas) => ({
      ...prevRespuestas,
      [pregunta]: respuesta,
    }));
  };

  return (
    <div>
      <TituloyFlecha
        title={t("Cuestionario3.Titulo")}
        link={"/Cuestionario/3/intentos"}
      />
      <div style={{ marginTop: "2%", width: "80%", margin: "auto" }}>
        <p style={{ textAlign: "justify" }}>
          {t("Cuestionario3.Intro")}
          {t("Cuestionario3.Intro2")}
        </p>
      </div>

      <div style={{ display: pagina === 0 ? "block" : "none" }}>
        {windowWidth > 800 ? (
          <PreguntasColumnasIntercaladas
            questions={preguntas1}
            onRespuestasChange={handleRespuestasChange1}
            answerOptions={answerOptions}
          />
        ) : (
          <CheckboxesCuestionarioSD
            preguntas={preguntas1}
            respuestasComunes={answerOptions}
            onRespuestaSubmit={handleRespuestaSubmit}
          />
        )}
      </div>
      <div style={{ display: pagina === 1 ? "block" : "none" }}>
        {windowWidth > 800 ? (
          <PreguntasColumnasIntercaladas
            questions={preguntas2}
            onRespuestasChange={handleRespuestasChange2}
            answerOptions={answerOptions}
          />
        ) : (
          <CheckboxesCuestionarioSD
            preguntas={preguntas2}
            respuestasComunes={answerOptions}
            onRespuestaSubmit={handleRespuestaSubmit}
          />
        )}
      </div>
      <div style={{ display: pagina === 2 ? "block" : "none" }}>
        {windowWidth > 800 ? (
          <PreguntasColumnasIntercaladas
            questions={preguntas3}
            onRespuestasChange={handleRespuestasChange3}
            answerOptions={answerOptions}
          />
        ) : (
          <CheckboxesCuestionarioSD
            preguntas={preguntas3}
            respuestasComunes={answerOptions}
            onRespuestaSubmit={handleRespuestaSubmit}
          />
        )}
      </div>
      <div style={{ display: pagina === 3 ? "block" : "none" }}>
        {windowWidth > 800 ? (
          <PreguntasColumnasIntercaladas
            questions={preguntas4}
            onRespuestasChange={handleRespuestasChange4}
            answerOptions={answerOptions2}
          />
        ) : (
          <CheckboxesCuestionarioSD
            preguntas={preguntas4}
            respuestasComunes={answerOptions2}
            onRespuestaSubmit={handleRespuestaSubmit}
          />
        )}
      </div>

      {!todoRespondido && (
        <div>
          <br />
          <p style={{ color: "red" }}>{t("Responde-todo")}</p>
        </div>
      )}
      <div style={{ display: "flex" }}>
        <button
          onClick={() => paginar(-1)}
          style={{
            marginTop: "30px",
            marginRight: window.innerWidth <= 768 ? "10%" : "-25%",
            width: window.innerWidth <= 768 ? "50%" : "20%",
            // visibility: pagina === 0 ? "hidden" : "visible",
            background: pagina === 0 ? " #A5BBC1" : "",
          }}
          className="link-button"
        >
          {t("Atras")}
        </button>

        {pagina < 3 && (
          <button
            onClick={() => paginar(1)}
            style={{
              marginTop: "30px",
              marginLeftt: window.innerWidth <= 768 ? "-10%" : "25%",
              width: window.innerWidth <= 768 ? "50%" : "20%",
              visibility: pagina === 3 ? "hidden" : "visible",
            }}
            className="link-button"
          >
            {t("Continuar")}
          </button>
        )}

        {pagina === 3 && (
          <>
            {windowWidth > 800 ? (
              <button
                onClick={mostrarRespuestasEnConsola}
                className="link-button"
                style={{
                  marginTop: "30px",
                  width: window.innerWidth <= 768 ? "50%" : "20%",
                }}
              >
                {t("Confirmar")}
              </button>
            ) : (
              <button
                onClick={mostrarRespuestasEnConsolaMovil}
                className="link-button"
                style={{
                  marginTop: "30px",
                  width: window.innerWidth <= 768 ? "50%" : "20%",
                }}
              >
                {t("Confirmar")}
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Cuestionario3;
