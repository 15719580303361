import { useEffect, useContext, useState } from "react";
import UserContext from "../../UserContext";
import { useNavigate } from "react-router-dom";
import PreguntasRespuestasCheckboxesHorizontal from "../../components/Cuestionarios/PreguntasRespuestasCheckboxesHorizontal";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import Card from "../../components/Card";
import "./CuestionarioSocioDemo.css";
import CheckboxesCuestionarioSD from "../../components/Cuestionarios/CheckboxesCuestionarioSD";
import TextoConCard from "../../components/Cuestionarios/TextoConCard";
import EscalaPEI from "../../Images/EscalaPEI.png";
import TituloyFlecha from "../../components/TituloyFlecha";
const Cuestionario6 = () => {
  const navigate = useNavigate();
  const email = useContext(UserContext);
  const [pagina, sePagina] = useState(0);
  const [t] = useTranslation("global");
  const numero_cuestionario = 6;
  const [todoRespondido, SetTodoRespondido] = useState(true);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [navigate]);

  const preguntas = [
    t("Cuestionario6.Preguntas.1"),
    t("Cuestionario6.Preguntas.2"),
    t("Cuestionario6.Preguntas.3"),
    t("Cuestionario6.Preguntas.4"),
    t("Cuestionario6.Preguntas.5"),
    t("Cuestionario6.Preguntas.6"),
    t("Cuestionario6.Preguntas.7"),
    t("Cuestionario6.Preguntas.8"),
    t("Cuestionario6.Preguntas.9"),
    t("Cuestionario6.Preguntas.10"),
    t("Cuestionario6.Preguntas.11"),
    t("Cuestionario6.Preguntas.12"),
    t("Cuestionario6.Preguntas.13"),
  ];
  const respuestasComunes = [
    t("Cuestionario6.Respuestas.1"),
    t("Cuestionario6.Respuestas.2"),
    t("Cuestionario6.Respuestas.3"),
    t("Cuestionario6.Respuestas.4"),
    t("Cuestionario6.Respuestas.5"),
    t("Cuestionario6.Respuestas.6"),
    t("Cuestionario6.Respuestas.7"),
  ];
  // Utilizar un objeto para almacenar las respuestas
  const [respuestas, setRespuestas] = useState({});

  // Función para manejar las respuestas de cada instancia de PreguntaRespuesta
  const handleRespuestaSubmit = (pregunta, respuesta) => {
    // Actualizar el objeto de respuestas con la respuesta correspondiente
    setRespuestas((prevRespuestas) => ({
      ...prevRespuestas,
      [pregunta]: respuesta,
    }));
  };

  // Función para mostrar todas las respuestas en la consola
  const mostrarRespuestasEnConsola = async () => {
    const respuestasEnTexto = preguntas
      .map((pregunta) => `"${respuestas[pregunta] || " "}"`)
      .join(",");
    const puntuacion =
      '"' +
      traducirArray(
        preguntas.map((pregunta) => `${respuestas[pregunta] || " "}`)
      ).join('","') +
      '"';
    console.log("Respuestas en texto:", respuestasEnTexto, puntuacion);
    const hayDosComillasSinValor = /""|\s*" "\s*|\[object Object\]/.test(
      respuestasEnTexto
    );

    if (hayDosComillasSinValor) {
      SetTodoRespondido(false);
    } else {
      SetTodoRespondido(true);
      try {
        const response = await axios.post(t("Recursos.ip") + "/respuesta", {
          numeroCuestionario: numero_cuestionario,
          arrayCuestionario: respuestasEnTexto,
          arrayPuntuacionCuestionario: puntuacion,
          email: email.email,
        });

        console.log(response.data);
        navigate("/Cuestionario/6/intentos");
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const traducirArray = (array) => {
    return array.map((elemento) => traducirAValor(elemento));
  };
  const traducirAValor = (cadena) => {
    switch (cadena) {
      case t("Cuestionario6.Respuestas.1"):
        return 1;
      case t("Cuestionario6.Respuestas.2"):
        return 2;
      case t("Cuestionario6.Respuestas.3"):
        return 3;
      case t("Cuestionario6.Respuestas.4"):
        return 4;
      case t("Cuestionario6.Respuestas.5"):
        return 5;
      case t("Cuestionario6.Respuestas.6"):
        return 6;
      case t("Cuestionario6.Respuestas.7"):
        return 7;

      default:
        return cadena; // Valor por defecto o manejo de error
    }
  };

  function paginar(valor) {
    SetTodoRespondido(true);
    if (valor > 0 && pagina < 6) {
      sePagina(pagina + valor);
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else if (valor < 0 && pagina > 0) {
      sePagina(pagina + valor);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }

  return (
    <div>
      <TituloyFlecha
        title={t("Cuestionario6.Titulo")}
        link={"/Cuestionario/6/intentos"}
      />
      
      <div style={{ marginTop: "2%", width: "80%", margin: "auto" }}>
        <p style={{ textAlign: "justify" }}>
          {t("Cuestionario6.Intro")}
        </p>
      </div>
      <div className="cuestionarioContainer">
        <br />
        <div style={{ display: pagina === 0 ? "block" : "none" }}>
          <CheckboxesCuestionarioSD
            preguntas={[preguntas[0]]}
            respuestasComunes={[
              t("Cuestionario6.Respuestas.1"),
              t("Cuestionario6.Respuestas.2"),
              t("Cuestionario6.Respuestas.3"),
              t("Cuestionario6.Respuestas.4"),
              t("Cuestionario6.Respuestas.5"),
              t("Cuestionario6.Respuestas.6"),
              t("Cuestionario6.Respuestas.7"),
            ]}
            onRespuestaSubmit={handleRespuestaSubmit}
          />
          <CheckboxesCuestionarioSD
            preguntas={[preguntas[1]]}
            respuestasComunes={[
              t("Cuestionario6.Respuestas.1"),
              t("Cuestionario6.Respuestas.2"),
              t("Cuestionario6.Respuestas.3"),
              t("Cuestionario6.Respuestas.4"),
              t("Cuestionario6.Respuestas.5"),
              t("Cuestionario6.Respuestas.6"),
              t("Cuestionario6.Respuestas.7"),
            ]}
            onRespuestaSubmit={handleRespuestaSubmit}
          />
          <CheckboxesCuestionarioSD
            preguntas={[preguntas[2]]}
            respuestasComunes={[
              t("Cuestionario6.Respuestas.1"),
              t("Cuestionario6.Respuestas.2"),
              t("Cuestionario6.Respuestas.3"),
              t("Cuestionario6.Respuestas.4"),
              t("Cuestionario6.Respuestas.5"),
              t("Cuestionario6.Respuestas.6"),
              t("Cuestionario6.Respuestas.7"),
            ]}
            onRespuestaSubmit={handleRespuestaSubmit}
          />
          <CheckboxesCuestionarioSD
            preguntas={[preguntas[3]]}
            respuestasComunes={[
              t("Cuestionario6.Respuestas.1"),
              t("Cuestionario6.Respuestas.2"),
              t("Cuestionario6.Respuestas.3"),
              t("Cuestionario6.Respuestas.4"),
              t("Cuestionario6.Respuestas.5"),
              t("Cuestionario6.Respuestas.6"),
              t("Cuestionario6.Respuestas.7"),
            ]}
            onRespuestaSubmit={handleRespuestaSubmit}
          />
          <CheckboxesCuestionarioSD
            preguntas={[preguntas[4]]}
            respuestasComunes={[
              t("Cuestionario6.Respuestas.1"),
              t("Cuestionario6.Respuestas.2"),
              t("Cuestionario6.Respuestas.3"),
              t("Cuestionario6.Respuestas.4"),
              t("Cuestionario6.Respuestas.5"),
              t("Cuestionario6.Respuestas.6"),
              t("Cuestionario6.Respuestas.7"),
            ]}
            onRespuestaSubmit={handleRespuestaSubmit}
          />
          <CheckboxesCuestionarioSD
            preguntas={[preguntas[5]]}
            respuestasComunes={[
              t("Cuestionario6.Respuestas.1"),
              t("Cuestionario6.Respuestas.2"),
              t("Cuestionario6.Respuestas.3"),
              t("Cuestionario6.Respuestas.4"),
              t("Cuestionario6.Respuestas.5"),
              t("Cuestionario6.Respuestas.6"),
              t("Cuestionario6.Respuestas.7"),
            ]}
            onRespuestaSubmit={handleRespuestaSubmit}
          />
          <CheckboxesCuestionarioSD
            preguntas={[preguntas[6]]}
            respuestasComunes={[
              t("Cuestionario6.Respuestas.1"),
              t("Cuestionario6.Respuestas.2"),
              t("Cuestionario6.Respuestas.3"),
              t("Cuestionario6.Respuestas.4"),
              t("Cuestionario6.Respuestas.5"),
              t("Cuestionario6.Respuestas.6"),
              t("Cuestionario6.Respuestas.7"),
            ]}
            onRespuestaSubmit={handleRespuestaSubmit}
          />
        </div>
        <div style={{ display: pagina === 1 ? "block" : "none" }}>
          <CheckboxesCuestionarioSD
            preguntas={[preguntas[7]]}
            respuestasComunes={[
              t("Cuestionario6.Respuestas.1"),
              t("Cuestionario6.Respuestas.2"),
              t("Cuestionario6.Respuestas.3"),
              t("Cuestionario6.Respuestas.4"),
              t("Cuestionario6.Respuestas.5"),
              t("Cuestionario6.Respuestas.6"),
              t("Cuestionario6.Respuestas.7"),
            ]}
            onRespuestaSubmit={handleRespuestaSubmit}
          />
          <CheckboxesCuestionarioSD
            preguntas={[preguntas[8]]}
            respuestasComunes={[
              t("Cuestionario6.Respuestas.1"),
              t("Cuestionario6.Respuestas.2"),
              t("Cuestionario6.Respuestas.3"),
              t("Cuestionario6.Respuestas.4"),
              t("Cuestionario6.Respuestas.5"),
              t("Cuestionario6.Respuestas.6"),
              t("Cuestionario6.Respuestas.7"),
            ]}
            onRespuestaSubmit={handleRespuestaSubmit}
          />
          <CheckboxesCuestionarioSD
            preguntas={[preguntas[9]]}
            respuestasComunes={[
              t("Cuestionario6.Respuestas.1"),
              t("Cuestionario6.Respuestas.2"),
              t("Cuestionario6.Respuestas.3"),
              t("Cuestionario6.Respuestas.4"),
              t("Cuestionario6.Respuestas.5"),
              t("Cuestionario6.Respuestas.6"),
              t("Cuestionario6.Respuestas.7"),
            ]}
            onRespuestaSubmit={handleRespuestaSubmit}
          />
          <CheckboxesCuestionarioSD
            preguntas={[preguntas[10]]}
            respuestasComunes={[
              t("Cuestionario6.Respuestas.1"),
              t("Cuestionario6.Respuestas.2"),
              t("Cuestionario6.Respuestas.3"),
              t("Cuestionario6.Respuestas.4"),
              t("Cuestionario6.Respuestas.5"),
              t("Cuestionario6.Respuestas.6"),
              t("Cuestionario6.Respuestas.7"),
            ]}
            onRespuestaSubmit={handleRespuestaSubmit}
          />
          <CheckboxesCuestionarioSD
            preguntas={[preguntas[11]]}
            respuestasComunes={[
              t("Cuestionario6.Respuestas.1"),
              t("Cuestionario6.Respuestas.2"),
              t("Cuestionario6.Respuestas.3"),
              t("Cuestionario6.Respuestas.4"),
              t("Cuestionario6.Respuestas.5"),
              t("Cuestionario6.Respuestas.6"),
              t("Cuestionario6.Respuestas.7"),
            ]}
            onRespuestaSubmit={handleRespuestaSubmit}
          />
          <CheckboxesCuestionarioSD
            preguntas={[preguntas[12]]}
            respuestasComunes={[
              t("Cuestionario6.Respuestas.1"),
              t("Cuestionario6.Respuestas.2"),
              t("Cuestionario6.Respuestas.3"),
              t("Cuestionario6.Respuestas.4"),
              t("Cuestionario6.Respuestas.5"),
              t("Cuestionario6.Respuestas.6"),
              t("Cuestionario6.Respuestas.7"),
            ]}
            onRespuestaSubmit={handleRespuestaSubmit}
          />
        </div>

        <br></br>
        {!todoRespondido && (
          <div>
            <p style={{ color: "red" }}>{t("Responde-todo")}</p>
          </div>
        )}
        <div style={{ width: "100%", display: "flex" }}>
          <button
            onClick={() => paginar(-1)}
            style={{
              marginTop: "30px",
              marginRight: window.innerWidth <= 768 ? "10%" : "-25%",
              marginBottom: "30px",
              width: window.innerWidth <= 768 ? "50%" : "20%",
              //visibility: pagina === 0 ? "hidden" : "visible",
              background: pagina ===0 ?" #A5BBC1" : "",
            }}
            className="link-button"
          >
            {t("Atras")}
          </button>

          {pagina < 1 && (
            <button
              onClick={() => paginar(1)}
              style={{
                marginTop: "30px",
                marginBottom: "30px",
                marginLeftt: window.innerWidth <= 768 ? "-10%" : "25%",
                width: window.innerWidth <= 768 ? "50%" : "20%",
                visibility: pagina === 1 ? "hidden" : "visible",
              }}
              className="link-button"
            >
              {t("Continuar")}
            </button>
          )}
          {pagina === 1 && (
            <button
              onClick={mostrarRespuestasEnConsola}
              className="link-button"
              style={{
                width: window.innerWidth <= 768 ? "50%" : "20%",
                marginTop: "30px",
                marginBottom: "30px",
              }}
            >
              {t("Confirmar")}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Cuestionario6;
