import React, { Component } from "react";
import "./EscalavisualanalogicaHorizontal.css";

class EscalavisualanalogicaHorizontal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: null,
    };
  }

  handleClick = (event) => {
    const linea = this.lineaRef.getBoundingClientRect();
    const clickX = event.clientX - linea.left;
    const totalWidth = linea.right - linea.left;
    const porcentaje = (clickX / totalWidth) * 100;
    const valorExacto = porcentaje / 10;
    const respuesta = valorExacto % 1 === 0 ? valorExacto : valorExacto.toFixed(1);

    this.setState({ selectedValue: respuesta }, () => {
      if (this.props.onRespuestaSeleccionada) {
        this.props.onRespuestaSeleccionada(respuesta);
      }
    });
  };

  render() {
    const { selectedValue } = this.state;
    const { textoIzquierda, textoDerecha, textoAbajo } = this.props;

    // Calcula el estilo dinámico para la línea roja
    const redLineStyle = {
      left: selectedValue ? `calc(${selectedValue * 10}%)` : 0,
    };

    return (
      <div className="escalavisualanalogica-containerH">
        <div className="texto-containerH">
          <div className="textoIzquierdaH"><b>{textoIzquierda}</b></div>
          <div className="textoDerechaH"><b>{textoDerecha}</b></div>
        </div>
        <div className="linea-containerH">
          <div
            className="lineaH"
            onClick={this.handleClick}
            ref={(ref) => (this.lineaRef = ref)}
          >
            <div className="marcasH">
              {[...Array(11)].map((_, i) => (
                <div
                  key={i}
                  className={`marcaH ${i === 0 || i === 10 ? "mayorH" : "mediaH"}`}
                >
                  <span className="numeroH">{i}</span>
                </div>
              ))}
            </div>
            {/* Agrega la línea roja vertical */}
            <div className="lineaRoja" style={redLineStyle}></div>
          </div>
        </div>
        {selectedValue !== null && (
          <div className="textoResultadoH">
            <p>Valor seleccionado: <b>{selectedValue}</b></p>
          </div>
        )}
        <div className="textoAbajoContainerH">
          <p>{textoAbajo}</p>
        </div>
      </div>
    );
  }
}

export default EscalavisualanalogicaHorizontal;
