import React, { useState, useEffect  } from "react";
import { Link } from "react-router-dom";
import Card from "../components/Card";
import "./RellenoRecomendados.css";
import pdf from "../Images/PDF_icono.png";
import ppt from "../Images/PPT_icono.png";
import mp4 from "../Images/MP4_icono.png";
import tarea from "../Images/tarea_icono.png";
import video from "../Images/video_icono.png";
import infografia from "../Images/infografia_icono.png";
import { useTranslation } from "react-i18next";
import { BorderColor } from "@mui/icons-material";

function RellenoRecomendados({ nombreFront, nombre, contenido }) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [t] = useTranslation("global");
  const [colorborde, setColorborde] = useState("#9A91D2");
  const [contenidolink, setContenidolink] = useState(null);
  let componenteRenderizado;
  let altocard = "21vh";
  let anchocard = "21vh";
  if (windowWidth < 800) {
    altocard = "20vh";
    anchocard = "20vh";
  }

  

  useEffect(() => {
    switch (contenido) {
      case "presentacion":
        setContenidolink("/pdf/" + nombre)
        break;
      case "infografia":
       setContenidolink( "/pdf/" + nombre)
        break;
      case "tarea":
       setContenidolink( "/pdf/" + nombre)
        break;
      case "material_complementario":
       setContenidolink( "/pdf/" + nombre)
        break;
      case "video":
       setContenidolink( "/video/" + nombre)
        break;
      default:
       setContenidolink( "/pdf/" + nombre)
    }
  }, [contenido,nombre]);

  function eliminarUltimosCaracteres(cadena) {
    const nuevaCadena = cadena.slice(0, -4);
    return nuevaCadena;
  }

  function ImagenConNombre(nombre) {
    // Supongamos que las imágenes están en una carpeta llamada "imagenes"
    // en la raíz de la carpeta "public" de tu proyecto React

    const rutaImagen =
      //process.env.PUBLIC_URL + "/Images/Miniaturas/" + eliminarUltimosCaracteres(nombre) + ".png"; // Cambia la extensión según el tipo de archivo de imagen que estés utilizando
      process.env.PUBLIC_URL +
      "/Images/Miniaturas/" +
      eliminarUltimosCaracteres(nombre) +
      ".png"; // Cambia la extensión según el tipo de archivo de imagen que estés utilizando

    return rutaImagen;
  }

  componenteRenderizado = (
    <Link to={contenidolink}>
      <Card
        content={
          <div style={{ height: "100%" }}>
            {/* <div style={{ minHeight: "30%", maxHeight: "30%", marginTop:"0%" }}>
              <br></br>
              <p className="parrafoCardRecomendados">
                <b>{nombreFront}</b>
              </p>
            </div>
            <br />
            <div style={{ minHeight: "70%", maxHeight: "70%" }}>
              <br></br> */}
            <img
              src={ImagenConNombre(nombre)}
              alt={nombre}
              className="imagenRellenoRecomendados"
            />
            {/* </div> */}
          </div>
        }
        alto={altocard}
        ancho={anchocard}
        className={`cardRellenoRecomendados ${contenido}Border`}


      />
    </Link>
  );

  return <div>{componenteRenderizado}</div>;
}

export default RellenoRecomendados;

// componenteRenderizado = (
//   <Link to={contenidolink}>
//     <Card
//       content={
//         <div style={{ height: "100%" }}>
//           <div style={{ minHeight: "30%", maxHeight: "30%", marginTop:"0%" }}>
//             <br></br>
//             <p className="parrafoCardRecomendados">
//               <b>{nombreFront}</b>
//             </p>
//           </div>
//           <br />
//           <div style={{ minHeight: "70%", maxHeight: "70%" }}>
//             <br></br>
//             <img
//                src={ImagenConNombre(contenido)}
//                alt={contenido}
//               className="imagenRellenoRecomendados"
//             />
//           </div>
//         </div>
//       }
//       alto={altocard}
//       ancho={anchocard}
//       className="cardRellenoRecomendados"
//     />
//   </Link>
// );

// return <div>{componenteRenderizado}</div>;
// }
