import { useEffect, useContext, useState } from "react";
import UserContext from "../../UserContext";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Card from "../../components/Card";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import TituloyFlecha from "../../components/TituloyFlecha";
const CuestionarioRevisar = () => {
  const [t] = useTranslation("global");

  const email = useContext(UserContext);

  // Obtener cuestionarioId e intento de la URL
  const { cuestionarioId, intento } = useParams();

  // Definir el estado para almacenar las respuestas
  const [respuestas, setRespuestas] = useState([]);

  const [preguntas, setPreguntas] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Construir la URL con los parámetros
        const url =
          t(`Recursos.ip`) +
          `/respuesta/intento/revisar?email=${email.email}&numerocuestionario=${cuestionarioId}&intento=${intento}`;

        // Realizar la solicitud GET
        const response = await axios.get(url);

        // Actualizar el estado con las respuestas obtenidas
        setRespuestas(response.data);

        const numeroPreguntas = response.data.length;

        const preguntasArray = [];

        for (let i = 1; i <= numeroPreguntas; i++) {
          const pregunta = t(`Cuestionario${cuestionarioId}.Preguntas.${i}`);
          preguntasArray.push(pregunta);
        }

        setPreguntas(preguntasArray);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Llamar a la función para realizar la solicitud cuando el componente se monte
    fetchData();
  }, [cuestionarioId, intento]);

  // Resto del componente para renderizar las respuestas

  return (
    <div>
      <TituloyFlecha title={t(`Cuestionario${cuestionarioId}.Titulo`)} link={`/Cuestionario/${cuestionarioId}/intentos`}/>
      
      <br />
      <div
        style={{ margin: "0 auto", textAlign: "left", width: "50%" }}
      >
        <br />
        {preguntas.map((pregunta, index) => (
          <div key={index}>
            <p style={{ fontSize: "1.1 rem", marginLeft: "2%" }}>
              <strong>{pregunta}</strong>
            </p>
            {/* Verificar si hay una respuesta correspondiente */}
            {index < respuestas.length && (
              <p style={{ fontSize: "1 rem", marginLeft: "2%" }}>
                {respuestas[index]}
              </p>
            )}
          </div>
        ))}
        <br />
      </div>
      <br />
    </div>
  );
};

export default CuestionarioRevisar;
