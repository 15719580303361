// AuthChecker.js
import React, { useEffect, useContext } from "react";
import UserContext from '../UserContext';
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AuthChecker = ({ bearer, setBearer, setEmail }) => {

  const navigate = useNavigate();
  const [t] = useTranslation("global");
  const location = useLocation();
  const { mostrar, setMostrar } = useContext(UserContext);
  const { ventanaAnterior, setVentanaAnterior } = useContext(UserContext);

  const handleConnectionChange = () => {
    const isOnline = navigator.onLine;
    if (!isOnline) {
      // Mostrar un mensaje al usuario o realizar alguna acción cuando se pierde la conexión
      alert(t("perdida-conexion"));
      window.location.reload();
    }
  };
   
  window.addEventListener("online", handleConnectionChange);
  window.addEventListener("offline", handleConnectionChange);

  useEffect(() => {
    if (!(location.pathname === "/login" || location.pathname === "/QuienesSomos" || location.pathname === "/Login" || location.pathname === "/anadirinfopersonal" || location.pathname === "/Descarga" || location.pathname === "/FormularioRegistro" || location.pathname === "/CuestionarioEstadoAnimo" || location.pathname === "/cerrarsesion" || location.pathname === "/CuestionarioSocioDemo" || location.pathname === "/CuestionarioSocioDemo1" || location.pathname === "/Ayuda" || location.pathname === "/politica-privacidad")) { //realmente aqui podemos ir a cuestionariosocidodemo1 sin ser en el registro inicial pero sino jodemos la logica con estado de animo y eso es más complicado, de todas foras naide va a ir directo a eso, no?
      setVentanaAnterior(location.pathname)
    }
    if (location.pathname !== "/Ayuda" && location.pathname !== "/politica-privacidad" && location.pathname !== "/Descarga" && location.pathname !== "/QuienesSomos") {
      const storedToken = localStorage.getItem("accessToken");
      const tokenTimestamp = localStorage.getItem("accessTokenTimestamp");

      if (storedToken != null && tokenTimestamp != null) {
        const currentTimestamp = Date.now();
        const elapsedMilliseconds =
          currentTimestamp - parseInt(tokenTimestamp, 10);
        const elapsedMinutes = elapsedMilliseconds / (1000 * 60);
        console.log("AuthChecker, elapsed: " + elapsedMinutes);


        //Verificar si ha pasado más de media hora (57 minutos)
        if (elapsedMinutes >= 57) {


          console.log("AuthChecker, setBearer Null")
          setBearer(null);
          localStorage.removeItem("accessToken");
          setMostrar(false)
          navigate("/login");
        }
      }

      if (bearer == null) {

        console.log("AuthChecker, localaccesstoken: ", localStorage.getItem("accessToken"));
        if (
          localStorage.getItem("accessToken") === null ||
          localStorage.getItem("accessToken") === "null"

        ) {
          console.log("AuthChecker, true")
          setMostrar(false)
          navigate("/login");
        } else {
          console.log("AuthChecker, false")
          setBearer(localStorage.getItem("accessToken"));
          setEmail(localStorage.getItem("email"));
          setMostrar(true)
        }
      }
    }
  }, [bearer, setBearer, setEmail, navigate]);

  return null; // No renderiza nada, ya que es solo para lógica
};

export default AuthChecker;
