import React from "react";
import CircularProgressBarPasos from "../Graficos/CircularProgressBarPasos";
import CircularProgressBarSueno from "../Graficos/CircularProgressBarSueno";
import Card from "../Card";
import Pasos_Icono from "../../Images/Pasos_Icono.png";
import Sueño_Icono from "../../Images/Sueño_Icono.png";
import mood1 from "../../Images/mood1.png";
import mood2 from "../../Images/mood2.png";
import mood3 from "../../Images/mood3.png";
import mood4 from "../../Images/mood4.png";
import mood5 from "../../Images/mood5.png";
import "./CartaGrandeHome.css";
import { useTranslation } from "react-i18next";
import { useEffect, useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import UserContext from "../../UserContext";
import { BsGraphUp } from "react-icons/bs";

export default function CartaGrandeHome({
  progresosueno,
  maxValorsueno,
  progresoactividad,
  maxValoractividad,
}) {
  const [t] = useTranslation("global");
  const email = useContext(UserContext);
  const [pasos_totales, setPasos_totales] = useState(null);
  const [sueno, setSueno] = useState(null);
  const [estadoAnimo, setEstadoAnimo] = useState(null);
  const [objetivopasos, setObjetivopasos] = useState(null);
  const [objetivosueno, setObjetivosueno] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    // Verificar si el correo electrónico no es nulo antes de llamar a la función
    if (email.email) {
      consulta_estado_animo();
      consulta_pasos();
      consulta_sueño();
      consulta_objetivo_pasos();
      consulta_objetivo_sueno();
    }
  }, [email]);

  const consulta_estado_animo = async () => {
    const currentDate = new Date();
    const formattedCurrentDate = currentDate.toISOString().split("T")[0];
    try {
      const response = await axios.get(t("Recursos.ip") + "/estadoanimo", {
        params: {
          email: email.email,
          startTime: formattedCurrentDate,
          endTime: formattedCurrentDate,
        },
      });

      setEstadoAnimo(response.data[0].estadoAnimo);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const consulta_pasos = async () => {
    const currentDate = new Date();
    const OneMoreDay = new Date();
    OneMoreDay.setDate(currentDate.getDate() + 1);
    const formattedCurrentDate = currentDate.toISOString().split("T")[0];
    const formattedOneMoreDay = OneMoreDay.toISOString().split("T")[0];

    try {
      const response = await axios.get(t("Recursos.ip") + "/pasos", {
        params: {
          email: email.email,
          startTime: formattedCurrentDate,
          endTime: formattedOneMoreDay,
        },
      });
      setPasos_totales(calcularTotalNumeropasosUltimoDia(response.data));
    } catch (error) {
      console.error("Error:", error);
    }
  };

  function calcularTotalNumeropasosUltimoDia(datos) {
    // Calcula el total de numeropasos de los elementos del último día
    const totalNumeropasos = datos.reduce(
      (sum, item) => sum + item.numeropasos,
      0
    );

    return totalNumeropasos;
  }

  const consulta_sueño = async () => {
    const currentDate = new Date();
    const oneDayAgo = new Date();
    oneDayAgo.setDate(currentDate.getDate() - 1);
    const formattedCurrentDate = currentDate.toISOString().split("T")[0];
    const formattedoneDayAgo = oneDayAgo.toISOString().split("T")[0];

    try {
      const response_durancion = await axios.get(t("Recursos.ip") + "/sueno", {
        params: {
          email: email.email,
          startTime: formattedoneDayAgo,
          endTime: formattedCurrentDate,
        },
      });
      // setSueno(response.data[0].duracion);
      try {
        const ultima = encontrarFechaFinMasReciente(
          response_durancion.data
        ).ultimasesion;
        console.log(response_durancion);
        if (ultima[0].t_fin > formattedCurrentDate) {
          //esto hace que solo se muestren los del anterior dia
          setSueno(
            encontrarFechaFinMasReciente(response_durancion.data).minutosTotales
            // calcularDiferenciaMinutos(
            //   ultima[ultima.length - 1].t_inicio,
            //   ultima[0].t_fin
            // )
          );
        }
      } catch (error) {
        setSueno(0);
      }
    } catch (error) {
      error("Error:", error);
    }
  };

  function encontrarFechaFinMasReciente(fasesSueno) {
    if (fasesSueno.length === 0) {
      return { ultimasesion: null, minutosTotales: 0 };
    }

    const ultimasesion = [];
    let minutosTotales = 0;

    fasesSueno.sort((a, b) => new Date(b.t_fin) - new Date(a.t_fin));

    for (let i = 0; i < fasesSueno.length; i++) {
      if (i === 0) {
        ultimasesion.push(fasesSueno[i]);
        minutosTotales += calcularDuracionEnMinutos(
          fasesSueno[i].t_inicio,
          fasesSueno[i].t_fin
        );
      } else {
        const fechaFinActual = new Date(fasesSueno[i].t_fin);
        const fechaInicioAnterior = new Date(fasesSueno[i - 1].t_inicio);

        if (fechaInicioAnterior.getTime() === fechaFinActual.getTime()) {
          ultimasesion.push(fasesSueno[i]);
          minutosTotales += calcularDuracionEnMinutos(
            fasesSueno[i].t_inicio,
            fasesSueno[i].t_fin
          );
        } else {
          break;
        }
      }
    }

    // Restar los minutos de las fases "DESPIERTO"
    const minutosDespierto = ultimasesion
      .filter((fase) => fase.fasesSuenoEnum === "DESPIERTO")
      .reduce(
        (total, fase) =>
          total + calcularDuracionEnMinutos(fase.t_inicio, fase.t_fin),
        0
      );
    console.log("ultimasesion", ultimasesion);
    console.log(
      "Minutos totales y despierto",
      minutosTotales,
      minutosDespierto
    );
    minutosTotales -= minutosDespierto;

    return { ultimasesion, minutosTotales };
  }

  // Función auxiliar para calcular la duración en minutos entre dos fechas.
  function calcularDuracionEnMinutos(fechaInicio, fechaFin) {
    const inicio = new Date(fechaInicio);
    const fin = new Date(fechaFin);
    return Math.round((fin - inicio) / (1000 * 60)); // Convertir de milisegundos a minutos
  }

  const consulta_objetivo_pasos = async () => {
    try {
      const response = await axios.get(t("Recursos.ip") + "/objetivo", {
        params: {
          email: email.email,
          parametro: "pasos",
        },
      });

      const objetivoaux = response.data;
      setObjetivopasos(objetivoaux.valor);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const consulta_objetivo_sueno = async () => {
    try {
      const response = await axios.get(t("Recursos.ip") + "/objetivo", {
        params: {
          email: email.email,
          parametro: "sueno",
        },
      });

      const objetivoaux = response.data;
      setObjetivosueno(objetivoaux.valor);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const estadoAnimoString = () => {
    switch (estadoAnimo) {
      case 1:
        return t("Estado-Animo.Muy-mal");
      case 2:
        return t("Estado-Animo.Mal");
      case 3:
        return t("Estado-Animo.Normal");
      case 4:
        return t("Estado-Animo.Bien");
      case 5:
        return t("Estado-Animo.Muy-bien");
      default:
        // Devuelve una imagen predeterminada o maneja el caso no especificado según tus necesidades
        return t("Estado-Animo.Normal");
    }
  };
  const navergarcuestionario = () => {
    navigate("/CuestionarioEstadoAnimo");
  };
  const navergarestadoanimo = () => {
    navigate("/EstadodeAnimo/semana");
  };
  const estadoAnimoImagen = () => {
    switch (estadoAnimo) {
      case 1:
        return mood1;
      case 2:
        return mood2;
      case 3:
        return mood3;
      case 4:
        return mood4;
      case 5:
        return mood5;
      default:
        // Devuelve una imagen predeterminada o maneja el caso no especificado según tus necesidades
        return mood1;
    }
  };
  return (
    <div style={{ fontSize: "2vh" }}>
      <div style={{ width: "30vh", margin: "0 auto", marginTop: "-2.5vh" }}>
        <Card
          content={
            <h1
              style={{
                fontSize: "3vh",
                marginTop: "0.5vh",
                color: "white",
              }}
            >
              {t("Mi-Resumen-Diario")}
            </h1>
          }
          alto="50%"
          ancho="100%"
          className={"cardCartaGrandeHome"}
        />
      </div>
      {estadoAnimo ? (
        <div>
          <p style={{ marginTop: "0.75rem" }}>
            {t("Carta-Grande.Hoy-te-encuentras")} <b>{estadoAnimoString()} </b>{" "}
            <button
              className="botongraficoanimoCartaGrandeHome"
              onClick={() => navergarestadoanimo()}
            >
              <BsGraphUp
                style={{
                  color: "#518ea6",
                  strokeWidth: "1.75px",
                  marginBottom: "3px",
                }}
              />
            </button>
          </p>
          <img
            src={estadoAnimoImagen()}
            alt={estadoAnimoString()}
            className="imagenCartaGrandeHome"
            style={{ marginTop: "-2%", marginBottom: "1%" }}
          />
        </div>
      ) : (
        <div style={{ marginTop: "5%", marginBottom: "5%" }}>
          <p>{t("Carta-Grande.No-estado-animo")} </p>

          <button
            className="botoncircCartaGrandeHome"
            onClick={() => navergarcuestionario()}
          >
            {t("Cubrir")}
          </button>
        </div>
      )}

      <p>{t("Carta-Grande.Hoy-dormido-caminado")}</p>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "1%",
          width: "100%",
        }}
      >
        <div style={{ width: "50%" }}>
          <b style={{ fontSize: "2.5vh" }}>{t("Pasos")}</b>
          <br />
          <Link to="/MiActividadFisica/dia">
            <img
              src={Pasos_Icono}
              alt={t("Pasos-Icono")}
              className="iconoCartaGrandeHome"
            />
          </Link>
          {/* Coloca aquí el texto que aparecerá a la derecha del primer componente */}
        </div>
        <div style={{ width: "50%" }}>
          <b style={{ fontSize: "2.5vh" }}>{t("Sueno")}</b>
          <br />
          <Link to="/RutinaSuenoDiaria">
            <img
              src={Sueño_Icono}
              alt={t("Sueno-Icono")}
              className="iconoCartaGrandeHome"
            />
          </Link>
          {/* Coloca aquí el texto que aparecerá a la derecha del segundo componente */}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            marginTop: "3%",
            width: "50%",
            justifyContent: "center",
          }}
        >
          <CircularProgressBarPasos
            progress={pasos_totales}
            maxValue={objetivopasos}
            texto=" pasos"
            colortexto="black"
            colorfondo="#F0F8FF"
            colorpath="#F8DE22"
            ancho="60%"
            alto="60%"
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: "3%",
            marginLeft: "1%",
            width: "50%",
            justifyContent: "center",
          }}
        >
          {/* {objetivosueno !== null && sueno !== null && sueno > 0 ? ( */}
          {/* {objetivosueno !== null && sueno !== null && sueno > 0 ? ( */}
          <CircularProgressBarSueno
            progress={sueno}
            maxValue={objetivosueno}
            texto=" horas"
            colortexto="black"
            colorfondo="#F0F8FF"
            colorpath="#810CA8"
            ancho="60%"
            alto="60%"
          />
        </div>
      </div>
    </div>
  );
}
