import { useState, useContext, useEffect } from "react";
import LinesChart from "../Graficos/LinesChart";
import { PiHeartbeatBold } from "react-icons/pi";
import Corazon_Icono from "../../Images/Corazon_Icono.png";
import "./PulsacionesCarta.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import UserContext from "../../UserContext";
import axios from "axios";

function PulsacionesCarta({ progreso, maxValor }) {
  const [t] = useTranslation("global");
  const [chartData, setChartData] = useState(null);
  const [valores_dia10, setValores_dia10] = useState(null);
  const [minutos_dia10, setMinutos_dia10] = useState(null);
  const [fecha, setFecha] = useState(null);
  const [periodicidad] = useState(30);
  const email = useContext(UserContext);

  useEffect(() => {
    // Verificar si el correo electrónico no es nulo antes de llamar a la función
    if (email.email) {
      cosnulta_pulsaciones();
    }
  }, [email]);

  useEffect(() => {
    if (valores_dia10 && minutos_dia10) {
      setChartData(getChartData());
      //Console.log("SUUU", valores_dia10);
    }
  }, [valores_dia10, minutos_dia10]);

  const cosnulta_pulsaciones = async () => {
    const currentDate = new Date();
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(currentDate.getDate() - 90);
    const formattedCurrentDate = currentDate.toISOString().split("T")[0];
    const formattedOneWeekAgo = oneWeekAgo.toISOString().split("T")[0];

    try {
      const response = await axios.get(
        t("Recursos.ip") + "/pulsaciones",
        {
          params: {
            email: email.email,
            startTime: formattedOneWeekAgo,
            endTime: formattedCurrentDate,
          },
        }
      );
      //Console.log("Pulsaciones: ", response.data);

      const valores_dia = fpval_ultimodia(response.data);
      //Console.log( "Valores",response.data);
      setFecha(formatearFecha(response.data[0].timestamp))
      setValores_dia10(calcularMediaCadaIntervalo(valores_dia, periodicidad));
      setMinutos_dia10(minutosDelDiaConIntervalo(periodicidad));
    } catch (error) {
      console.error("Error:", error);
    }
  };

  function fpval_ultimodia(data) {
    // Obtén la fecha más reciente
    const latestDate = new Date(
      Math.max(...data.map((item) => new Date(item.timestamp)))
    );

    // Crea un objeto para almacenar los valores de datos para cada minuto del día
    const minuteData = {};

    // Llena el objeto con valores existentes de datos
    data.forEach((item) => {
      const itemDate = new Date(item.timestamp);
      if (itemDate.toDateString() === latestDate.toDateString()) {
        const hours = itemDate.getHours();
        const minutes = itemDate.getMinutes();
        const key = `${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes
          }`;
        minuteData[key] = item.fpVal;
      }
    });

    // Crea el array resultante con 1440 elementos, rellenando con 0 cuando no hay datos
    const resultArray = [];
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 60; j++) {
        const key = `${i < 10 ? "0" + i : i}:${j < 10 ? "0" + j : j}`;
        const fpVal = minuteData[key] || 0;
        resultArray.push({
          timestamp: `${latestDate.getFullYear()}-${latestDate.getMonth() + 1
            }-${latestDate.getDate()} ${key}:00.001`,
          fpVal,
        });
      }
    }
    return resultArray;
  }

  function calcularMediaCadaIntervalo(datos, minutosIntervalo) {
    const mediaArray = [];
    let sum = 0;
    let count = 0;

    for (let i = 0; i < datos.length; i++) {
      const valor = datos[i].fpVal;

      // Si el valor no es 0, agrégalo a la suma
      if (valor !== 0) {
        sum += valor;
        count++;
      }

      // Si ha pasado el intervalo de tiempo especificado o hemos llegado al final de los datos
      if (
        i === datos.length - 1 ||
        (i > 0 && (i + 1) % minutosIntervalo === 0)
      ) {
        // Calcula la media para el intervalo actual (o menos si es el final de los datos)
        if (count > 0) {
          const media = sum / count;
          mediaArray.push(media);
        } else {
          // Si no hubo valores distintos de 0 en el intervalo actual, agrega NaN
          mediaArray.push(0);
        }
        // Reinicia el contador y la suma
        count = 0;
        sum = 0;
      }
    }

    return mediaArray;
  }
  const minutosDelDiaConIntervalo = (intervaloMinutos) => {
    const minutos = [];

    for (let hora = 0; hora < 24; hora++) {
      for (let minuto = 0; minuto < 60; minuto += intervaloMinutos) {
        const horaFormateada = hora < 10 ? `0${hora}` : `${hora}`;
        const minutoFormateado = minuto < 10 ? `0${minuto}` : `${minuto}`;
        minutos.push(`${horaFormateada}:${minutoFormateado}`);
      }
    }

    return minutos;
  };

  function calcularMediaSinCeros(arr) {
    let sum = 0;
    let count = 0;

    for (let i = 0; i < arr.length; i++) {
      if (arr[i] !== 0) {
        sum += arr[i];
        count++;
      }
    }

    if (count === 0) {
      return 0.0; // En caso de que no haya valores distintos de 0 en el array
    }

    return (sum / count).toFixed(1);
  }
  function formatearFecha(dateString) {
    const date = new Date(dateString); // Convertir la cadena a un objeto Date
    if (isNaN(date.getTime())) {
      return "Fecha no válida"; // Manejar el caso en que la cadena no sea una fecha válida
    }

    const dia = date.getDate().toString().padStart(2, "0");
    const mes = (date.getMonth() + 1).toString().padStart(2, "0");
    const anio = date.getFullYear();

    return `${dia}-${mes}-${anio}`;
  }

  const getChartData = () => {
    return {
      labels: minutos_dia10,
      datasets: [
        {
          label: "Pulsaciones",
          data: valores_dia10,
          tension: 1,
          fill: true,
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgba(255, 99, 132, 0.5)",
          pointRadius: 0.1,
          pointBorderColor: "rgba(255, 99, 132)",
          pointBackgroundColor: "rgba(255, 99, 132)",
          cubicInterpolationMode: "step-before", // Suaviza las curvas
        },
      ],
    };
  };

  const misoptions = {
    plugins: {
      legend: {
        display: false, // Deshabilita la leyenda (incluido el botón para ocultar líneas)
      },
    },
    scales: {
      y: {
        min: 0,
        title: {
          display: true,
          text: t("pulsaciones-bpm"),
          color: "black",
        },
      },
      x: {
        title: {
          display: true,
          text: t("Hora"),
          color: "black",
        },
        ticks: { color: "black" },
      },
    },
  };

  return (
    <div style={{ width: "100%", minHeight:"100%" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginLeft: "3%",
          color: "#340068",
          marginTop: "5%"
        }}
      >
        <img
          src={Corazon_Icono}
          alt={t("Corazon-Icono")}
          className="imagenPulsacionesCarta"
        />
        <h3 style={{color: "black"}}>
          &nbsp;
          {t("Mi_Frecuencia_Cardiaca")}
        </h3>
      </div>

      {chartData && fecha ? (
        <div style={{ color: "black" }}>

          <p>
            <b>{fecha}</b>
          </p>
          <div
            style={{
              marginRight: "1%",
              marginLeft: "1%",
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              
            }}
          >
            <LinesChart data={chartData} options={misoptions} />
          </div>
          <div
            style={{
              color: "black",
              alignContent: "center",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", marginTop: "1%"}}>
              <b>{t("Frecuencia_cardiaca_media")}</b>
              {calcularMediaSinCeros(valores_dia10)} {t("bpm")}
            </div>

          </div>
        </div>
      ) : (
        <div style={{ color: "black", marginTop: "20%" }}>
          <b>{t("Conectando-BD")}</b>
        </div>
      )}

    </div>
  );
}

export default PulsacionesCarta;
