import React from 'react';

function EscalaHoraria({ horaInicio, horaFin, ancho }) {
  const containerWidth = 380;


  return (
    <div className="escala-container" style={{ width: ancho, display: 'flex' }}>
      {generarHorasIntermedias(horaInicio,horaFin,10).map((hora, index) => (
        <div key={index} style={{ flex: 1, textAlign: 'center' }}>
          {hora}
        </div>
      ))}
    </div>
  );
}

function generarHorasIntermedias(horaInicio, horaFin, numero) {
    const horas = [];
    const totalMinutos = calcularDiferenciaMinutos(new Date(horaFin), new Date(horaInicio));
  
    for (let i = 0; i <= numero; i++) {
      const minutos = (i / numero) * totalMinutos;
      const hora = sumarMinutos(new Date(horaInicio), minutos);
      horas.push(formatoHoraConMinutos(hora.getHours(), hora.getMinutes()));
    }
  
    return horas;
  }
  
  function calcularDiferenciaMinutos(fechaFin, fechaInicio) {
    return Math.abs(fechaFin - fechaInicio) / 60000; // 1 minuto = 60000 milisegundos
  }
  
  function sumarMinutos(fecha, minutos) {
    const nuevaFecha = new Date(fecha);
    nuevaFecha.setMinutes(fecha.getMinutes() + minutos);
    return nuevaFecha;
  }
  
  function formatoHoraConMinutos(hora, minutos) {
    const horaFormateada = hora < 10 ? `0${hora}` : `${hora}`;
    const minutosFormateados = minutos < 10 ? `0${minutos}` : `${minutos}`;
    return `${horaFormateada}:${minutosFormateados}`;
  }
  
  
export default EscalaHoraria;
