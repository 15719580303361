import { useEffect, useContext, useState } from "react";
import UserContext from "../../UserContext";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import Card from "../../components/Card";
import Bars from "../../components/Graficos/BarsChart";
import { useTranslation } from "react-i18next";
import "./PasosPage.css";
import TituloyFlecha from "../../components/TituloyFlecha";
const Pasos = () => {
  const email = useContext(UserContext);
  const [chartData, setChartData] = useState(null); // Estado para controlar los datos del gráfico
  const [selectedDate, setSelectedDate] = useState();
  const [t] = useTranslation("global");
  const [pasosArray, setPasosArray] = useState([]);
  const [timestampsArray, setTimestampsArray] = useState([]);
  const [nfecha, setNfecha] = useState(0);
  const [distancia, setDistancia] = useState(null);
  const [tiempo, setTiempo] = useState(null);
  const { contenido } = useParams();
  const [contenidoAMostrar, setContenidoAMostrar] = useState(null);
  const [finprocesado, setFinprocesado] = useState(false);
  const [colorFondoboton1, setColorFondoboton1] = useState("#b9ddd7");
  const [colorFondoboton2, setColorFondoboton2] = useState("#b9ddd7");
  const [colorFondoboton3, setColorFondoboton3] = useState("#b9ddd7");
  const [colorFondoboton4, setColorFondoboton4] = useState("#b9ddd7");
  const [colorTexto1, setColorTexto1] = useState("black");
  const [colorTexto2, setColorTexto2] = useState("black");
  const [colorTexto3, setColorTexto3] = useState("black");
  const [colorTexto4, setColorTexto4] = useState("black");
  const [listadias, setListadias] = useState(null);
  const [valores_dia, setValores_dia] = useState(null);
  const [minutos_dia, setMinutos_dia] = useState(null);
  const [fecha, setFecha] = useState(null);
  const [periodicidad] = useState(30); //no sé por qué pero tienes que poner la mitad de lo que quieres que se muestre en el eje x :)
  const [textocuadro, setTextocuadro] = useState(t("Actividad-diaria-media"));
  const [diariossinagrupar, setDiariossinagrupar] = useState(null);
  
  const locale = {
    dateTime: "%A, %e de %B de %Y, %X",
    date: "%d/%m/%Y",
    time: "%H:%M:%S",
    periods: ["AM", "PM"],
    days: [
      t("DiasSemana.Domingo"),
      t("DiasSemana.Lunes"),
      t("DiasSemana.Martes"),
      t("DiasSemana.Miercoles"),
      t("DiasSemana.Jueves"),
      t("DiasSemana.Viernes"),
      t("DiasSemana.Sabado"),
    ],
    shortDays: [
      t("shortDiasSemana.Domingo"),
      t("shortDiasSemana.Lunes"),
      t("shortDiasSemana.Martes"),
      t("shortDiasSemana.Miercoles"),
      t("shortDiasSemana.Jueves"),
      t("shortDiasSemana.Viernes"),
      t("shortDiasSemana.Sabado"),
    ],
    months: [
      t("Meses.Enero"),
      t("Meses.Febrero"),
      t("Meses.Marzo"),
      t("Meses.Abril"),
      t("Meses.Mayo"),
      t("Meses.Junio"),
      t("Meses.Julio"),
      t("Meses.Agosto"),
      t("Meses.Septiembre"),
      t("Meses.Octubre"),
      t("Meses.Noviembre"),
      t("Meses.Diciembre"),
    ],
    shortMonths: [
      t("shortMeses.Enero"),
      t("shortMeses.Febrero"),
      t("shortMeses.Marzo"),
      t("shortMeses.Abril"),
      t("shortMeses.Mayo"),
      t("shortMeses.Junio"),
      t("shortMeses.Julio"),
      t("shortMeses.Agosto"),
      t("shortMeses.Septiembre"),
      t("shortMeses.Octubre"),
      t("shortMeses.Noviembre"),
      t("shortMeses.Diciembre"),
    ],
  };

  useEffect(() => {
    if (contenidoAMostrar === "0") {
      if (valores_dia && minutos_dia) {
        setChartData(getChartData());
      }
    } else if (pasosArray && timestampsArray && finprocesado === true) {
      setChartData(getChartData());
    }
  }, [
    valores_dia,
    minutos_dia,
    pasosArray,
    timestampsArray,
    finprocesado,
    nfecha,
  ]);

  useEffect(() => {
    // Verificar si el correo electrónico no es nulo antes de llamar a la función
    if (email && contenidoAMostrar) {
      setFinprocesado(false);
      setNfecha(0);
      consulta_distancia();
      consulta_tiempo();
      if (contenidoAMostrar === "0") {
        consulta_pasos();
      } else {
        consulta_pasos_agrupados();
      }
    }
  }, [email, contenidoAMostrar]);

  useEffect(() => {
    setChartData(null);
    if (contenido) {
      // Console.log("Contenido:", contenido);
      switch (contenido) {
        case "semana":
          setColorFondoboton1("#b9ddd7");
          setColorFondoboton2("#518ea6");
          setColorFondoboton3("#b9ddd7");
          setColorFondoboton4("#b9ddd7");
          setColorTexto1("black");
          setColorTexto2("white");
          setColorTexto3("black");
          setColorTexto4("black");
          setContenidoAMostrar("1");
          setTextocuadro(t("Actividad-diaria-media-semana"));
          break;
        case "mes":
          setColorFondoboton1("#b9ddd7");
          setColorFondoboton2("#b9ddd7");
          setColorFondoboton3("#518ea6");
          setColorFondoboton4("#b9ddd7");
          setColorTexto1("black");
          setColorTexto2("black");
          setColorTexto3("white");
          setColorTexto4("black");
          setContenidoAMostrar("2");
          setTextocuadro(t("Actividad-diaria-media-mes"));
          break;
        case "ano":
          setColorFondoboton1("#b9ddd7");
          setColorFondoboton2("#b9ddd7");
          setColorFondoboton3("#b9ddd7");
          setColorFondoboton4("#518ea6");
          setColorTexto1("black");
          setColorTexto2("black");
          setColorTexto3("black");
          setColorTexto4("white");
          setContenidoAMostrar("3");
          setTextocuadro(t("Actividad-diaria-media-ano"));
          break;
        default:
          setColorFondoboton1("#518ea6");
          setColorFondoboton2("#b9ddd7");
          setColorFondoboton3("#b9ddd7");
          setColorFondoboton4("#b9ddd7");
          setColorTexto1("white");
          setColorTexto2("black");
          setColorTexto3("black");
          setColorTexto4("black");
          setContenidoAMostrar("0");
          setTextocuadro(t("Actividad-diaria-total"));
      }
    }
  }, [contenido]);

  const formatearNumeroConPuntoDeMiles = (numero) => {
    if (numero === null) {
      return 0;
    } else {
      return numero.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
  };

  const consulta_pasos = async () => {
    const currentDate = new Date();
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(currentDate.getDate() - 365);

    const formattedCurrentDate = currentDate.toISOString().split("T")[0];
    const formattedOneWeekAgo = oneWeekAgo.toISOString().split("T")[0];

    try {
      const response = await axios.get(t("Recursos.ip") + "/pasos", {
        params: {
          email: email.email,
          startTime: formattedOneWeekAgo,
          endTime: formattedCurrentDate,
        },
      });

      console.log("Sin agrupar", response.data);

      // Obtener los arrays de pasos y timestamps de la respuesta
      const pasosArray = response.data.map((item) => item.numeropasos);
      const timestampsArray = response.data.map(
        (item) => new Date(item.timestamp)
      );

      // Dividir los timestamps por día
      const dividedTimestampArrays = divideDatesByDay(timestampsArray);

      // Actualizar los estados con los datos divididos
      setPasosArray(pasosArray);
      setTimestampsArray(timestampsArray);

      const listad = agruparPorDiaYOrdenar(response.data);
      setListadias(listad);
      setFecha(listad[0].fecha);
      const valores = obtenernumeropasosPorPosicion(listad, 0);
      setValores_dia(calcularSumaCadaIntervalo(valores, periodicidad));
      setMinutos_dia(minutosDelDiaConIntervalo(periodicidad));
      console.log("pasosArray", timestampsArray);
      // console.log("dividedPasosArrays", dividedPasosArrays);

      setSelectedDate(Object.keys(dividedTimestampArrays)[nfecha]);
      setFinprocesado(true);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const consulta_pasos_agrupados = async () => {
    const currentDate = new Date();
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(currentDate.getDate() - 365);

    const formattedCurrentDate = currentDate.toISOString().split("T")[0];
    const formattedOneWeekAgo = oneWeekAgo.toISOString().split("T")[0];


    try {
      const response = await axios.get(t("Recursos.ip") + "/pasos/grouped", {
        params: {
          email: email.email,
          startTime: formattedOneWeekAgo,
          endTime: formattedCurrentDate,
          granularidad: 1, // 1 por dias, 2 por semana y 3 por mes
        },
      });

      let arrayagrupado = null;
      switch (contenidoAMostrar) {
        case "1":
          arrayagrupado = combinarArraysDias(
            generarArrayDiasaSemana(new Date()),
            response.data
          );
          setPasosArray(agruparPorSemanas(arrayagrupado, "numeropasos"));
          setTimestampsArray(
            convertirFormato(agruparPorSemanas(arrayagrupado, "timestamp"))
          );
          break;
        case "2":
          console.log("Meses",  agruparPorMes(response.data))
          arrayagrupado = combinarArraysDias(
            generarArrayDias(UltimoDiaDelMes(new Date())),
            response.data
          );
          setPasosArray(agruparPorMeses(arrayagrupado, "numeropasos"));
          setTimestampsArray(
            convertirFormato(agruparPorMeses(arrayagrupado, "timestamp"))
          );
          break;
        case "3":
          console.log("Ano",  response.data)
          setDiariossinagrupar(response.data)
          arrayagrupado =  agruparPorMes(
            response.data
          );
          console.log("Ano", arrayagrupado)
          setPasosArray(arrayagrupado.map((item) => item.numeropasos));
          setTimestampsArray(convertirFormatoMes(arrayagrupado));
          break;
        default:
          arrayagrupado = combinarArraysDias(
            generarArrayDiasaSemana(new Date()),
            response.data
          );
          setPasosArray(agruparPorSemanas(arrayagrupado, "numeropasos"));
          setTimestampsArray(agruparPorSemanas(arrayagrupado, "timestamp"));
      }
      // ////Console.log("arrayagrupado", arrayagrupado);
      // ////Console.log(
      //   "Timestamp",
      //   convertirFormato(agruparPorMeses(arrayagrupado, "timestamp"))
      // );
      // ////Console.log("Pasos", agruparPorMeses(arrayagrupado, "numeropasos"));

      setFinprocesado(true);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  function agruparPorMes(datos) { //ESTO SOLO VA CON 2024 ACTUALMENTE PERO QUEDAN 7 DIAS PARA EL PILOTO Y LO VA A CORREGIR QUIEN YO ME SÉ
    const pasosPorMes = Array(12).fill(0);
    datos.forEach(dato => {
      const fecha = new Date(dato.timestamp);
      const mes = fecha.getMonth();
      pasosPorMes[mes] += dato.numeropasos;
    });
    const resultado = pasosPorMes.map((totalPasos, indice) => {
      const fecha = new Date(2024, indice, 1);
      return {
        numeropasos: totalPasos,
        timestamp: fecha.toISOString()
      };
    });
    return resultado;
  }
  const consulta_distancia = async () => {
    const currentDate = new Date();
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(currentDate.getDate() - 365);
    const formattedCurrentDate = currentDate.toISOString().split("T")[0];
    const formattedOneWeekAgo = oneWeekAgo.toISOString().split("T")[0];

    try {
      const response = await axios.get(t("Recursos.ip") + "/distancia", {
        params: {
          email: email.email,
          startTime: formattedOneWeekAgo,
          endTime: formattedCurrentDate,
        },
      });
      //console.log("Distancia: ", response.data);
      setDistancia(response.data);

      //setFecha(formatearFecha(response.data[0].fecha));
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const consulta_tiempo = async () => {
    const currentDate = new Date();
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(currentDate.getDate() - 365);
    const formattedCurrentDate = currentDate.toISOString().split("T")[0];
    const formattedOneWeekAgo = oneWeekAgo.toISOString().split("T")[0];

    try {
      const response = await axios.get(t("Recursos.ip") + "/tiempoactivo", {
        params: {
          email: email.email,
          startTime: formattedOneWeekAgo,
          endTime: formattedCurrentDate,
        },
      });
      console.log("Tiempo: ", response.data);
      setTiempo(response.data);

      //setFecha(formatearFecha(response.data[0].fecha));
    } catch (error) {
      console.error("Error:", error);
    }
  };
  function convertirFormato(data) {
    const formattedData = data
      .map((arr) =>
        arr
          .map((timestamp) => {
            if (!timestamp) return undefined;

            const [fecha, hora] = timestamp.split("T");
            const [ano, mes, dia] = fecha.split("-");
            return `${dia}/${mes}`;
          })
          .filter((formattedTimestamp) => formattedTimestamp !== undefined)
      )
      .filter((arr) => arr.length > 0);

    return formattedData;
  }

  function agruparPorDiaYOrdenar(array) {
    // Utilizamos reduce para agrupar por día
    const agrupadoPorDia = array.reduce((resultado, elemento) => {
      // Extraemos la fecha del timestamp
      const fecha = new Date(elemento.timestamp).toLocaleDateString();

      // Si la fecha no está en el resultado, la inicializamos con un array vacío
      if (!resultado[fecha]) {
        resultado[fecha] = [];
      }

      // Agregamos el elemento al array correspondiente a la fecha
      resultado[fecha].push(elemento);

      return resultado;
    }, {});

    // Obtenemos las fechas únicas y las ordenamos de manera descendente
    const fechasOrdenadas = Object.keys(agrupadoPorDia).sort(
      (a, b) => new Date(b) - new Date(a)
    );

    // Creamos un array final ordenado por fecha
    const resultadoFinal = fechasOrdenadas.map((fecha) => ({
      fecha,
      datos: agrupadoPorDia[fecha],
    }));

    return resultadoFinal;
  }
  function obtenernumeropasosPorPosicion(listaCompleta, posicion) {
    // Obtener el conjunto de datos correspondiente a la posición indicada
    const datosDiaSeleccionado = listaCompleta[posicion]?.datos;

    if (datosDiaSeleccionado) {
      // Aplicar la función numeropasos_ultimodia al conjunto de datos del día seleccionado
      const resultadoDia = numeropasos_ultimodia(datosDiaSeleccionado);

      // Devolver el resultado
      return resultadoDia;
    } else {
      ////Console.log("No se encontraron datos para la posición indicada.");
      return [];
    }
  }
  function numeropasos_ultimodia(data) {
    // Obtén la fecha más reciente
    const latestDate = new Date(
      Math.max(...data.map((item) => new Date(item.timestamp)))
    );

    // Crea un objeto para almacenar los valores de datos para cada minuto del día
    const minuteData = {};

    // Llena el objeto con valores existentes de datos
    data.forEach((item) => {
      const itemDate = new Date(item.timestamp);
      if (itemDate.toDateString() === latestDate.toDateString()) {
        const hours = itemDate.getHours();
        const minutes = itemDate.getMinutes();
        const key = `${hours < 10 ? "0" + hours : hours}:${
          minutes < 10 ? "0" + minutes : minutes
        }`;
        minuteData[key] = item.numeropasos;
      }
    });

    // Crea el array resultante con 1440 elementos, rellenando con 0 cuando no hay datos
    const resultArray = [];
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 60; j++) {
        const key = `${i < 10 ? "0" + i : i}:${j < 10 ? "0" + j : j}`;
        const numeropasos = minuteData[key] || 0;
        resultArray.push({
          timestamp: `${latestDate.getFullYear()}-${
            latestDate.getMonth() + 1
          }-${latestDate.getDate()} ${key}:00.001`,
          numeropasos,
        });
      }
    }
    return resultArray;
  }
  const minutosDelDiaConIntervalo = (intervaloMinutos) => {
    const minutos = [];

    for (let hora = 0; hora < 24; hora++) {
      for (let minuto = 0; minuto < 60; minuto += intervaloMinutos) {
        const horaFormateada = hora < 10 ? `0${hora}` : `${hora}`;
        const minutoFormateado = minuto < 10 ? `0${minuto}` : `${minuto}`;
        minutos.push(`${horaFormateada}:${minutoFormateado}`);
      }
    }

    return minutos;
  };
  function calcularSumaCadaIntervalo(datos, minutosIntervalo) {
    const sumaArray = [];
    let sum = 0;

    for (let i = 0; i < datos.length; i++) {
      const valor = datos[i].numeropasos;

      // Si el valor no es 0, agrégalo a la suma
      if (valor !== 0) {
        sum += valor;
      }

      if (
        i === datos.length - 1 ||
        (i > 0 && (i + 1) % minutosIntervalo === 0)
      ) {
        const media = sum;
        sumaArray.push(media);
        sum = 0;
      }
    }

    return sumaArray;
  }
  function convertirFormatoMes(data) {
    return data.map((obj) => {
      const timestamp = obj.timestamp;
      // eslint-disable-next-line no-unused-vars
      const [fecha, hora] = timestamp.split("T");
      // eslint-disable-next-line no-unused-vars
      const [anio, mes, dia] = fecha.split("-");
      const nombreMes = locale.months[parseInt(mes, 10) - 1];
      return `${nombreMes}`;
    });
  }

  function agruparPorSemanas(datos, propiedad = "todos") {
    // Ordenar el array por timestamp
    datos.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

    // Inicializar el array de resultado
    const resultado = [];

    // Iterar sobre los datos
    datos.forEach((dato) => {
      // Obtener el día de la semana (0: domingo, 1: lunes, ..., 6: sábado)
      const diaSemana = new Date(dato.timestamp).getDay();

      // Verificar si es lunes (día de la semana 1)
      if (diaSemana === 1) {
        // Inicializar el array para la nueva semana
        resultado.push([]);
      }

      // Determinar qué propiedad agregar al resultado
      if (propiedad === "todos") {
        // Si se solicitan todos los datos, agregar el objeto completo
        resultado[resultado.length - 1].push({ ...dato });
      } else {
        // Agregar la propiedad específica al resultado
        resultado[resultado.length - 1].push(dato[propiedad]);
      }
    });

    return resultado;
  }

  const UltimoDiaDelMes = (fecha) => {
    // Obtener el último día del mes
    const ultimoDia = new Date(fecha.getFullYear(), fecha.getMonth() + 1, 0);

    return ultimoDia;
  };

  function agruparPorMeses(datos, propiedad = "todos") {
    // Ordenar el array por timestamp
    datos.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

    // Inicializar el array de resultado para todos los meses
    const resultado = Array.from({ length: 12 }, () => []); //Si por aqui hay errores probar a cambiar el numero de meses

    // Iterar sobre los datos
    datos.forEach((dato) => {
      // Obtener el mes (0: enero, 1: febrero, ..., 11: diciembre)
      const mes = new Date(dato.timestamp).getMonth();

      // Determinar qué propiedad agregar al resultado
      if (propiedad === "todos") {
        // Si se solicitan todos los datos, agregar el objeto completo
        resultado[mes].push({ ...dato });
      } else {
        // Agregar la propiedad específica al resultado
        resultado[mes].push(dato[propiedad]);
      }
    });

    return eliminarSubarraysVacios(resultado);
  }

  function eliminarSubarraysVacios(arr) {
    // Filtrar el array para eliminar los subarrays vacíos
    const resultado = arr.filter((subarray) => subarray.length > 0);

    // Determinar la longitud máxima de los subarrays no vacíos
    const longitudMaxima = resultado.reduce(
      (max, subarray) => Math.max(max, subarray.length),
      0
    );

    // Rellenar los subarrays con longitud menor que la máxima con subarrays vacíos
    resultado.forEach((subarray) => {
      while (subarray.length < longitudMaxima) {
        subarray.push(undefined); // Puedes cambiar undefined por cualquier valor deseado
      }
    });

    return resultado;
  }
  function generarArrayDiasaSemana(fechaActual) {
    const añoActual = fechaActual.getFullYear();
    const diaActual = fechaActual.getDay();
    const primerDiaAño = new Date(añoActual, 0, 1);
    const primerLunesAño = new Date(
      añoActual,
      0,
      1 + ((8 - primerDiaAño.getDay()) % 7)
    );
    const diasTranscurridos = Math.ceil(
      (fechaActual - primerLunesAño) / (24 * 60 * 60 * 1000)
    );
    const diasPorSemana = 7;
    const arrayDias = [];

    for (let i = 0; i < diasTranscurridos + diasPorSemana - diaActual; i++) {
      const fecha = new Date(primerLunesAño);
      fecha.setDate(fecha.getDate() + i);

      if (fecha.getFullYear() > añoActual) {
        break;
      }

      const year = fecha.getFullYear();
      const month = (fecha.getMonth() + 1).toString().padStart(2, "0");
      const day = fecha.getDate().toString().padStart(2, "0");
      const hours = "00";
      const minutes = "00";

      const timestamp = `${year}-${month}-${day}T${hours}:${minutes}:00`;

      arrayDias.push({
        numeropasos: 0,
        timestamp,
      });
    }
    return arrayDias;
  }

  function generarArrayDias(fechaActual) {
    const añoActual = fechaActual.getFullYear();
    const primerDiaAño = new Date(añoActual, 0, 1);
    const diasTranscurridos = Math.ceil(
      (fechaActual - primerDiaAño) / (24 * 60 * 60 * 1000)
    );
    const arrayDias = [];

    for (let i = 0; i <= diasTranscurridos; i++) {
      const fecha = new Date(primerDiaAño);
      fecha.setDate(fecha.getDate() + i);

      if (fecha > fechaActual) {
        break;
      }

      const year = fecha.getFullYear();
      const month = (fecha.getMonth() + 1).toString().padStart(2, "0");
      const day = fecha.getDate().toString().padStart(2, "0");
      const hours = "00";
      const minutes = "00";

      const timestamp = `${year}-${month}-${day}T${hours}:${minutes}:00`;

      arrayDias.push({
        numeropasos: 0,
        timestamp,
      });
    }

    return arrayDias;
  }

  function generarArrayPrimerDiaDelMes() {
    // Obtener el año actual
    const anoActual = new Date().getFullYear();

    // Inicializar el array de resultado
    const resultado = [];

    // Iterar sobre los meses del año actual
    for (let mes = 1; mes < 13; mes++) {
      // Crear la fecha para el primer día del mes actual
      const primerDiaMes = new Date(anoActual, mes, 1);

      // Establecer la hora a medianoche (00:00:00 UTC)
      primerDiaMes.setUTCHours(0, 0, 0, 0);

      // Ajustar el día del mes a 1
      primerDiaMes.setUTCDate(1);

      // Formatear la fecha sin la "Z" al final
      const fechaFormateada = primerDiaMes.toISOString().slice(0, -5);

      // Agregar al resultado
      resultado.push({
        numeropasos: 0,
        timestamp: fechaFormateada,
      });
    }

    return resultado;
  }

  function combinarArraysDias(arrayAno, arrayDias) {
    // Iterar a través de cada semana en el array de semanas

    for (const dia of arrayAno) {
      // Encontrar el índice del día en el array de días
      const indiceDia = arrayDias.findIndex(
        (d) => d.timestamp === dia.timestamp
      );

      // Si se encuentra el día, sustituir el elemento
      if (indiceDia !== -1) {
        arrayAno[arrayAno.indexOf(dia)] = arrayDias[indiceDia];
      }
    }
    return arrayAno;
  }

  function divideDatesByDay(datesArray) {
    const groupedDates = datesArray.reduce((result, date) => {
      const formattedDate = `${date.getDate()}-${
        date.getMonth() + 1
      }-${date.getFullYear()}`;
      const formattedTime = `${date.getHours()}:${date.getMinutes()}`;

      if (!result[formattedDate]) {
        result[formattedDate] = [];
      }

      result[formattedDate].push(formattedTime);

      return result;
    }, {});

    return groupedDates;
  }
  function cambiarNfecha(n) {
    if (contenidoAMostrar === "0") {
      if ((n < 0 && nfecha > 0) || (n > 0 && nfecha < listadias.length - 1)) {
        setFecha(listadias[nfecha + n].fecha);
        const valores = obtenernumeropasosPorPosicion(listadias, nfecha + n);

        setValores_dia(calcularSumaCadaIntervalo(valores, periodicidad));
        setNfecha(nfecha + n);
      }
    } else {
      if ((n < 0 && nfecha > 0) || (n > 0 && nfecha < pasosArray.length - 1)) {
        setNfecha(nfecha + n);
      }
    }
  }

  function formatFecha(opcion) {
    switch (opcion) {
      case "1":
        const currentYear = new Date().getFullYear(); // Obtener el año actual

        const parseDate = (dateString) => {
          const [day, month] = dateString.split("/").map(Number);
          return new Date(currentYear, month - 1, day); // Restamos 1 al mes ya que en JavaScript los meses van de 0 a 11
        };

        const startDate = parseDate([...timestampsArray].reverse()[nfecha][0]);
        const endDate = parseDate([...timestampsArray].reverse()[nfecha][6]);

        const startDay = startDate.getDate();
        const startMonth = locale.shortMonths[startDate.getMonth()];
        const endDay = endDate.getDate();
        const endMonth = locale.shortMonths[endDate.getMonth()];

        return `${startDay} ${startMonth} - ${endDay} ${endMonth}`;
      case "2":
        return [...timestampsArray].reverse()[nfecha].map((label) => {
          //Esta linea es para ignora el warning pero realmente necesito dia para separarlo
          // eslint-disable-next-line no-unused-vars
          const [dia, mes] = label.split("/");
          const nombreMes = locale.months[parseInt(mes, 10) - 1];
          return `${nombreMes}`;
        })[0];

      case "3":
        const fechaActual = new Date();
        const añoActual = fechaActual.getFullYear();
        return añoActual;

      default:
        return fecha;
    }
  }

  function distanciaMostrada() {
    let total;
    
    switch (contenidoAMostrar) {
      case "1":
        total = mediaMetrosEnRango(
          distancia,
          convertirFormatoFecha([...timestampsArray].reverse()[nfecha][0]),
          convertirFormatoFecha([...timestampsArray].reverse()[nfecha][6])
        );
        break;
      case "2":
        total = mediaMetrosEnRango(
          distancia,
          convertirFormatoFecha([...timestampsArray].reverse()[nfecha][0]),
          convertirFormatoFecha(
            [...timestampsArray].reverse()[nfecha][
              [...timestampsArray].reverse()[nfecha].length - 1
            ]
          )
        );
        break;
      case "3":
        total = mediaMetrosEnRango(
          distancia,
          obtenerDiaAño(false),
          obtenerDiaAño(true)
        );
        break;
      default:
        //////Console.log(convertirFormatoFecha(selectedDate))
        total = mediaMetrosEnRango(
          distancia,
          convertirFormatoFecha(formatFecha(contenidoAMostrar)),
          convertirFormatoFecha(formatFecha(contenidoAMostrar))
        );
    }
    if (isNaN(total)) {
      total = 0;
    }

    return total;
  }

  function tiempoMostrado(array) {
    let sumaPasos = 0;
    let diasConPasos = 0;
    if(contenidoAMostrar==="3"){
      array=agruparPorMesDatos(diariossinagrupar)
    }
    array.forEach((valor) => {
      if (valor > 0) {
        // Considera solo los días con actividad
        sumaPasos += valor;
        diasConPasos++; // Incrementa el contador solo para días con pasos
      }
    });
 
    switch (contenidoAMostrar) {
      case "1":
        return (
          sumarMinutosEnRango(
            tiempo,
            convertirFormatoFecha([...timestampsArray].reverse()[nfecha][0]),
            convertirFormatoFecha([...timestampsArray].reverse()[nfecha][6])
          ) / diasConPasos
        );

      case "2":
       
        return (
          sumarMinutosEnRango(
            tiempo,
            convertirFormatoFecha([...timestampsArray].reverse()[nfecha][0]),
            convertirFormatoFecha(
              [...timestampsArray].reverse()[nfecha][
                [...timestampsArray].reverse()[nfecha].length - 1
              ]
            )
          ) / diasConPasos
          
        );
       

      case "3":
        return (
          sumarMinutosEnRango(
            tiempo,
            obtenerDiaAño(false),
            obtenerDiaAño(true)
          ) /  diasConPasos
        );

      default:
        // ////Console.log(convertirFormatoFecha(selectedDate))
        return sumarMinutosEnRango(
          tiempo,
          convertirFormatoFecha(formatFecha(contenidoAMostrar)),
          convertirFormatoFecha(formatFecha(contenidoAMostrar))
        );
    }
  }
  function obtenerPosicionesConNumero(array) {
    return array.reduce((posiciones, valor, indice) => {
      if (valor !== 0) {
        posiciones.push(indice + 1);
      }
      return posiciones;
    }, []);
  }
  function obtenerTotalDias(meses) {
    const diasPorMes = [
      31, // Enero
      28, // Febrero en año bisiestos tenemos un error de 1 dia pero bueno eso no toca hasat dentro de 4 años xD
      31, // Marzo
      30, // Abril
      31, // Mayo
      30, // Junio
      31, // Julio
      31, // Agosto
      30, // Septiembre
      31, // Octubre
      30, // Noviembre
      31, // Diciembre
    ];

    let totalDias = 0;
    meses.forEach((mes) => {
      totalDias += diasPorMes[mes - 1];
    });

    return totalDias;
  }
  function agruparPorMesDatos(datos) {
    return datos.map(dato => dato.numeropasos);
  }
  function pasosMostrado(array) {
    let total;
    let sumaPasos = 0;
    let diasConPasos = 0;
    if(contenidoAMostrar==="3"){
      array=agruparPorMesDatos(diariossinagrupar)
    }
    
    array.forEach((valor) => {
      if (valor > 0) {
        // Considera solo los días con actividad
        sumaPasos += valor;
        diasConPasos++; // Incrementa el contador solo para días con pasos
      }
    });
    console.log("PAsos",diasConPasos)
    switch (contenidoAMostrar) {
      case "1":
        total = sumaPasos / diasConPasos;
        break;
      case "2":
        total = sumaPasos / diasConPasos;
        break;
      case "3":
        total = sumaPasos / diasConPasos; //obtenerTotalDias(obtenerPosicionesConNumero(array));
        break;
      default:
        total = sumaPasos;
    }
    if (isNaN(total)) {
      total = 0;
    }

    return total;
  }

  function convertirFormatoFecha(fecha) {
    const fechaParts = fecha.split(/[/-]/); // Permitir tanto "/" como "-" como separadores

    if (fechaParts.length >= 2) {
      const [dd, mm, yyyy] = fechaParts;

      // Obtener el año actual si no se proporciona
      const year = yyyy
        ? yyyy.padStart(4, "0")
        : new Date().getFullYear().toString();

      // Asegurar que dd y mm tengan dos dígitos
      const ddFormatted = dd.padStart(2, "0");
      const mmFormatted = mm.padStart(2, "0");

      return `${year}-${mmFormatted}-${ddFormatted}`;
    } else {
      return null;
    }
  }
  function obtenerDiaAño(obtenerUltimoDia = false) {
    const fecha = new Date();
    const añoActual = fecha.getFullYear();
    const primerDia = new Date(añoActual, 0, 1);
    const ultimoDia = new Date(añoActual + 1, 0, 0);

    const formatoFecha = (date) => {
      const dd = String(date.getDate()).padStart(2, "0");
      const mm = String(date.getMonth() + 1).padStart(2, "0"); // Sumar 1 porque los meses van de 0 a 11
      const yyyy = date.getFullYear();
      return `${yyyy}-${mm}-${dd}`;
    };

    return obtenerUltimoDia ? formatoFecha(ultimoDia) : formatoFecha(primerDia);
  }

  function mediaMetrosEnRango(fechas, rangoInicio, rangoFin) {
    // Validar el formato de las fechas en los datos
    const formatoCorrecto = fechas.every((fecha) => {
      return /^\d{4}-\d{2}-\d{2}$/.test(fecha.fecha);
    });

    if (!formatoCorrecto) {
      console.error(
        "Error: El formato de las fechas en los datos no es correcto."
      );
      return { totalMetros: 0, conteoSumasNoCero: 0 };
    }

    // Filtrar las fechas que están en el rango
    const fechasEnRango = fechas.filter((fecha) => {
      return fecha.fecha >= rangoInicio && fecha.fecha <= rangoFin;
    });

    // Inicializar variables para la suma y el conteo
    let totalMetrosEnRango = 0;
    let conteoSumasNoCero = 0;

    // Sumar los metros para las fechas en el rango y contar las sumas distintas de 0
    fechasEnRango.forEach((fecha) => {
      totalMetrosEnRango += fecha.metros;
      if (fecha.metros !== 0) {
        conteoSumasNoCero += 1;
      }
    });

    return totalMetrosEnRango / conteoSumasNoCero;
  }

  function sumarMinutosEnRango(fechas, rangoInicio, rangoFin) {
    // Validar el formato de las fechas en los datos
    const formatoCorrecto = fechas.every((fecha) => {
      return /^\d{4}-\d{2}-\d{2}$/.test(fecha.fecha);
    });

    if (!formatoCorrecto) {
      console.error(
        "Error: El formato de las fechas en los datos no es correcto."
      );
      return 0;
    }

    // Filtrar las fechas que están en el rango
    const fechasEnRango = fechas.filter((fecha) => {
      return fecha.fecha >= rangoInicio && fecha.fecha <= rangoFin;
    });

    // Sumar los minutos para las fechas en el rango
    const totalMinutosEnRango = fechasEnRango.reduce((total, fecha) => {
      return total + fecha.minutos;
    }, 0);

    return totalMinutosEnRango;
  }

  function convertirMinutosAHoras(minutos) {
    if (isNaN(minutos) || minutos < 0) {
      return "0 min";
    }
  
    const horas = Math.floor(minutos / 60);
    const minutosRestantes = Math.floor(minutos % 60); // Redondeamos hacia abajo los minutos
  
    if (minutos === 0) {
      return "0 h 0 min";
    }
  
    let resultado = "";
  
    if (horas > 0) {
      resultado += `${horas} h `;
    }
  
    if (minutosRestantes > 0) {
      resultado += `${minutosRestantes} min`;
    }
  
    return resultado.trim(); // Eliminamos espacios al final si no hay minutos
  }
  

  const getChartData = () => {
    // Crear copias invertidas de los arrays
    let reversedLabels = null;
    let reversedPasosData = null;
    switch (contenidoAMostrar) {
      case "0":
        return {
          labels: minutos_dia,
          datasets: [
            {
              label: t("Pasos"),
              data: valores_dia,
              tension: 0.5,
              fill: true,
              borderColor: "#F8DE22",
              backgroundColor: "#F8DE22",
              pointRadius: 1,
              pointBorderColor: "#F8DE22",
              pointBackgroundColor: "#F8DE22",
            },
          ],
        };

      case "2":
        reversedLabels = [...timestampsArray]
          .reverse()
          [nfecha].map((date) => date.split("/")[0]); //Mira te soy sincero, esto lo hice para igualar la visualizacion del eje x con el resto de la pagina, es una chapuza que flipas PERO NO TENGO TIEMPO PARA HACERLO BIEN, lo siento
        reversedPasosData = [...pasosArray].reverse()[nfecha];

        break;
      case "3":
        reversedLabels = [...timestampsArray];
        reversedPasosData = [...pasosArray];

        break;
      default:
        const options = { weekday: "long", day: "2-digit" };
        reversedLabels = [...timestampsArray].reverse()[nfecha].map((date) => {
          const [day, month] = date.split("/");
          const formattedDate = new Date(
            `2024-${month}-${day}`
          ).toLocaleDateString(locale, options);
          return `${
            formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1)
          }`;
        });
        reversedPasosData = [...pasosArray].reverse()[nfecha];
    }

    return {
      labels: reversedLabels,
      datasets: [
        {
          label: "Pasos",
          data: reversedPasosData,
          tension: 0.5,
          fill: true,
          borderColor: "#F8DE22",
          backgroundColor: "#F8DE22",
          pointRadius: 0.1,
          pointBorderColor: "#F8DE22",
          pointBackgroundColor: "#F8DE22",
        },
      ],
    };
  };

  const misoptions = {
    plugins: {
      legend: {
        display: false, // Deshabilita la leyenda (incluido el botón para ocultar líneas)
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        suggestedMax: 100,
        title: {
          display: true,
          text: t("Pasos"),
          color: "black",
        },
      },
      x: {
        title: {
          display: true,
          text:
            contenido === "Dia"
              ? t("Hora")
              : contenido === "semana" || contenido === "mes"
              ? t("Dia")
              : contenido === "ano"
              ? t("Mes")
              : "",
        },
        ticks: { color: "black" },
      },
    },
  };

  return (
    <div>
        <TituloyFlecha
        title={t("Actividad")}
        link={"/MiSalud"}
      />

      <div
        style={{
          marginTop: "1%",
          marginBottom: "1%",
          margin: window.innerWidth <= 768 ? "3%" : "1%",
        }}
      >
        <Link to="/MiActividadFisica/Dia">
          <button
            className="botongranuralidadPasosPage"
            style={{ backgroundColor: colorFondoboton1, color: colorTexto1 }}
          >
            {t("Dia")}
          </button>
        </Link>
        <Link to="/MiActividadFisica/semana">
          <button
            className="botongranuralidadPasosPage"
            style={{ backgroundColor: colorFondoboton2, color: colorTexto2 }}
          >
            {t("Semana")}
          </button>
        </Link>
        <Link to="/MiActividadFisica/mes">
          <button
            className="botongranuralidadPasosPage"
            style={{ backgroundColor: colorFondoboton3, color: colorTexto3 }}
          >
            {t("Mes")}
          </button>
        </Link>
        <Link to="/MiActividadFisica/ano">
          <button
            className="botongranuralidadPasosPage"
            style={{ backgroundColor: colorFondoboton4, color: colorTexto4 }}
          >
            {t("Ano")}
          </button>
        </Link>
      </div>
      <div className="containerBodyPasos">
        <div className="containerGrafFechaPasos">
          {chartData ? (
            <div style={{ height: "100%", width: "100%" }}>
              <div className="centerPasosPage1">
                {nfecha < Object.keys(timestampsArray).length - 1 &&
                contenidoAMostrar !== "3" ? (
                  <div style={{ visibility: "visible", marginRight: "12%" }}>
                    <button
                      className="botoncircPasosPage"
                      onClick={() => cambiarNfecha(1)}
                    >
                      {t("Anterior")}
                    </button>
                  </div>
                ) : (
                  <div style={{ visibility: "hidden" }}>
                    <button onClick={() => cambiarNfecha(1)}>
                      {t("Anterior")}
                    </button>
                  </div>
                )}
                <p
                  style={{
                    fontSize: "1.1rem",
                    color: "black",
                    width: "150%",
                    marginLeft: "-25%",
                  }}
                >
                  {<b>{formatFecha(contenidoAMostrar)}</b>}
                </p>
                {nfecha > 0 && contenidoAMostrar !== "3" ? (
                  <div style={{ visibility: "visible", marginLeft: "12%" }}>
                    <button
                      className="botoncircPasosPage"
                      onClick={() => cambiarNfecha(-1)}
                    >
                      {t("Siguiente")}
                    </button>
                  </div>
                ) : (
                  <div style={{ visibility: "hidden" }}>
                    <button onClick={() => cambiarNfecha(-1)}>
                      {t("Siguiente")}
                    </button>
                  </div>
                )}
              </div>

              <div className="graficoPasosPage">
                <Bars data={chartData} options={misoptions} />
              </div>
            </div>
          ) : selectedDate ? (
            <p>
              {" "}
              <b>{t("Conectando-BD")}</b>{" "}
            </p>
          ) : (
            <p>
              {" "}
              <b> {t("Conectando-BD")}</b>{" "}
            </p>
          )}
        </div>
        <div className="centerPasosPage2" style={{ marginBottom: "2%" }}>
          <Card
            content={
              <div style={{ color: "black" }}>
                <h3
                  style={{ textAlign: "center", marginTop: "0.5%" }}
                  className="titulocardsPasosPage"
                >
                  {textocuadro}
                </h3>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "1%",
                  }}
                >
                  <div style={{ flex: 1, textAlign: "left", marginLeft: "3%" }}>
                    <b> {t("Distancia")}</b>
                    <br />
                    {distancia && chartData ? (
                      <div>
                        {distanciaMostrada() !== null
                          ? (distanciaMostrada() / 1000).toLocaleString(
                              "es-ES",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )
                          : ""}{" "}
                        km{" "}
                      </div>
                    ) : (
                      <div>{t("Conectando-BD")}</div>
                    )}
                  </div>
                  <div
                    style={{
                      flex: 1,
                      textAlign: "right",
                      marginRight: "3%",
                    }}
                  >
                    <b>{t("Tiempo-activo")}</b>
                    <br />
                    {tiempo && chartData ? (
                      <div>{convertirMinutosAHoras(tiempoMostrado( chartData.datasets[0].data))} </div>
                    ) : (
                      <div>{t("Conectando-BD")}</div>
                    )}
                  </div>
                </div>
                <div style={{ textAlign: "center", marginTop: "0%" }}>
                  <b>{t("Pasos")}</b>
                  <br />
                  {chartData ? (
                    <div>
                      {formatearNumeroConPuntoDeMiles(
                        pasosMostrado(
                          chartData.datasets[0].data
                        ).toLocaleString("es-ES", {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })
                      )}{" "}
                      {console.log(chartData.datasets[0].data)}
                    </div>
                  ) : (
                    <div>{t("Conectando-BD")}</div>
                  )}
                </div>
              </div>
            }
            className={"cardInfoPasosPage"}
          ></Card>
        </div>
      </div>
    </div>
  );
};
export default Pasos;
