import { useParams } from "react-router-dom";
import { useEffect, useContext, useState } from "react";
import UserContext from "../../UserContext";
import { useTranslation } from "react-i18next";
import axios from "axios";
import "./CuestionarioIntento.css";
import { useNavigate } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";
import Card from "../../components/Card";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import TextoConCard from "../../components/Cuestionarios/TextoConCard";
import TituloyFlecha from "../../components/TituloyFlecha";
const CuestionarioIntento = () => {
  // Obtén el parámetro de la URL llamado 'cuestionarioId'
  const { cuestionarioId } = useParams();
  const email = useContext(UserContext);
  const [t] = useTranslation("global");
  const [semanalrespondido, setSemanalrespondido] = useState(false);
  const [intentos, setIntentos] = useState([]);
  const location = useLocation();

  useEffect(() => {
    const fetchIntentos = async () => {
      try {
        const responseIntentos = await axios.get(
          t("Recursos.ip") + "/respuesta/numerointentos",
          {
            params: {
              email: email.email,
              numerocuestionario: cuestionarioId,
            },
          }
        );
        console.log("Respuesta", responseIntentos.data)
        const numeroDeIntentos = responseIntentos.data;
        const intentosArray = Array.from(
          { length: numeroDeIntentos },
          (_, index) => index + 1
        );

        const responseFechas = await axios.get(
          t("Recursos.ip") + "/respuesta/fechas",
          {
            params: {
              email: email.email,
              numerocuestionario: cuestionarioId,
            },
          }
        );
        console.log("Respuesta", responseIntentos.data, responseFechas.data)
        setIntentos(responseFechas.data);
      } catch (error) {
        console.error("Error:", error);
      }
    };
    function obtenerLunesDeEstaSemana() {
      const hoy = new Date();
      const diaSemana = hoy.getDay();
      const diferenciaDias = diaSemana - 1; // Restamos uno para obtener la diferencia de días entre hoy y el lunes

      // Calculamos la fecha del lunes restando la diferencia de días a la fecha actual
      const fechaLunes = new Date(hoy);
      fechaLunes.setDate(hoy.getDate() - diferenciaDias);

      // Formateamos la fecha en el formato YYYY-MM-DD
      const fechaLunesFormateada = fechaLunes.toISOString().slice(0, 10);

      return fechaLunesFormateada;
    }

    const respuestasemanal = async () => {
      try {
        const response = await axios.get(
          t("Recursos.ip") + "/respuesta/semanalfecha",
          {
            params: {
              email: email.email,
              numerocuestionario: cuestionarioId,
            },
          }
        );
        console.log(response.data,obtenerLunesDeEstaSemana());
        setSemanalrespondido(response.data >=obtenerLunesDeEstaSemana());
      } catch (error) {
        console.error("Error:", error);
      }
    };
    if (email.email) {
      fetchIntentos();
      if (cuestionarioId === "9") {
        respuestasemanal();
      }
    }
  }, [email.email, cuestionarioId]);

  const navigate = useNavigate();

  const revisarCuestionario = (id) => {
    navigate(`/Cuestionario/${cuestionarioId}/revisar/${id}`);
  };

  const CardTitle = () => {
    // Utiliza el valor de cuestionarioId para obtener la traducción correcta
    const titulo = t(`Cuestionario${cuestionarioId}.Titulo`);

    return <h1>&nbsp;{titulo}&nbsp;</h1>;
  };

  const rutaCuestionario = cuestionarioId === "9" ? "/" : "/ListaCuestionario";
  const formatearFecha = (fecha) => {
    const [year, month, day] = fecha.split('-');
    return `${day}-${month}-${year}`;
  };
  return (
    <div>
      <TituloyFlecha
        title={t(`Cuestionario${cuestionarioId}.Titulo`)}
        link={rutaCuestionario}
      />

      <div
        style={{
          width: "80%",
          margin: "0 auto",
          marginTop: "2%",
          maxWidth: "80%",
        }}
      >
        <TextoConCard cuestionarioId={cuestionarioId} />
      </div>

      <p className="tituloTabla">{t(`Revision.Intro`)}</p>

      <table className="tableIntento">
        <thead>
          <tr>
            <th>{t("Intento")}</th>
            <th>{t("FechaIntento")}</th>
            {/* <th>{t("Interpretación")}</th> */}
            <th>{t("Revisión")}</th>
          </tr>
        </thead>
        <tbody>
          {intentos.map((fecha, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{formatearFecha(fecha)}</td>
              {/* <td>{t("Valor-interpretación")}</td> */}
              <td>
                <button
                  onClick={() => revisarCuestionario(index + 1)}
                  className="botoncirc"
                  style={{ width: window.innerWidth <= 768 ? "100%" : "40%" }}
                >
                  Revisar
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <br></br>
      {cuestionarioId === "9" ? (
        <>
          <p>{t("Revision.Recordatorio-semanal")}</p>
          {semanalrespondido === true ? (
            <>
              <button
                className="intento-button"
                style={{
                  maxWidth: window.innerWidth <= 768 ? "30%" : "10%",
                  backgroundColor: "#A5BBC1",
                }}
              >
                {t("Revision.Ya-respondido")}
              </button>
            </>
          ) : (
            <>
              <Link
                to={`/Cuestionario${cuestionarioId}`}
                style={{ width: window.innerWidth <= 768 ? "30%" : "10%" }}
              >
                <button
                  className="intento-button"
                  style={{ maxWidth: window.innerWidth <= 768 ? "30%" : "10%" }}
                >
                  {t("Revision.NuevoIntento")}
                </button>
              </Link>
            </>
          )}
        </>
      ) : cuestionarioId === "8" ? (
        <>
          <Link
            to={`/Cuestionario${cuestionarioId}/pregunta`}
            style={{ width: window.innerWidth <= 768 ? "30%" : "10%" }}
          >
            <button
              className="link-button"
              style={{ maxWidth: window.innerWidth <= 768 ? "30%" : "10%" }}
            >
              {t("Revision.NuevoIntento")}
            </button>
          </Link>
        </>
      ) : (
        <>
          <Link
            to={`/Cuestionario${cuestionarioId}`}
            style={{ width: window.innerWidth <= 768 ? "30%" : "10%" }}
          >
            <button
              className="link-button"
              style={{ maxWidth: window.innerWidth <= 768 ? "30%" : "10%" }}
            >
              {t("Revision.NuevoIntento")}
            </button>
          </Link>
        </>
      )}
    </div>
  );
};

export default CuestionarioIntento;
