import React from "react";
import "./Ayuda.css";
import { useTranslation } from "react-i18next";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import Card from "../../components/Card";
import UserContext from "../../UserContext";
import { useContext } from "react";
import TituloyFlecha from "../../components/TituloyFlecha";


const PoliticaPrivacidad = () => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();

  const retroceder = () => {
    navigate(-1);
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="containerAyuda">

        <TituloyFlecha
          title={t("PoliticaPrivacidad.titulo")}
          link={"atras"}
        />
        <div className="contenidoAyuda">
          <p>{t("PoliticaPrivacidad.1.texto1")}</p>
          <p>{t("PoliticaPrivacidad.1.texto2")}</p>
          <p>{t("PoliticaPrivacidad.1.texto3")}</p>
          <p>{t("PoliticaPrivacidad.1.texto4")}</p>
          <p>{t("PoliticaPrivacidad.1.texto6")}</p>
          <p>{t("PoliticaPrivacidad.1.texto7")}</p>
          <h2 className="subtituloAyuda">{t("PoliticaPrivacidad.2.titulo")}</h2>
          <p>{t("PoliticaPrivacidad.2.texto1")}</p>
          <ul>
            <li>{t("PoliticaPrivacidad.2.texto2")}</li>
            <li>{t("PoliticaPrivacidad.2.texto3")}</li>
            <li>{t("PoliticaPrivacidad.2.texto4")}</li>
            <li>{t("PoliticaPrivacidad.2.texto5")}</li>
          </ul>
          <h2 className="subtituloAyuda">{t("PoliticaPrivacidad.3.titulo")}</h2>
          <p>{t("PoliticaPrivacidad.3.texto1")}</p>
          <h2 className="subtituloAyuda">{t("PoliticaPrivacidad.4.titulo")}</h2>
          <ul>
            <li>{t("PoliticaPrivacidad.4.texto1")}</li>
            <li>{t("PoliticaPrivacidad.4.texto2")}</li>
            <li>{t("PoliticaPrivacidad.4.texto3")}</li>
            <li>{t("PoliticaPrivacidad.4.texto4")}</li>
            <li>{t("PoliticaPrivacidad.4.texto5")}</li>
          </ul>
          <p>{t("PoliticaPrivacidad.4.texto6")}</p>
          <p>{t("PoliticaPrivacidad.4.texto7")}</p>
          <h2 className="subtituloAyuda">{t("PoliticaPrivacidad.5.titulo")}</h2>
          <p>{t("PoliticaPrivacidad.5.texto1")}</p>
          <h2 className="subtituloAyuda">{t("PoliticaPrivacidad.6.titulo")}</h2>
          <p>{t("PoliticaPrivacidad.6.texto1")}</p>
          <h2 className="subtituloAyuda">{t("PoliticaPrivacidad.7.titulo")}</h2>
          <p>{t("PoliticaPrivacidad.7.texto1")}</p>
          <p>{t("PoliticaPrivacidad.7.texto2")}</p>
          <p>{t("PoliticaPrivacidad.7.texto3")}</p>
          <h2 className="subtituloAyuda">{t("PoliticaPrivacidad.8.titulo")}</h2>
          <p>{t("PoliticaPrivacidad.8.texto1")}</p>
          <h2 className="subtituloAyuda">{t("PoliticaPrivacidad.8.titulo")}</h2>
          <p>{t("PoliticaPrivacidad.9.texto1")}</p>
          <br></br>
        </div>
      </div>
    </div>
  );
};

export default PoliticaPrivacidad;
