import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import global_es from "./translations/es/global.json"
import global_en from "./translations/en/global.json"
import global_gal from "./translations/gal/global.json"

const root = document.getElementById("root");

i18next.init({
  interpolation: { escapeValue: false },
  lng:"es",
  resources:{
    es:{
      global:global_es
    },
    en:{
      global:global_en
    },
    gal:{
      global:global_gal
    }
  }
});
ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </React.StrictMode>,
  root
);

// Si deseas eliminar el bloque anterior, puedes hacerlo, ya que estás renderizando App dos veces.
// ReactDOM.render(<App />, root);

// Si deseas medir el rendimiento de tu aplicación, puedes mantener esta línea.
reportWebVitals();
