import { useContext, useState,useEffect } from "react";
import UserContext from '../../UserContext';

import PreguntaRespuesta from "../../components/Cuestionarios/PreguntaRespuesta";
import axios from 'axios';
import { useNavigate } from "react-router-dom";


const Cuestionario0 = () => {
  const navigate = useNavigate();


  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [navigate]);

  const email = useContext(UserContext);


  //Cuestionario sociodemográfico
  //Preguntar por respuestas predefinidas
  const preguntas = [
    "¿Qué edad tiene usted?",
    "¿Cuál es su sexo?",
    "¿Cuál es su estado civil?",
    "¿Cuál es su entorno residencial?",
    "¿Cómo es su unidad de convivencia?",
    "¿Cuál es su nivel educativo?",
    "¿Cómo describiría su nivel socioeconómico?",
    "¿Tiene apoyo como cuidador?",
    "Si es así, ¿qué tipo de apoyo tiene?",
  ];
  const numero_cuestionario = 0;

  // Utilizar un objeto para almacenar las respuestas
  const [respuestas, setRespuestas] = useState({});

  // Función para manejar las respuestas de cada instancia de PreguntaRespuesta
  const handleRespuestaSubmit = (pregunta, respuesta) => {
    // Actualizar el objeto de respuestas con la respuesta correspondiente
    setRespuestas((prevRespuestas) => ({
      ...prevRespuestas,
      [pregunta]: respuesta,
    }));
  }




  // Función para mostrar todas las respuestas en la consola
  const mostrarRespuestasEnConsola = async () => {
    const miArray = new Array(preguntas.length);

    // Convertir el objeto de respuestas en un array de respuestas ordenadas
    const miArrayRespuestas = preguntas.map((pregunta) => ({
      clave: pregunta,
      valor: respuestas[pregunta] || "", // Usar un valor predeterminado si no existe respuesta
    }));

    // Iterar a través de las preguntas y asignar las respuestas correspondientes
    for (let i = 0, j = 0; i < preguntas.length; i++) {
      if (preguntas[i] === miArrayRespuestas[j].clave) {
        miArray[i] = miArrayRespuestas[j].valor;
        j++;
      } else {
        miArray[i] = "";
      }
    }
    console.log(miArrayRespuestas);
    const resultado = '"' + miArray.join('","') + '"';
    console.log(resultado);
    try {
      const response = await axios.post("http://193.144.49.45:8080/api/respuesta", {
        numeroCuestionario: numero_cuestionario,
        arrayCuestionario: resultado,
        email: email.email,
      });

      console.log(response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  }




  return (
    <div>
      <h1>Cuestionario 0</h1>
      {preguntas.map((pregunta, index) => (
        <PreguntaRespuesta
          key={index}
          pregunta={pregunta}
          onRespuestaSubmit={handleRespuestaSubmit}
        />
      ))}
      <br></br>
      <button onClick={mostrarRespuestasEnConsola}>Mostrar respuestas en consola</button>
    </div>
  );
}


export default Cuestionario0;
