import { useEffect, useContext, useState } from "react";
import UserContext from "../../UserContext";
import { useNavigate } from "react-router-dom";
import CheckboxesCuestionarioSD from "../../components/Cuestionarios/CheckboxesCuestionarioSD";
import { useTranslation } from "react-i18next";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import Card from "../../components/Card";
import axios from "axios";
import TituloyFlecha from "../../components/TituloyFlecha";
const Cuestionario8 = () => {
  const bearer = useContext(UserContext);
  const navigate = useNavigate();
  const email = useContext(UserContext);
  const { setEmail } = useContext(UserContext);
  const { setBearer } = useContext(UserContext);
  const [t] = useTranslation("global");
  const numero_cuestionario = 8;
  const [pagina, sePagina] = useState(0);
  const [todoRespondido, SetTodoRespondido] = useState(true);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [navigate]);
  
  const preguntas = [
    t("Cuestionario8.Preguntas.1"),
    t("Cuestionario8.Preguntas.2"),
    t("Cuestionario8.Preguntas.3"),
    t("Cuestionario8.Preguntas.4"),
    t("Cuestionario8.Preguntas.5"),
    t("Cuestionario8.Preguntas.6"),
    t("Cuestionario8.Preguntas.7"),
    t("Cuestionario8.Preguntas.8"),
    t("Cuestionario8.Preguntas.9"),
    t("Cuestionario8.Preguntas.10"),
    t("Cuestionario8.Preguntas.11"),
    t("Cuestionario8.Preguntas.12"),
    t("Cuestionario8.Preguntas.13"),
    t("Cuestionario8.Preguntas.14"),
    t("Cuestionario8.Preguntas.15"),
    t("Cuestionario8.Preguntas.16"),
  ];
  // Utilizar un objeto para almacenar las respuestas
  const [respuestas, setRespuestas] = useState({});

  // Función para manejar las respuestas de cada instancia de PreguntaRespuesta
  // Función para manejar las respuestas de cada instancia de PreguntaRespuesta
  const handleRespuestaSubmit = (pregunta, respuesta) => {
    // Actualizar el objeto de respuestas con la respuesta correspondiente
    setRespuestas((prevRespuestas) => ({
      ...prevRespuestas,
      [pregunta]: respuesta,
    }));
  };

  // Función para mostrar todas las respuestas en la consola
  const mostrarRespuestasEnConsola = async () => {
    const respuestasEnTexto = preguntas
      .map((pregunta) => `"${respuestas[pregunta] || " "}"`)
      .join(",");
    const puntuacion =
      '"' +
      traducirArray(
        preguntas.map((pregunta) => `${respuestas[pregunta] || " "}`)
      ).join('","') +
      '"';
    console.log("Respuestas en texto:", respuestasEnTexto, puntuacion);
    const hayDosComillasSinValor = /""|\s*" "\s*|\[object Object\]/.test(
      respuestasEnTexto
    );

    if (hayDosComillasSinValor) {
      SetTodoRespondido(false);
    } else {
      SetTodoRespondido(true);
      try {
        const response = await axios.post(t("Recursos.ip") + "/respuesta", {
          numeroCuestionario: numero_cuestionario,
          arrayCuestionario: respuestasEnTexto,
          arrayPuntuacionCuestionario: puntuacion,
          email: email.email,
        });

        console.log(response.data);
        navigate("/Cuestionario/8/intentos");
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const traducirArray = (array) => {
    return array.map((elemento) => traducirAValor(elemento));
  };
  const traducirAValor = (cadena) => {
    switch (cadena) {
      case t("Cuestionario8.Respuestas.1"):
        return 0;
      case t("Cuestionario8.Respuestas.2"):
        return 1;
      case t("Cuestionario8.Respuestas.3"):
        return 2;
      case t("Cuestionario8.Respuestas.4"):
        return 3;
      case t("Cuestionario8.Respuestas.5"):
        return 4;
      case t("Cuestionario8.Respuestas.6"):
        return 5;

      default:
        return cadena; // Valor por defecto o manejo de error
    }
  };

  function paginar(valor) {
    SetTodoRespondido(true);
    if (valor > 0 && pagina < 3) {
      sePagina(pagina + valor);
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else if (valor < 0 && pagina > 0) {
      sePagina(pagina + valor);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }
  return (
    <div>
      <TituloyFlecha
        title={t("Cuestionario8.Titulo")}
        link={"/Cuestionario/8/intentos"}
      />

      <p>{t("Cuestionario8.Explicacion.TextoIntro2")}</p>

      <br></br>
      <div
        style={{
          marginTop: "1%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        className="cuestionarioContainer"
      >
        <div style={{ display: pagina === 0 ? "block" : "none" }}>
          <h2>{t("Cuestionario8.Bloques.1")}</h2>
          <br></br>
          <CheckboxesCuestionarioSD
            preguntas={[preguntas[0]]}
            respuestasComunes={[
              t("Cuestionario8.Respuestas.1"),
              t("Cuestionario8.Respuestas.2"),
              t("Cuestionario8.Respuestas.3"),
              t("Cuestionario8.Respuestas.4"),
              t("Cuestionario8.Respuestas.5"),
              t("Cuestionario8.Respuestas.6"),
            ]}
            onRespuestaSubmit={handleRespuestaSubmit}
          />
          <CheckboxesCuestionarioSD
            preguntas={[preguntas[1]]}
            respuestasComunes={[
              t("Cuestionario8.Respuestas.1"),
              t("Cuestionario8.Respuestas.2"),
              t("Cuestionario8.Respuestas.3"),
              t("Cuestionario8.Respuestas.4"),
              t("Cuestionario8.Respuestas.5"),
              t("Cuestionario8.Respuestas.6"),
            ]}
            onRespuestaSubmit={handleRespuestaSubmit}
          />
          <CheckboxesCuestionarioSD
            preguntas={[preguntas[2]]}
            respuestasComunes={[
              t("Cuestionario8.Respuestas.1"),
              t("Cuestionario8.Respuestas.2"),
              t("Cuestionario8.Respuestas.3"),
              t("Cuestionario8.Respuestas.4"),
              t("Cuestionario8.Respuestas.5"),
              t("Cuestionario8.Respuestas.6"),
            ]}
            onRespuestaSubmit={handleRespuestaSubmit}
          />
          <CheckboxesCuestionarioSD
            preguntas={[preguntas[3]]}
            respuestasComunes={[
              t("Cuestionario8.Respuestas.1"),
              t("Cuestionario8.Respuestas.2"),
              t("Cuestionario8.Respuestas.3"),
              t("Cuestionario8.Respuestas.4"),
              t("Cuestionario8.Respuestas.5"),
              t("Cuestionario8.Respuestas.6"),
            ]}
            onRespuestaSubmit={handleRespuestaSubmit}
          />
        </div>
        <div style={{ display: pagina === 1 ? "block" : "none" }}>
          <h2>{t("Cuestionario8.Bloques.2")}</h2>
          <br></br>
          <CheckboxesCuestionarioSD
            preguntas={[preguntas[4]]}
            respuestasComunes={[
              t("Cuestionario8.Respuestas.1"),
              t("Cuestionario8.Respuestas.2"),
              t("Cuestionario8.Respuestas.3"),
              t("Cuestionario8.Respuestas.4"),
              t("Cuestionario8.Respuestas.5"),
              t("Cuestionario8.Respuestas.6"),
            ]}
            onRespuestaSubmit={handleRespuestaSubmit}
          />
          <CheckboxesCuestionarioSD
            preguntas={[preguntas[5]]}
            respuestasComunes={[
              t("Cuestionario8.Respuestas.1"),
              t("Cuestionario8.Respuestas.2"),
              t("Cuestionario8.Respuestas.3"),
              t("Cuestionario8.Respuestas.4"),
              t("Cuestionario8.Respuestas.5"),
              t("Cuestionario8.Respuestas.6"),
            ]}
            onRespuestaSubmit={handleRespuestaSubmit}
          />
          <CheckboxesCuestionarioSD
            preguntas={[preguntas[6]]}
            respuestasComunes={[
              t("Cuestionario8.Respuestas.1"),
              t("Cuestionario8.Respuestas.2"),
              t("Cuestionario8.Respuestas.3"),
              t("Cuestionario8.Respuestas.4"),
              t("Cuestionario8.Respuestas.5"),
              t("Cuestionario8.Respuestas.6"),
            ]}
            onRespuestaSubmit={handleRespuestaSubmit}
          />
          <CheckboxesCuestionarioSD
            preguntas={[preguntas[7]]}
            respuestasComunes={[
              t("Cuestionario8.Respuestas.1"),
              t("Cuestionario8.Respuestas.2"),
              t("Cuestionario8.Respuestas.3"),
              t("Cuestionario8.Respuestas.4"),
              t("Cuestionario8.Respuestas.5"),
              t("Cuestionario8.Respuestas.6"),
            ]}
            onRespuestaSubmit={handleRespuestaSubmit}
          />
        </div>
        <div style={{ display: pagina === 2 ? "block" : "none" }}>
          <h2>{t("Cuestionario8.Bloques.3")}</h2>
          <br></br>
          <CheckboxesCuestionarioSD
            preguntas={[preguntas[8]]}
            respuestasComunes={[
              t("Cuestionario8.Respuestas.1"),
              t("Cuestionario8.Respuestas.2"),
              t("Cuestionario8.Respuestas.3"),
              t("Cuestionario8.Respuestas.4"),
              t("Cuestionario8.Respuestas.5"),
              t("Cuestionario8.Respuestas.6"),
            ]}
            onRespuestaSubmit={handleRespuestaSubmit}
          />
          <CheckboxesCuestionarioSD
            preguntas={[preguntas[9]]}
            respuestasComunes={[
              t("Cuestionario8.Respuestas.1"),
              t("Cuestionario8.Respuestas.2"),
              t("Cuestionario8.Respuestas.3"),
              t("Cuestionario8.Respuestas.4"),
              t("Cuestionario8.Respuestas.5"),
              t("Cuestionario8.Respuestas.6"),
            ]}
            onRespuestaSubmit={handleRespuestaSubmit}
          />
          <CheckboxesCuestionarioSD
            preguntas={[preguntas[10]]}
            respuestasComunes={[
              t("Cuestionario8.Respuestas.1"),
              t("Cuestionario8.Respuestas.2"),
              t("Cuestionario8.Respuestas.3"),
              t("Cuestionario8.Respuestas.4"),
              t("Cuestionario8.Respuestas.5"),
              t("Cuestionario8.Respuestas.6"),
            ]}
            onRespuestaSubmit={handleRespuestaSubmit}
          />
          <CheckboxesCuestionarioSD
            preguntas={[preguntas[11]]}
            respuestasComunes={[
              t("Cuestionario8.Respuestas.1"),
              t("Cuestionario8.Respuestas.2"),
              t("Cuestionario8.Respuestas.3"),
              t("Cuestionario8.Respuestas.4"),
              t("Cuestionario8.Respuestas.5"),
              t("Cuestionario8.Respuestas.6"),
            ]}
            onRespuestaSubmit={handleRespuestaSubmit}
          />
        </div>
        <div style={{ display: pagina === 3 ? "block" : "none" }}>
          <h2>{t("Cuestionario8.Bloques.4")}</h2>
          <br></br>
          <CheckboxesCuestionarioSD
            preguntas={[preguntas[12]]}
            respuestasComunes={[
              t("Cuestionario8.Respuestas.1"),
              t("Cuestionario8.Respuestas.2"),
              t("Cuestionario8.Respuestas.3"),
              t("Cuestionario8.Respuestas.4"),
              t("Cuestionario8.Respuestas.5"),
              t("Cuestionario8.Respuestas.6"),
            ]}
            onRespuestaSubmit={handleRespuestaSubmit}
          />
          <CheckboxesCuestionarioSD
            preguntas={[preguntas[13]]}
            respuestasComunes={[
              t("Cuestionario8.Respuestas.1"),
              t("Cuestionario8.Respuestas.2"),
              t("Cuestionario8.Respuestas.3"),
              t("Cuestionario8.Respuestas.4"),
              t("Cuestionario8.Respuestas.5"),
              t("Cuestionario8.Respuestas.6"),
            ]}
            onRespuestaSubmit={handleRespuestaSubmit}
          />
          <CheckboxesCuestionarioSD
            preguntas={[preguntas[14]]}
            respuestasComunes={[
              t("Cuestionario8.Respuestas.1"),
              t("Cuestionario8.Respuestas.2"),
              t("Cuestionario8.Respuestas.3"),
              t("Cuestionario8.Respuestas.4"),
              t("Cuestionario8.Respuestas.5"),
              t("Cuestionario8.Respuestas.6"),
            ]}
            onRespuestaSubmit={handleRespuestaSubmit}
          />
          <CheckboxesCuestionarioSD
            preguntas={[preguntas[15]]}
            respuestasComunes={[
              t("Cuestionario8.Respuestas.1"),
              t("Cuestionario8.Respuestas.2"),
              t("Cuestionario8.Respuestas.3"),
              t("Cuestionario8.Respuestas.4"),
              t("Cuestionario8.Respuestas.5"),
              t("Cuestionario8.Respuestas.6"),
            ]}
            onRespuestaSubmit={handleRespuestaSubmit}
          />
        </div>
        <br></br>
        {!todoRespondido && (
          <div>
            <p style={{ color: "red" }}>{t("Responde-todo")}</p>
          </div>
        )}
        <div
          style={{
            width: "100%",
            marginBottom: "-5%",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <button
            onClick={() => paginar(-1)}
            style={{
              marginTop: "30px",
              width: window.innerWidth <= 768 ? "50%" : "20%",
              background: pagina === 0 ? " #A5BBC1" : "",
              // visibility: pagina === 0 ? "hidden" : "visible",
            }}
            className="link-button"
          >
            {t("Atras")}
          </button>

          {pagina < 3 && (
            <button
              onClick={() => paginar(1)}
              style={{
                marginTop: "30px",
                width: window.innerWidth <= 768 ? "50%" : "20%",
                visibility: pagina === 3 ? "hidden" : "visible",
                marginLeft: "5%",
              }}
              className="link-button"
            >
              {t("Continuar")}
            </button>
          )}
          {pagina === 3 && (
            <button
              onClick={mostrarRespuestasEnConsola}
              className="link-button"
              style={{
                width: window.innerWidth <= 768 ? "50%" : "20%",
                marginLeft: "5%",
                marginTop: "30px",
              }}
            >
              {t("Confirmar")}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Cuestionario8;
