
import "./App.css";
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import Pasos from './pages/MisDatosSalud/PasosPage';
import SuenoPage from './pages/MisDatosSalud/SuenoPage';
import SuenoPageDuracion from './pages/MisDatosSalud/SuenoPageDuracion';
import PulsacionesPage from './pages/MisDatosSalud/PulsacionesPage';
import PulsacionesPageMaxMin from './pages/MisDatosSalud/PulsacionesPageMaxMin';
import UserContext from './UserContext';
import bootstrap from 'bootstrap/dist/css/bootstrap.css'
import NavBarSuperior from "./layouts/navbar";
import ListaCuestionario from "./pages/NavBar/ListaCuestionario";
import Cuestionario0 from './pages/Cuestionarios/Cuestionario0';
import Cuestionario1 from './pages/Cuestionarios/Cuestionario1';
import Cuestionario2 from './pages/Cuestionarios/Cuestionario2';
import Cuestionario3 from './pages/Cuestionarios/Cuestionario3';
import Cuestionario4 from './pages/Cuestionarios/Cuestionario4';
import Cuestionario5 from './pages/Cuestionarios/Cuestionario5';
import Cuestionario6 from './pages/Cuestionarios/Cuestionario6';
import Cuestionario8 from './pages/Cuestionarios/Cuestionario8';
import Cuestionario8Pregunta from './pages/Cuestionarios/Cuestionario8Pregunta';
import Cuestionario9 from './pages/Cuestionarios/Cuestionario9Semanal';
import Cuestionario10 from './pages/Cuestionarios/Cuestionario10';
import CuestionarioSocioDemo from "./pages/Cuestionarios/CuestionarioSocioDemo"
import CuestionarioSocioDemo1 from "./pages/Cuestionarios/CuestionarioSocioDemo1"
import MiPulsera from "./pages/MisDatosSalud/MiPulsera";
import GlobalStyles from './GlobalStyles';
import FormularioRegistro from "./pages/Registro/FormularioRegistro";
import Miperfil from "./pages/NavBar/Miperfil";
import PreguntaAnadirInfoPersonal from "./pages/Registro/PreguntaAnadirInfoPersonal";
import CuestionarioEstadoAnimo from "./pages/Cuestionarios/CuestionarioEstadoAnimo";
import PreguntaCerrarSesion from "./pages/NavBar/PreguntaCerrarSesion";
import Ayuda from "./pages/NavBar/Ayuda";
import MisAvisos from "./pages/NavBar/MisAvisos";
import QuienesSomos from "./pages/NavBar/QuienesSomos";
import PdfViewer from "./pages/Temas-Contenidos/PdfViewer";
import VideoViewer from "./pages/Temas-Contenidos/VideoViewer";
import PreguntasFrecuentes from "./pages/NavBar/PreguntasFrecuentes";
import Publicidad from "./layouts/Publicidad";
import AuthChecker from "./layouts/AuthChecker";
import CuestionarioIntento from "./pages/Cuestionarios/CuestionarioIntento";
import CuestionarioRevisar from "./pages/Cuestionarios/CuestionarioRevisar";
import PoliticaPrivacidad from "./pages/NavBar/PoliticaPrivacidad";
import Tecuido from "./pages/Temas-Contenidos/Tecuido";
import Mecuido from "./pages/Temas-Contenidos/Mecuido";
import Descarga from "./pages/Descarga";
import AyudaSincronizacion from "./pages/NavBar/AyudaSincronizacion";
import PantalladeCarga from "./pages/PantalladeCarga";
import PaginacionTemas from "./components/PaginacionTemas";
import EstadodeAnimo from "./pages/EstadodeAnimo";
import LoginAdmin from './pages/Administrador/LoginAdmin';
import UsuariosAdmin from './pages/Administrador/usuariosAdmin';
import PrivateRoute from './pages/Administrador/PrivateRoute';
import IntentosAdmin from './pages/Administrador/IntentosAdmin';
import RevisarAdmin from './pages/Administrador/RevisarAdmin';
import InicioAdmin from "./pages/Administrador/InicioAdmin";
import CuestionariosAdmin from "./pages/Administrador/CuestionariosAdmin";


function App() {
  const [authenticatedAdmin, setAuthenticatedAdmin] = useState(false);
  const [bearer, setBearer] = useState(null);
  const [email, setEmail] = useState(null);
  const [mostrar, setMostrar] = useState(false);
  const [nombre, setNombre] = useState('Nombre');
  const [ventanaAnterior, setVentanaAnterior] = useState(null);

  useEffect(() => {
    const adminAuthenticated = localStorage.getItem('adminAuthenticated');
    if (adminAuthenticated === 'true') {
      setAuthenticatedAdmin(true);
    }
  }, []);

  const handleLogin = () => {
    setAuthenticatedAdmin(true);
    localStorage.setItem('adminAuthenticated', 'true');
  };

  return (
    <div className="App">
      <GlobalStyles />
      <BrowserRouter>

        <UserContext.Provider value={{ bearer, setBearer, email, setEmail, nombre, setNombre, mostrar, setMostrar, ventanaAnterior, setVentanaAnterior }}>
          {mostrar && <NavBarSuperior />} {/* La NavBar solo se muestra si isLoggedIn es verdadero */}
          <AuthChecker bearer={bearer} setBearer={setBearer} setEmail={setEmail} />
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/MiSalud" element={<MiPulsera />} />
            <Route path="/MiActividadFisica/:contenido" element={<Pasos />} />
            <Route path="/RutinaSuenoDiaria" element={<SuenoPage />} />
            <Route path="/RutinaSueno/:contenido" element={<SuenoPageDuracion />} />
            <Route path="/FrecuenciaCardiacaDiaria" element={<PulsacionesPage />} />
            <Route path="/FrecuenciaCardiaca/:contenido" element={<PulsacionesPageMaxMin />} />
            <Route path="/ListaCuestionario" element={<ListaCuestionario />} />
            <Route path="/Cuestionario/:cuestionarioId/intentos" element={<CuestionarioIntento />} />
            <Route path="/Cuestionario0" element={<Cuestionario0 />} />
            <Route path="/Cuestionario1" element={<Cuestionario1 />} />
            <Route path="/Cuestionario2" element={<Cuestionario2 />} />
            <Route path="/Cuestionario3" element={<Cuestionario3 />} />
            <Route path="/Cuestionario4" element={<Cuestionario4 />} />
            <Route path="/Cuestionario5" element={<Cuestionario5 />} />
            <Route path="/Cuestionario6" element={<Cuestionario6 />} />
            <Route path="/Cuestionario8" element={<Cuestionario8 />} />
            <Route path="/Cuestionario8/pregunta" element={<Cuestionario8Pregunta />} />
            <Route path="/Cuestionario9" element={<Cuestionario9 />} />
            <Route path="/Cuestionario10" element={<Cuestionario10 />} />
            <Route path="/FormularioRegistro" element={<FormularioRegistro />} />
            <Route path="/Miperfil" element={<Miperfil />} />
            <Route path="/CuestionarioSocioDemo" element={<CuestionarioSocioDemo />} />
            <Route path="/CuestionarioSocioDemo1" element={<CuestionarioSocioDemo1 />} />
            <Route path="/anadirinfopersonal" element={<PreguntaAnadirInfoPersonal />} />
            <Route path="/CuestionarioEstadoAnimo" element={<CuestionarioEstadoAnimo />} />
            <Route path="/cerrarsesion" element={<PreguntaCerrarSesion />} />
            <Route path="/Ayuda" element={<Ayuda />} />
            <Route path="/PreguntasFrecuentes" element={<PreguntasFrecuentes />} />
            <Route path="/MisAvisos" element={<MisAvisos />} />
            <Route path="/pdf/:pdfFileName" element={<PdfViewer />} />
            <Route path="/video/:VideoFileName" element={<VideoViewer />} />
            <Route path="/Cuestionario/:cuestionarioId/revisar/:intento" element={<CuestionarioRevisar />} />
            <Route path="/politica-privacidad" element={<PoliticaPrivacidad />} />
            <Route path="/Tecuido/:temaSeleccionado" element={<Tecuido />} />
            <Route path="/Mecuido/:temaSeleccionado" element={<Mecuido />} />
            <Route path="/Descarga" element={<Descarga />} />
            <Route path="/AyudaSincronizacion" element={<AyudaSincronizacion />} />
            <Route path="/temas/:temaSeleccionado/:pagina?" component={PaginacionTemas} />
            <Route path="/EstadodeAnimo/:contenido" element={<EstadodeAnimo />} />
            <Route path="/pantalladeCarga" element={<PantalladeCarga />} />
            <Route path="/LoginAdmin" element={<LoginAdmin />} />
            <Route path="/admin" element={<PrivateRoute><InicioAdmin /></PrivateRoute> }/>
            <Route path="/CuestionarioAdmin" element={<PrivateRoute><CuestionariosAdmin /></PrivateRoute> }/>
            <Route
              path="/usuariosAdmin"
              element={
                <PrivateRoute>
                  <UsuariosAdmin />
                </PrivateRoute>
              }
            />
            <Route path="/IntentosAdmin/:id" element={<PrivateRoute><IntentosAdmin /></PrivateRoute>}/> {/* Ruta para mostrar detalles del cuidador */}
            <Route path="/RevisarAdmin/:id/:idCuestionario/:idIntento" element={<PrivateRoute><RevisarAdmin/></PrivateRoute>} />
            <Route path="/QuienesSomos" element={<QuienesSomos />} />
          </Routes>
          <Publicidad />
        </UserContext.Provider>
      </BrowserRouter>
    </div>
  );
}

export default App;
