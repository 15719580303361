import React from 'react';
import Autocuidado_Icono from "../../Images/Autocuidado_Icono.png";
import Card from '../Card';
import './MiAutocuidadoCarta.css';
import { useTranslation } from "react-i18next";
function MiAutocuidadoCarta() {
  const [t] = useTranslation("global");
  return (
    <div style={{ fontSize: "2vh" }}>
      <div style={{ width: "30vh", margin: "0 auto", marginTop: "-2.5vh" }}>
        <Card content={<h1 style={{
          fontSize: "3vh",
          marginTop: "0.5vh",
          color: "white"
        }}>
          {t("Mecuido")}</h1>}
          alto="50%"
          ancho="100%" className={"cardMiAutocuidadoCarta"} />
      </div>
      <div style={{ marginTop: "0.5vh"}}>
        {/* Texto en la parte superior */}
        <p style={{ color: "black" }}>{t("MeCuido.texto")}</p>

        <img style={{ marginBottom: "1vh"}}src={Autocuidado_Icono} alt={t("Mecuido-Icono")} className="imagenMiAutocuidadoCarta" />
      </div>
    </div>
  )
}

export default MiAutocuidadoCarta;






