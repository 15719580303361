/*global google*/
import { useEffect, useState, useContext } from "react";
import { FcGoogle, FcAndroidOs } from "react-icons/fc";
import Ripples from "react-ripples";
import UserContext from "../UserContext";
import { useNavigate } from "react-router-dom";
import "./login.css";
import Logo from "../Images/Logo.png";
import LogoLetra from "../Images/LogoLetra.png";
import ImagenIntro from "../Images/IntroWeb.png";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { Link } from "react-router-dom";
const Login = () => {
  const google = (window.google = window.google ? window.google : {});
  const [tokenCLient, setTokenClient] = useState({});
  const [userOauth, setUserOauth] = useState({});
  const [CLIENT_ID, setCLIENT_ID] = useState(null);
  const { setEmail } = useContext(UserContext);
  const { mostrar, setMostrar } = useContext(UserContext);
  // eslint-disable-next-line no-unused-vars
  const [nombre, setNombre] = useState();
  const { ventanaAnterior, setVentanaAnterior } = useContext(UserContext);
  const SCOPES =
    "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/fitness.activity.read https://www.googleapis.com/auth/fitness.heart_rate.read https://www.googleapis.com/auth/fitness.location.read https://www.googleapis.com/auth/fitness.sleep.read  ";
  const { bearer, setBearer } = useContext(UserContext);

  const navigate = useNavigate();

  const [t] = useTranslation("global");

  useEffect(() => {
    const initGoogleAuth = () => {
      if (
        typeof google !== "undefined" &&
        google.accounts &&
        CLIENT_ID !== null
      ) {
        // Google está cargado, puedes inicializar el cliente de token y realizar más operaciones aquí
        setTokenClient(
          google.accounts.oauth2.initTokenClient({
            client_id: CLIENT_ID,
            scope: SCOPES,
            callback: (tokenResponse) => {
              setUserOauth(tokenResponse);
            },
          })
        );

        google.accounts.id.prompt(); // Asumiendo que esto está en el flujo de tu aplicación
      } else {
        // Google no está definido aún, intenta inicializar nuevamente después de un delay
        setTimeout(initGoogleAuth, 100);
      }
    };

    initGoogleAuth();
  }, [CLIENT_ID]);

  useEffect(() => {
    const getclient_id = async (accessToken) => {
      try {
        // Llamada a la API de Google para obtener la información del usuario
        const response = await axios.get(t("Recursos.ip") + "/cliente");

        console.log("respuesta: " + response);
        //Console.log("DWDW");
        setCLIENT_ID(response.data);
      } catch (error) {
        // Si ocurre algún error en la llamada a la API, lo manejas aquí
        console.error("Error al obtener información del client_id", error);

        return null;
      }
    };

    getclient_id();
    console.log("Client id: " + CLIENT_ID);
  }, [t]);

  useEffect(() => {
    /*ESTE COMENTARIO DEFINE GOOGLE*/
    /* global google */
    /*ESTE COMENTARIO DEFINE GOOGLE*/

    if (CLIENT_ID !== null) {
      // Realizar el flujo normal de autenticación si no hay AccessToken almacenado
      setTokenClient(
        google.accounts.oauth2.initTokenClient({
          client_id: CLIENT_ID,
          scope: SCOPES,
          callback: (tokenResponse) => {
            setUserOauth(tokenResponse);
          },
        })
      );
    }

    // Verificar que google está definido antes de usarlo
    if (google && google.accounts && google.accounts.id) {
      google.accounts.id.prompt();
    } else {
      console.error("La variable google no está definida");
    }
  }, [setEmail, setBearer, userOauth, CLIENT_ID]);

  useEffect(() => {
    // Realiza la comprobación continua del valor bearer aquí

    const getUserInfo = async (accessToken) => {
      try {
        // Llamada a la API de Google para obtener la información del usuario
        const response = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        // En la respuesta, tienes acceso a los datos del usuario, incluyendo el email y el nombre
        const { email, name } = response.data;
        setNombre(name);
        setEmail(email);

        console.log("Email enviado");

        localStorage.setItem("email", email);

        const responseemailcheck = await axios.post(
          t("Recursos.ip") + "/accounts/emailcheck",
          {
            email: email,
          }
        );

        //COMPROBAR SI EL EMAIL ESTA EN BD O NO
        //Si no esta lo mandamos al registro
        if (responseemailcheck.data !== true) {
          navigate("/FormularioRegistro");
        } else {
          //Si está registrado comprobamos si ha cubierto la info personal
          const response = await axios.get(t("Recursos.ip") + "/respuesta", {
            params: {
              email: email,
              numerocuestionario: 0,
            },
          });

          if (response.data === true) {
            //Si lo ha cubierto preguntamos si ha cubierto hoy el estado de animo

            const response2 = await axios.get(
              t("Recursos.ip") + "/estadoanimo/cubiertohoy",
              {
                params: {
                  email: email,
                },
              }
            );

            if (response2.data !== true) {
              //si no lo ha cubierto al formulario estado animo
              setMostrar(true);
              navigate("/CuestionarioEstadoAnimo");
            } else {
              //si lo ha cubierto al home
              if (ventanaAnterior !== null) {
                console.log("Aqui", ventanaAnterior);
                setMostrar(true);
                navigate(ventanaAnterior);
              } else {
                console.log("No, Aqui");
                setMostrar(true);
                navigate("/pantalladeCarga");
              }
            }
          } else {
            //Si no, se le manda al formulario
            setMostrar(true);
            navigate("/anadirinfopersonal");
          }
        }

        return { email, name };
      } catch (error) {
        // Si ocurre algún error en la llamada a la API, lo manejas aquí
        console.error("Error al obtener información del usuario:", error);
        return null;
      }
    };

    if (bearer !== null) {
      // handleFetchUserEmail();
      getUserInfo(bearer);
    }
  }, [bearer, setEmail, navigate, t]); // Dependencias del efecto: bearer y navigate

  useEffect(() => {
    //Deberia poder juntar este y el de arriba, pendiente de revision

    if (userOauth.access_token != null) {
      setBearer(userOauth.access_token);
      // Almacenar el token en el almacenamiento local
      localStorage.setItem("accessToken", userOauth.access_token);
      localStorage.setItem("accessTokenTimestamp", Date.now());
    }
  }, [userOauth.access_token, setBearer]);

  function callToken() {
    tokenCLient.requestAccessToken();
    setBearer(userOauth.access_token);
  }



  const handleClick = () => {
    window.open("https://play.google.com/store/apps/details?id=com.talionis.appmovilsimuladorweb&pcampaignid=web_share", '_blank');
  };

  return (
    <div style={{ height: "100vh", width: "100%", marginBottom: "-5%" }} className="containerImagenIzq">
      <div className="containerLogin">
        <div >

        </div>


        <div className="containerBotonesLogin">
          <div className="cardBotonesLogin">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                src={Logo}
                alt={t("Logo")}
                style={{ width: "30%" }}
              />
              <img
                src={LogoLetra}
                alt={t("Logo-Letra")}
                style={{ width: "30%", marginTop: "5%", marginBottom: "5%" }}
              />
            </div>
            <button
              type="button"
              className="btnGoogle"
              onClick={(e) => callToken()}
            >
              {t("Login-Google")}&nbsp;
            </button>
            <p style={{ marginTop: "5%", marginBottom: "1%", maxWidth: "85%" }}>
              <b>{t("Quieres-App")}</b>
            </p>
            <button
              type="button"
              className="button-style-android"
              onClick={handleClick}
            >
              <FcAndroidOs style={{ fontSize: "30px", marginLeft: "-20%" }} />
              &nbsp;{" "}&nbsp;{" "}&nbsp;{" "} {t("Descarga-App")}
            </button>

            <div style={{
              display: "flex",
              flexDirection: "row",
              textAlign: "center",
              position: "relative",
              bottom: "-10%",
              marginLeft: "7.5%"
            }}>
              <div onClick={() => navigate("/Ayuda")} style={{ color: "#518ea6", cursor: "pointer" }}>{t("Ayuda")}</div>
              <span style={{ width: "50px" }}></span>
              <div onClick={() => navigate("/QuienesSomos")} style={{ color: "#518ea6", cursor: "pointer" }}>{t("QuienesSomos")}</div>
            </div>

          </div>
        </div>

      </div>
    </div>
  );
};
export default Login;
