import React from 'react';
import Card from '../Card';
import './Cuadrado.css';

function Cuadrado({ index, minutos, color, horaInicio, widthPercentage, horaFin, onCuadradoHover, valor }) {
  const cuadradoEstilo = {
    width: `${widthPercentage}%`, // Utiliza porcentaje en lugar de píxeles
    height: '3.5rem',
    backgroundColor: color,
    position: 'relative',
    transition: 'background-color 0.3s',
  };

  return (
    <div
      style={cuadradoEstilo}
      onMouseEnter={() => onCuadradoHover(color, minutos, horaInicio, horaFin, valor, index)}
      onMouseLeave={() => onCuadradoHover(null, null, null, null, null, null)}
    >
      {/* No necesitas manejar eventos de ratón aquí */}
    </div>
  );
}

export default Cuadrado;
