import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import "./CircularProgressBar.css"
import { easeQuadInOut } from "d3-ease";
import AnimatedProgressProvider from "./AnimatedProgressProvider"
const CircularProgressBarPasos = ({ progress, maxValue, texto, colortexto, colorfondo, colorpath, ancho, alto }) => {
  const trailColor = "#FBECB2";  
  const formatearNumeroConPuntoDeMiles = (numero) => {
    if (numero === null) {
      return 0;
    } else{
    return numero.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");}
  }
    // Verificar si progress es nulo o está vacío, y asignarle el valor 0 en ese caso
   
  return (
    
    <div className="circular-progress-bar" style={{ width: ancho, height: alto }} > {/* Aplica la clase CSS al contenedor */}
      <AnimatedProgressProvider
        valueStart={0}
        valueEnd={progress}
        duration={1.4}
        easingFunction={easeQuadInOut}
      // repeat
      >
        {progress => {
          const roundedValue = Math.round(progress);
          return (
            <CircularProgressbar
              value={progress}
              maxValue={maxValue}

              text={formatearNumeroConPuntoDeMiles(`${roundedValue}`)}
              background
              backgroundPadding={6}
              /* This is important to include, because if you're fully managing the
        animation yourself, you'll want to disable the CSS animation. */

              styles={
                buildStyles({
                  backgroundColor: colorfondo,
                  textColor: colortexto,
                  pathColor: colorpath,
                  trailColor: trailColor,
                  pathTransition: "none"
                })}
            />
          );
        }}
      </AnimatedProgressProvider>
    </div>

  );
}

export default CircularProgressBarPasos;