import { useEffect, useContext, useState } from "react";
import "./CuestionarioEstadoAnimo.css";
import UserContext from "../../UserContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import mood1 from "../../Images/mood1.png";
import mood2 from "../../Images/mood2.png";
import mood3 from "../../Images/mood3.png";
import mood4 from "../../Images/mood4.png";
import mood5 from "../../Images/mood5.png";
import mood1checked from "../../Images/mood1checked.png";
import mood2checked from "../../Images/mood2checked.png";
import mood3checked from "../../Images/mood3checked.png";
import mood4checked from "../../Images/mood4checked.png";
import mood5checked from "../../Images/mood5checked.png";
import { useTranslation } from "react-i18next";

const CuestionarioEstadoAnimo = () => {
  const navigate = useNavigate();
  const [t] = useTranslation("global");
  const email = useContext(UserContext);
  const [selectedMood, setSelectedMood] = useState(null);
  const { ventanaAnterior, setVentanaAnterior } = useContext(UserContext);
  const { mostrar, setMostrar } = useContext(UserContext);
  const handleMoodClick = (mood) => {
    setSelectedMood(mood);

    console.log(mood);
  };

  const handleMastarde = () => {
    if (ventanaAnterior !== null) {
      navigate(ventanaAnterior);
    } else { navigate("/pantalladeCarga"); }
  };

  const handleConfirmClick = async () => {

    if (selectedMood) {
      try {
        const response1 = await axios.post(t("Recursos.ip") + "/estadoanimo", {
          estadoAnimo: selectedMood,
          email: email.email,
        });
        console.log(response1);
        if (ventanaAnterior !== null) {
          setMostrar(true)
          navigate(ventanaAnterior);
        } else {
          setMostrar(true)
          navigate("/pantalladeCarga");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      alert(t("Estado-Animo-Cuestionario.Alerta"));
    }
  };

  return (
    <div className="roott">
      <div className="cuestionario-container">
        <h1 style={{ marginTop: "5%" }}>
          {t("Estado-Animo-Cuestionario.Pregunta")}

        </h1>
        <div className="mood-container">
          <div className="imagenytexto">
            <img
              src={selectedMood === 1 ? mood1checked : mood1}
              alt={t("Estado-Animo.Muy-mal")}
              className={selectedMood === 1 ? "moodchecked" : "mood"}
              onClick={() => handleMoodClick(1)}
            />
            <br />
            <b>{t("Estado-Animo.Muy-mal")}</b>
          </div>
          <div className="imagenytexto">
            <img
              src={selectedMood === 2 ? mood2checked : mood2}
              alt={t("Estado-Animo.Mal")}
              className={selectedMood === 2 ? "moodchecked" : "mood"}
              onClick={() => handleMoodClick(2)}
            />
            <br />
            <b>{t("Estado-Animo.Mal")}</b>
          </div>
          <div className="imagenytexto">
            <img
              src={selectedMood === 3 ? mood3checked : mood3}
              alt={t("Estado-Animo.Normal")}
              className={selectedMood === 3 ? "moodchecked" : "mood"}
              onClick={() => handleMoodClick(3)}
            />
            <br />
            <b>{t("Estado-Animo.Normal")}</b>
          </div>
          <div className="imagenytexto">
            <img
              src={selectedMood === 4 ? mood4checked : mood4}
              alt={t("Estado-Animo.Bien")}
              className={selectedMood === 4 ? "moodchecked" : "mood"}
              onClick={() => handleMoodClick(4)}
            />
            <br />
            <b>{t("Estado-Animo.Bien")}</b>
          </div>
          <div className="imagenytexto">
            <img
              src={selectedMood === 5 ? mood5checked : mood5}
              alt={t("Estado-Animo.Muy-bien")}
              className={selectedMood === 5 ? "moodchecked" : "mood"}
              onClick={() => handleMoodClick(5)}
            />
            <br />
            <b>{t("Estado-Animo.Muy-bien")}</b>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            className="botoncirc-tarde"
            style={{ marginRight: "5%", width: window.innerWidth <= 768 ? "47%" : "20%", height: window.innerWidth <= 768 ? "100%" : "10%" }}
            onClick={handleMastarde}
          >
            {t("Estado-Animo-Cuestionario.Responder-mas-tarde")}
          </button>

          <button
            className="botoncirc-conf"
            style={{ width: window.innerWidth <= 768 ? "47%" : "20%", height: window.innerWidth <= 768 ? "100%" : "10%"}}
            onClick={handleConfirmClick}
          >
            {t("Estado-Animo-Cuestionario.Confirmar")}
          </button>
        </div>
        <br></br>
      </div>
    </div>
  );
};

export default CuestionarioEstadoAnimo;
