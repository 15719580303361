import { useEffect, useContext, useState } from "react";
import UserContext from "../../UserContext";
import { useNavigate } from "react-router-dom";
import PreguntaRespuestaCuadrados from "../../components/Cuestionarios/PreguntaRespuestaCuadrados";
import EscalavisualanalogicaVertical from "../../components/Cuestionarios/EscalavisualanalogicaVertical";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import Card from "../../components/Card";
import { tr } from "date-fns/locale";
import CheckboxesCuestionarioEQ5D5L from "../../components/Cuestionarios/CheckboxesCuestionarioEQ5D5L";
import "./CuestionarioSocioDemo.css";
import TextoConCard from "../../components/Cuestionarios/TextoConCard";
import Euroqol_Logo from "../../Images/EQ-5D-5L_Logo.png";
import { ImArrowRight } from "react-icons/im";
import { ImArrowLeft } from "react-icons/im";
import TituloyFlecha from "../../components/TituloyFlecha";

const Cuestionario1 = () => {
  
  const navigate = useNavigate();
  const email = useContext(UserContext);

  const [t] = useTranslation("global");
  const [todoRespondido, SetTodoRespondido] = useState(true);
  const [pagina, sePagina] = useState(0);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [navigate]);

  const numero_cuestionario = 1;

  const listaDePreguntas = [
    t("Cuestionario1.Preguntas.1"),
    t("Cuestionario1.Preguntas.2"),
    t("Cuestionario1.Preguntas.3"),
    t("Cuestionario1.Preguntas.4"),
    t("Cuestionario1.Preguntas.5"),
  ];

  const ordenDeseado = [
    t("Cuestionario1.Preguntas.1"),
    t("Cuestionario1.Preguntas.2"),
    t("Cuestionario1.Preguntas.3"),
    t("Cuestionario1.Preguntas.4"),
    t("Cuestionario1.Preguntas.5"),
  ];

  // Utilizar un objeto para almacenar las respuestas
  const [respuestas, setRespuestas] = useState({});
  const [respuestaEscala, setRespuestaEscala] = useState({});

  // Función para manejar las respuestas de cada instancia de PreguntaRespuestaCuadrados
  const handleRespuestaSubmit = (pregunta, respuesta) => {
    // Actualizar el objeto de respuestas con la respuesta correspondiente
    setRespuestas((prevRespuestas) => ({
      ...prevRespuestas,
      [pregunta]: respuesta,
    }));
  };

  // Función para mostrar todas las respuestas en la consola
  const mostrarRespuestasEnConsola = async () => {
    const respuestasEnOrden = ordenDeseado.map((item) => respuestas[item]);
    respuestasEnOrden.push(respuestaEscala);

    const puntuacion = '"' + traducirArray(respuestasEnOrden).join('","') + '"';
    const resultado = '"' + respuestasEnOrden.join('","') + '"';
    console.log(resultado, puntuacion);
    const hayDosComillasSinValor = /""|\s*""\s*|\[object Object\]/.test(
      resultado
    );

    if (hayDosComillasSinValor) {
      SetTodoRespondido(false);
    } else {
      SetTodoRespondido(true);
      try {
        const response = await axios.post(t("Recursos.ip") + "/respuesta", {
          numeroCuestionario: numero_cuestionario,
          arrayCuestionario: resultado,
          arrayPuntuacionCuestionario: puntuacion,
          email: email.email,
        });

        console.log(response.data);
        navigate("/Cuestionario/1/intentos");
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const traducirArray = (array) => {
    return array.map((elemento) => traducirAValor(elemento));
  };
  const traducirAValor = (cadena) => {
    switch (cadena) {
      case t("Cuestionario1.Respuestas1.1"):
      case t("Cuestionario1.Respuestas2.1"):
      case t("Cuestionario1.Respuestas3.1"):
      case t("Cuestionario1.Respuestas4.1"):
      case t("Cuestionario1.Respuestas5.1"):
        return 1;
      case t("Cuestionario1.Respuestas1.2"):
      case t("Cuestionario1.Respuestas2.2"):
      case t("Cuestionario1.Respuestas3.2"):
      case t("Cuestionario1.Respuestas4.2"):
      case t("Cuestionario1.Respuestas5.2"):
        return 2;
      case t("Cuestionario1.Respuestas1.3"):
      case t("Cuestionario1.Respuestas2.3"):
      case t("Cuestionario1.Respuestas3.3"):
      case t("Cuestionario1.Respuestas4.3"):
      case t("Cuestionario1.Respuestas5.3"):
        return 3;
      case t("Cuestionario1.Respuestas1.4"):
      case t("Cuestionario1.Respuestas2.4"):
      case t("Cuestionario1.Respuestas3.4"):
      case t("Cuestionario1.Respuestas4.4"):
      case t("Cuestionario1.Respuestas5.4"):
        return 4;
      case t("Cuestionario1.Respuestas1.5"):
      case t("Cuestionario1.Respuestas2.5"):
      case t("Cuestionario1.Respuestas3.5"):
      case t("Cuestionario1.Respuestas4.5"):
      case t("Cuestionario1.Respuestas5.5"):
        return 5;
      default:
        return cadena; // Valor por defecto o manejo de error
    }
  };

  const handleRespuestaEscala = (respuesta) => {
    // Manejar la respuesta en el componente padre
    setRespuestaEscala(respuesta);
  };

  function paginar(valor) {
    SetTodoRespondido(true);
    if (valor > 0 && pagina < 6) {
      sePagina(pagina + valor);
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else if (valor < 0 && pagina > 0) {
      sePagina(pagina + valor);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }

  return (
    <div>
      <TituloyFlecha title={t("Cuestionario1.Titulo")} link={"/Cuestionario/1/intentos"} />
      <div className="cuestionarioContainer" style={{ marginTop: "1%" }}>
        <div>
          <br />
          <br />
          <div
            style={{
              display: pagina === 0 ? "block" : "none",
              marginBottom: "5.4%",
            }}
          >
            <img src={Euroqol_Logo} alt={t("Cuestionario1.Euroqol_Logo")} />
            <br></br>
            <h1>{t("Cuestionario1.TextoIntro1")}</h1>
            <h2>{t("Cuestionario1.TextoIntro2")}</h2>
            <h2>{t("Cuestionario1.TextoIntro3")}</h2>
          </div>
          <div style={{ display: pagina === 1 ? "block" : "none" }}>
            <CheckboxesCuestionarioEQ5D5L
              preguntas={[listaDePreguntas[0]]}
              respuestasComunes={[
                t("Cuestionario1.Respuestas1.1"),
                t("Cuestionario1.Respuestas1.2"),
                t("Cuestionario1.Respuestas1.3"),
                t("Cuestionario1.Respuestas1.4"),
                t("Cuestionario1.Respuestas1.5"),
              ]}
              onRespuestaSubmit={handleRespuestaSubmit}
            />
          </div>
          <div style={{ display: pagina === 2 ? "block" : "none" }}>
            <CheckboxesCuestionarioEQ5D5L
              preguntas={[listaDePreguntas[1]]}
              respuestasComunes={[
                t("Cuestionario1.Respuestas2.1"),
                t("Cuestionario1.Respuestas2.2"),
                t("Cuestionario1.Respuestas2.3"),
                t("Cuestionario1.Respuestas2.4"),
                t("Cuestionario1.Respuestas2.5"),
              ]}
              onRespuestaSubmit={handleRespuestaSubmit}
            />
          </div>
          <div style={{ display: pagina === 3 ? "block" : "none" }}>
            <CheckboxesCuestionarioEQ5D5L
              preguntas={[listaDePreguntas[2]]}
              respuestasComunes={[
                t("Cuestionario1.Respuestas3.1"),
                t("Cuestionario1.Respuestas3.2"),
                t("Cuestionario1.Respuestas3.3"),
                t("Cuestionario1.Respuestas3.4"),
                t("Cuestionario1.Respuestas3.5"),
              ]}
              aclaracion="(Ej: trabajar, estudiar, hacer las tareas domésticas, actividades familiares o actividades durante el tiempo libre)"
              onRespuestaSubmit={handleRespuestaSubmit}
            />
          </div>
          <div style={{ display: pagina === 4 ? "block" : "none" }}>
            <CheckboxesCuestionarioEQ5D5L
              preguntas={[listaDePreguntas[3]]}
              respuestasComunes={[
                t("Cuestionario1.Respuestas4.1"),
                t("Cuestionario1.Respuestas4.2"),
                t("Cuestionario1.Respuestas4.3"),
                t("Cuestionario1.Respuestas4.4"),
                t("Cuestionario1.Respuestas4.5"),
              ]}
              onRespuestaSubmit={handleRespuestaSubmit}
            />
          </div>
          <div style={{ display: pagina === 5 ? "block" : "none" }}>
            <CheckboxesCuestionarioEQ5D5L
              preguntas={[listaDePreguntas[4]]}
              respuestasComunes={[
                t("Cuestionario1.Respuestas5.1"),
                t("Cuestionario1.Respuestas5.2"),
                t("Cuestionario1.Respuestas5.3"),
                t("Cuestionario1.Respuestas5.4"),
                t("Cuestionario1.Respuestas5.5"),
              ]}
              onRespuestaSubmit={handleRespuestaSubmit}
            />
          </div>
          <div
            style={{
              display: pagina === 6 ? "block" : "none",
              marginTop: "-2.5%",
            }}
          >
            <EscalavisualanalogicaVertical
              textoArriba={t("Cuestionario1.Escalas.textoArriba")}
              textoAbajo={t("Cuestionario1.Escalas.textoAbajo")}
              textoSeleccionado={t("Cuestionario1.Escalas.textoSeleccionado")}
              onRespuestaSeleccionada={handleRespuestaEscala}
            />
          </div>
        </div>
        {!todoRespondido && (
          <div>
            <p style={{ color: "red" }}>{t("Responde-todo")}</p>
          </div>
        )}
        <div style={{ display: "flex" }}>
          <button
            onClick={() => paginar(-1)}
            style={{
              marginTop: "30px",
              marginRight: window.innerWidth <= 768 ? "10%" : "-25%",
              width: window.innerWidth <= 768 ? "50%" : "20%",
              visibility: pagina === 0 ? "hidden" : "visible",
              color: "black",
              backgroundColor: "white",
              border: "1px solid black", // Agregar borde negro
              borderRadius: "5px",
            }}
            className="link-button"
          >
            <ImArrowLeft color="green" /> {t("Atras")}
          </button>

          {pagina < 6 && (
            <button
              onClick={() => paginar(1)}
              style={{
                marginTop: "30px",
                marginLeftt: window.innerWidth <= 768 ? "-10%" : "25%",
                width: window.innerWidth <= 768 ? "50%" : "20%",
                visibility: pagina === 6 ? "hidden" : "visible",
                color: "black",
                backgroundColor: "white",
                border: "1px solid black",
                borderRadius: "5px",
              }}
              className="link-button"
            >
              {t("Siguiente")} <ImArrowRight color="green" />
            </button>
          )}
          {pagina === 6 && (
            <button
              onClick={mostrarRespuestasEnConsola}
              className="link-button"
              style={{
                width: window.innerWidth <= 768 ? "50%" : "20%",
                marginTop: "30px",
                color: "black",
                backgroundColor: "white",
                border: "1px solid black",
                borderRadius: "5px",
              }}
            >
              {t("Confirmar")}
            </button>
          )}
        </div>
        <p style={{ marginTop: "-5%" }}>
          © EuroQol Research Foundation. EQ-5D<sup>TM</sup> is a trade mark of
          the EuroQol Research Foundation. Spain (Spanish) v1.0
        </p>
      </div>
    </div>
  );
};

export default Cuestionario1;
