import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import * as d3TimeFormat from "d3-time-format";
import "./CustomBarChartSueno.css";
import { useTranslation } from "react-i18next";
import { timeFormatDefaultLocale, timeFormat, timeParse } from "d3-time-format";

const CustomBarChartSueno = ({ data, dateDisplayType }) => {
  const chartRef = useRef();
  const [hoveredData, setHoveredData] = useState(null);
  const [t] = useTranslation("global");

  const locale = {
    dateTime: "%A, %e de %B de %Y, %X",
    date: "%d/%m/%Y",
    time: "%H:%M:%S",
    periods: ["AM", "PM"],
    days: [
      t("DiasSemana.Domingo"),
      t("DiasSemana.Lunes"),
      t("DiasSemana.Martes"),
      t("DiasSemana.Miercoles"),
      t("DiasSemana.Jueves"),
      t("DiasSemana.Viernes"),
      t("DiasSemana.Sabado"),
    ],
    shortDays: [
      t("shortDiasSemana.Domingo"),
      t("shortDiasSemana.Lunes"),
      t("shortDiasSemana.Martes"),
      t("shortDiasSemana.Miercoles"),
      t("shortDiasSemana.Jueves"),
      t("shortDiasSemana.Viernes"),
      t("shortDiasSemana.Sabado"),
    ],
    months: [
      t("Meses.Enero"),
      t("Meses.Febrero"),
      t("Meses.Marzo"),
      t("Meses.Abril"),
      t("Meses.Mayo"),
      t("Meses.Junio"),
      t("Meses.Julio"),
      t("Meses.Agosto"),
      t("Meses.Septiembre"),
      t("Meses.Octubre"),
      t("Meses.Noviembre"),
      t("Meses.Diciembre"),
    ],
    shortMonths: [
      t("shortMeses.Enero"),
      t("shortMeses.Febrero"),
      t("shortMeses.Marzo"),
      t("shortMeses.Abril"),
      t("shortMeses.Mayo"),
      t("shortMeses.Junio"),
      t("shortMeses.Julio"),
      t("shortMeses.Agosto"),
      t("shortMeses.Septiembre"),
      t("shortMeses.Octubre"),
      t("shortMeses.Noviembre"),
      t("shortMeses.Diciembre"),
    ],
  };
  timeFormatDefaultLocale(locale);

  useEffect(() => {
    if (!chartRef.current) return;

    const svg = d3.select(chartRef.current);
    svg.selectAll("*").remove();

    const margin = { top: 20, right: 20, bottom: 40, left: 40 };
    const width = 600 - margin.left - margin.right;
    const height = 400 - margin.top - margin.bottom;

    const parseTime = d3.timeParse("%Y-%m-%dT%H:%M:%S");

    let xScale;

    if (dateDisplayType === "1" || dateDisplayType === "2") {
      xScale = d3
        .scaleBand()
        .domain(data.map((d) => parseTime(d.horaInicio)))
        .range([0, width])
        .padding(0.2);
    } else if (dateDisplayType === "3") {
      xScale = d3
        .scaleBand()
        .domain(data.map((d) => parseTime(d.horaInicio)))
        .range([width, 0]) // Invierte el rango para agrupar por meses
        .padding(0.2);
    }

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(data, (d) => d.duracionTotal) + 100])
      .range([height, 0]);

    const chart = svg
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const xAxis = d3.axisBottom(xScale);

    const formatXAxis = (date, index, dates) => {
      const formattedDates = [];
      let lastDate = null;
      for (let i = 0; i < dates.length; i++) {
        let currentDate = new Date(dates[i]);
        if (i === 0 && d3TimeFormat.timeFormat("%A")(currentDate) === t("DiasSemana.Domingo")) {
          currentDate.setDate(currentDate.getDate() + 1);
        } else if (lastDate && d3TimeFormat.timeFormat("%d-%m-%Y")(currentDate) === d3TimeFormat.timeFormat("%d-%m-%Y")(lastDate)) {
          currentDate.setDate(currentDate.getDate() + 1);
        }
        formattedDates.push(currentDate);
        lastDate = currentDate;
      }
      return formattedDates[index];
    };

    if (dateDisplayType === "1") {
      xAxis.tickFormat((date, index) => {
        const adjustedDate = formatXAxis(date, index, xScale.domain());
        return `${d3TimeFormat.timeFormat("%A")(adjustedDate)} ${d3TimeFormat.timeFormat("%-d")(adjustedDate)}`;
      });
    } else if (dateDisplayType === "2") {
      xAxis.tickFormat(d3TimeFormat.timeFormat("%-d"));
    } else if (dateDisplayType === "3") {
      xAxis.tickFormat(d3TimeFormat.timeFormat("%B"));
    }

    chart
      .selectAll(".bar")
      .data(data)
      .enter()
      .append("rect")
      .attr("class", "bar")
      .attr("x", (d) => xScale(parseTime(d.horaInicio)))
      .attr("width", xScale.bandwidth())
      .attr("fill", "#BEAFFF")
      .attr("y", height) // Comienza desde abajo
      .attr("height", 0) // Altura inicial cero
      .on("mouseover", function (event, d) {
        d3.select(this).attr("fill", "#756AB6");
        setHoveredData(d);
      })
      .on("mouseout", function () {
        d3.select(this).attr("fill", "#BEAFFF");
        setHoveredData(null);
      })
      .transition() // Agregar la transición
      .duration(1000) // Duración de la transición en milisegundos
      .attr("y", (d) => yScale(d.duracionTotal)) // Posición final de la barra
      .attr("height", (d) => height - yScale(d.duracionTotal)); // Altura final de la barra

    const dateDisplayTypeLabels = {
      "1": t("Dia"),
      "2": t("Dia"),
      "3": t("Mes"),
    };

    const xAxisLabel = dateDisplayTypeLabels[dateDisplayType] || "";

    const yAxisLabel = t("Duración (minutos)");

    chart
      .append("g")
      .attr("transform", `translate(0,${height})`)
      .call(xAxis)
      .selectAll("text")
      .style("text-anchor", "end")
      .attr("transform", "rotate(-45)")
      .attr("dy", "0.5em")
      .attr("dx", "-0.5em");

    const yAxis = chart.append("g").call(d3.axisLeft(yScale));

    yAxis.selectAll("text").attr("dy", "0.5em");

    chart
      .append("text")
      .attr("transform", `translate(${width / 2},${height + margin.top + 40})`)
      .style("text-anchor", "middle")
      .style("font-size", "12px")
      .text(xAxisLabel);

    chart
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 0 - margin.left)
      .attr("x", 0 - height / 2)
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .style("font-size", "12px")
      .text(yAxisLabel);
  }, [data, dateDisplayType]);

  function convertirMinutosAHoras(minutos) {
    if (isNaN(minutos) || minutos < 0) {
      return "Formato de tiempo no válido";
    }

    const horas = Math.floor(minutos / 60);
    const minutosRestantes = Math.ceil(minutos % 60); // Aproximar hacia arriba

    if (minutos === 0) {
      return "0 h 0 min";
    }

    let resultado = "";

    if (horas > 0) {
      resultado += `${horas} h `;
    }

    if (minutosRestantes > 0) {
      resultado += `${minutosRestantes} min`;
    }

    return resultado.trim(); // Eliminamos espacios al final si no hay minutos
  }

  const parseTime = d3.timeParse("%Y-%m-%dT%H:%M:%S");

  const width = 600;
  const height = 425;

  return (
    <div className="anchoventana">
      <svg
        ref={chartRef}
        viewBox={`0 0 ${width} ${height}`}
        preserveAspectRatio="xMidYMid meet"
        style={{ height: "100%", width: "100%" }}
      />

      <div
        style={{
          marginTop: window.innerWidth <= 768 ? "-5%" : "0%",
          fontSize: "15px",
        }}
      >
        {hoveredData ? (
          <div>
            <strong>{t("Duracion total")}:</strong>{" "}
            {convertirMinutosAHoras(hoveredData.duracionTotal)} <br />
            <strong>{t("Sueño-Ligero")}:</strong>{" "}
            {convertirMinutosAHoras(hoveredData.duracionSuenoLigero)}{" "}
            <strong>{t("Sueño-Profundo")}:</strong>{" "}
            {convertirMinutosAHoras(hoveredData.duracionSuenoProfundo)}{" "}
            <strong>{t("REM")}:</strong>{" "}
            {convertirMinutosAHoras(hoveredData.duracionSuenoREM)}{" "}
            <strong>{t("Despierto/a")}:</strong>{" "}
            {convertirMinutosAHoras(hoveredData.duracionDespierto)}
            <br />
            {dateDisplayType != 3 ? (
              <div>
                <strong>{t("Hora-de-acostarse")}:</strong>{" "}
                {d3TimeFormat.timeFormat("%H:%M %-d %B")(
                  parseTime(hoveredData.horaInicio)
                )}{" "}
                <strong>{t("Hora-de-despertarse")}:</strong>{" "}
                {d3TimeFormat.timeFormat("%H:%M %-d %B")(
                  parseTime(hoveredData.horaFin)
                )}
              </div>
            ) : (
              <div> </div>
            )}
          </div>
        ) : (
          <div style={{ visibility: "hidden" }}>
            <strong>{t("Duracion total")}: </strong>
            <br />
            <strong>{t("Duracion sueño ligero")}: </strong>{" "}
            <strong>{t("Sueño-Profundo")}: </strong>{" "}
            <strong>{t("REM")}: </strong> <strong>{t("Despierto/a")}: </strong>
            <br />
            <strong>{t("Hora-de-acostarse")}: </strong>{" "}
            <strong>{t("Hora-de-despertarse")}: </strong>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomBarChartSueno;
