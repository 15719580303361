import React, { Component } from "react";
import "./EscalavisualanalogicaVertical.css";


class EscalavisualanalogicaVertical extends Component {


  constructor(props) {
    super(props);
    this.state = {
      selectedValue: null,
    };
  }


  handleClick = (event) => {
    const linea = this.lineaRef.getBoundingClientRect();
    const clickY = event.clientY - linea.top;
    const totalHeight = linea.bottom - linea.top;
    const porcentaje = 100 - (clickY / totalHeight) * 100;
    const respuesta = Math.round(porcentaje);

    this.setState({ selectedValue: respuesta }, () => {
      if (this.props.onRespuestaSeleccionada) {
        this.props.onRespuestaSeleccionada(respuesta);
      }
    });
  };

  render() {
    const { selectedValue } = this.state;
    const { textoArriba, textoAbajo, textoSeleccionado } = this.props;

    return (
      <div>
        <p>
          Nos gustaría conocer lo buena o mala que es tu salud HOY. La escala está numerada del 0 al 100, donde 100 representa la mejor salud que te puedes imaginar y 0 representa la peor salud que te puedas imaginar.
        </p>
        <p>
          Toca la escala para indiscar cuál es tu estado de salud HOY.
        </p>
        <div className="textoV">
          <br></br>
          <p>{textoArriba}</p>
        </div>
        <div
          className="lineaV"
          onClick={this.handleClick}
          ref={(ref) => (this.lineaRef = ref)}
        >
          <div className="marcasV">
            {[...Array(101)].map((_, i) => (
              <div
                key={i}
                className={`marcaV ${i % 10 === 0 ? "mayor" : i % 5 === 0 ? "media" : ""
                  }`}
                style={{
                  width: i % 10 === 0 ? "60px" :  "20px",
                }}
              >
                {i % 10 === 0 && <span className="numeroV">{100 - i}</span>}
              </div>
            ))}
          </div>
          {selectedValue !== null && (
            <div
              className="puntoRojo"
              style={{
                position: "absolute",
                top: `${100 - selectedValue - 1}%`, // Ajusta la posición para que esté justo encima del valor seleccionado
                left: "50%",
                transform: "translateX(-50%)",
              }}
            ></div>
          )}
        </div>
        <div className="texto2V">
          <br></br>
          <p>{textoAbajo}</p>
        </div>
        {selectedValue !== null && (
          <div style={{ marginTop: "-1.5%" }}>
            <br></br>
            <p>
              {textoSeleccionado}: {selectedValue}
            </p>
          </div>
        )}
      </div>
    );
  }
}

export default EscalavisualanalogicaVertical;
