import React, { useState, useEffect } from 'react';

function PreguntaRespuesta({ pregunta, onRespuestaSubmit, placeholder, aclaracion, respuestaprevia, ancho="auto"}) {
  const [respuesta, setRespuesta] = useState(respuestaprevia);

  // Este efecto se activará cuando la respuesta cambie
  useEffect(() => {
    // Verifica si la respuesta está vacía y la reemplaza por la respuesta previa
    
      onRespuestaSubmit(pregunta,respuestaprevia);
    
  }, []); // El efecto se activará cuando la respuesta o la respuesta previa cambien

  const handleInputChange = (event) => {
    const nuevaRespuesta = event.target.value;
    setRespuesta(nuevaRespuesta);

    // Llamar a la función de devolución de llamada y pasar la pregunta y respuesta como cadenas
    onRespuestaSubmit(pregunta, nuevaRespuesta);
  };

  const estiloPregunta = {
    fontSize: "20px",
    whiteSpace: "pre-line",
    textAlign: "justify",
    marginLeft: "5%",
    
  };

  const estiloRespuestasContainer = {
    padding: "5px",
    marginLeft: "7.5%",
    marginBottom: "5px",
    marginRight: "15px",
    display: "flex",
    width:ancho,
    alignItems: "center",
    
  };

  return (
    <div>
      <br />
      <p style={estiloPregunta}><b>{pregunta}</b>{aclaracion}</p>
      <input
        style={estiloRespuestasContainer}
        type="text"
        value={respuesta}
        onChange={handleInputChange}
        placeholder={placeholder}
      />
    </div>
  );
}

export default PreguntaRespuesta;
