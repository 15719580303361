import React from "react";
import "./Ayuda.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import Card from "../../components/Card";
import TituloyFlecha from "../../components/TituloyFlecha";

const PreguntasFrecuentes = () => {
  const [t] = useTranslation("global");
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div>

        <TituloyFlecha
          title={t("PreguntasFrecuentes.titulo")}
          link={"/"}
        />

        <div className="contenidoAyuda">
        <h2 className="subtituloAyuda">
            {t("PreguntasFrecuentes.preguntas.preg9")}
          </h2>
          <p>{t("PreguntasFrecuentes.respuestas.resp9")}</p>
          <p>{t("PreguntasFrecuentes.respuestas.resp9,1")}</p>
          <h2 className="subtituloAyuda">
            {t("PreguntasFrecuentes.preguntas.preg8")}
          </h2>
          <p>{t("PreguntasFrecuentes.respuestas.resp8")}</p>
          <p>{t("PreguntasFrecuentes.respuestas.resp8,1")}</p>
          <p>{t("PreguntasFrecuentes.respuestas.resp8,2")}</p>
          <h2 className="subtituloAyuda">
            {t("PreguntasFrecuentes.preguntas.preg0")}
          </h2>
          <p>
            {t("PreguntasFrecuentes.respuestas.resp0")}
            <Link
              to="/AyudaSincronizacion"
              onClick={() => window.scrollTo(0, 0)}
            >
              {" "}
              {t("Manual-Sincronizacion")}
            </Link>
          </p>
          <h2 className="subtituloAyuda">
            {t("PreguntasFrecuentes.preguntas.preg1")}
          </h2>
          <p>{t("PreguntasFrecuentes.respuestas.resp1")}</p>

          <h2 className="subtituloAyuda">
            {t("PreguntasFrecuentes.preguntas.preg2")}
          </h2>
          <p>{t("PreguntasFrecuentes.respuestas.resp2")}</p>

          <h2 className="subtituloAyuda">
            {t("PreguntasFrecuentes.preguntas.preg3")}
          </h2>
          <p>{t("PreguntasFrecuentes.respuestas.resp3")}</p>

          <h2 className="subtituloAyuda">
            {t("PreguntasFrecuentes.preguntas.preg4")}
          </h2>
          <p>{t("PreguntasFrecuentes.respuestas.resp4")}</p>

          <h2 className="subtituloAyuda">
            {t("PreguntasFrecuentes.preguntas.preg5")}
          </h2>
          <p>{t("PreguntasFrecuentes.respuestas.resp5")}</p>

          <h2 className="subtituloAyuda">
            {t("PreguntasFrecuentes.preguntas.preg6")}
          </h2>
          <p>
            {t("PreguntasFrecuentes.respuestas.resp6")}
            <Link
              to="/politica-privacidad"
              onClick={() => window.scrollTo(0, 0)}
            >
              {t("PreguntasFrecuentes.respuestas.resp6,1")}
            </Link>
          </p>
          <h2 className="subtituloAyuda">
            {t("PreguntasFrecuentes.preguntas.preg7")}
          </h2>
          <p>
            {t("PreguntasFrecuentes.respuestas.resp7")}
            <a style={{ color: "#518ea6" }}>
              {" "}
              {t("PreguntasFrecuentes.respuestas.resp7,1")}
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PreguntasFrecuentes;
