// Card.js

import React from "react";


function Card({ content, ancho, alto, className }) {
  return (
    <div className={`${className}`} style={{ width: ancho, height: alto }}>

      {content}

    </div>
  );
}

export default Card;

