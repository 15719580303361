// GlobalStyles.js
import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  body {
    background-color: #FDFDFD; /* Cambia #ffcc00 por el color que desees */
  }
`;

export default GlobalStyles;
