import React from "react";
import { useEffect, useContext} from "react";
import UserContext from "../../UserContext";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { useTranslation } from "react-i18next";
const PreguntaAnadirInfoPersonal = () => {
  const { setEmail } = useContext(UserContext);
  const { setBearer } = useContext(UserContext);
  const bearer = useContext(UserContext);
  const navigate = useNavigate();
  const email = useContext(UserContext);
  const [t] = useTranslation("global");

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [navigate]);



  
  // Función para manejar el clic en los botones
  const handleClick = async (opcion) => {
    if (opcion === "masTarde") {
      // Lógica para "Prefiero hacerlo más tarde"
      try {
        navigate("/ListaCuestionario")
      } catch (error) {
        console.error("Error de redireccion en cuestionario satifaccion", error);
        // Manejar el error según tus necesidades
      }
    } else if (opcion === "ahora") {
      // Lógica para "Sí, quiero ir a información personal detallada"

      navigate("/Cuestionario8");
    }
  };



  return (
    <div>
      <div className="container">
        <div className="titulo">{t("PreguntaCuestionarioSatisfaccion.Cubrir-cuestionario")}</div>
        <div className="subtitulo">
          {t("PreguntaCuestionarioSatisfaccion.Frase-explicativa")}
        </div>
        <div className="botones">
          <button className="botoncirc" onClick={() => handleClick("masTarde")}>
            {t("PreguntaCuestionarioSatisfaccion.mas-tarde")}
          </button>
          <button className="botoncirc" style={{ marginLeft: "5%" }} onClick={() => handleClick("ahora")}>
            {t("PreguntaCuestionarioSatisfaccion.si-dar-info")}
          </button>
        </div>
      </div>

    </div>

  );
};

export default PreguntaAnadirInfoPersonal;
