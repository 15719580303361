import React, { useState } from 'react';
import './PreguntasRespuestaConCasilla.css';
import { useTranslation } from "react-i18next";
function PreguntasRespuestaConCasilla({ listaDePreguntas, getCeldasMarcadas,opcionesRespuesta }) {
  const [celdasSeleccionadas, setCeldasSeleccionadas] = useState({});
  const [t] = useTranslation("global");
  const handleCeldaClick = (filaIndex, respuesta) => {
    const nuevasCeldasSeleccionadas = { ...celdasSeleccionadas };
  
    if (nuevasCeldasSeleccionadas[filaIndex] === respuesta) {
      delete nuevasCeldasSeleccionadas[filaIndex];
    } else {
      nuevasCeldasSeleccionadas[filaIndex] = respuesta;
    }
  
    setCeldasSeleccionadas(nuevasCeldasSeleccionadas);
  
    // Llama a la función de devolución de llamada con las nuevas celdas seleccionadas
    getCeldasMarcadas(nuevasCeldasSeleccionadas);
  };
  

  const obtenerCeldasMarcadas = () => {
    return celdasSeleccionadas;
  };



  return (
    <table className="tabla-preguntas">
      <thead bgcolor="white"> 
        <tr>
          <th>{t("Pregunta")}</th>
          {opcionesRespuesta.map((opcion, index) => (
            <th key={index}>{opcion}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {listaDePreguntas.map((pregunta, filaIndex) => (
          <tr key={filaIndex} style={{ backgroundColor: filaIndex === 0 ? 'white' : 'white' }}>
            <td style={{ textAlign: 'left', width: '300px' }}>
              {pregunta}
            </td>
            {opcionesRespuesta.map((opcion, columnaIndex) => (
              <td
                key={columnaIndex}
                style={{
                  textAlign: 'center',
                  width: '10%',
                  minWidth: '40px',
                  backgroundColor: celdasSeleccionadas[filaIndex] === columnaIndex ? 'lightblue' : 'white',
                  cursor: 'pointer',
                }}
                onClick={() => handleCeldaClick(filaIndex, columnaIndex)}
              >
                {/* Contenido de la celda */}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default PreguntasRespuestaConCasilla;
