import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

function CheckboxesMultiplesCuestionarioSD({
  preguntas,
  respuestasComunes,
  aclaracion,
  onRespuestaSubmit,
  respuestasprevias // Añadido como prop opcional con un valor por defecto vacío
}) {
  const [t] = useTranslation("global");

  function parseRespuestas(input) {
    if (typeof input !== 'string') return [];
    return input.split(" + ");
  }

  // Configuración inicial del estado con respuestas preseleccionadas
  const initialRespuestas = preguntas.reduce((acc, pregunta, index) => {
    const respuestaPreviasParsed = parseRespuestas(respuestasprevias[index] || "");
    acc[pregunta] = {};
    let otrosValue = "";

    respuestasComunes.forEach(respuesta => {
      const isOtros = respuesta.toLowerCase() === "otros" || respuesta.toLowerCase() === "otro";
      if (isOtros && respuestaPreviasParsed.includes(respuesta)) {
        otrosValue = respuestaPreviasParsed[respuestaPreviasParsed.indexOf(respuesta) + 1] || "";
      }
      acc[pregunta][respuesta] = respuestaPreviasParsed.includes(respuesta) && !isOtros;
    });

    if (otrosValue) {
      acc[pregunta][t("Otros")] = otrosValue;
    }

    return acc;
  }, {});

  const [respuestasSeleccionadas, setRespuestasSeleccionadas] = useState(initialRespuestas);
  const [respuestaOtros, setRespuestaOtros] = useState(Object.values(initialRespuestas).reduce((acc, curr) => {
    return {...acc, ...curr};
  }, {})[t("Otros")] || "");
  
  let estiloRespuesta,
    estiloTabla,
    estiloPregunta,
    estiloRespuestasContainer,
    estiloSeparador,
    estiloLinea;

  if (window.innerWidth >= 768) {
    // Estilo para pantalla grande
    estiloRespuesta = {
      padding: "5px",
      marginBottom: "5px",
      marginRight: "15px",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
    };

    estiloTabla = {
      width: "90%",
      marginLeft: "4%",
    };

    estiloPregunta = {
      fontSize: "20px",
      whiteSpace: "pre-line",
      textAlign: "justify",
      marginLeft: "5%",
    };

    estiloRespuestasContainer = {
      marginLeft: "4%",
      textAlign: "justify",
      display: "flex",
    };

    estiloSeparador = {
      margin: "15px 0",
    };

    estiloLinea = {
      marginLeft: "5px",
      marginBottom: "5px",
    };
  } else {
    // Estilo para pantalla pequeña (móvil)
    estiloRespuesta = {
      padding: "5px",
      marginBottom: "5px",
      marginRight: "15px",
      display: "flex",
      alignItems: "center",
    };

    estiloTabla = {
      width: "90%",
      marginLeft: "4%",
    };

    estiloPregunta = {
      fontSize: "20px",
      whiteSpace: "pre-line",
      textAlign: "justify",
      marginLeft: "5%",
    };

    estiloSeparador = {
      margin: "15px 0",
    };

    estiloLinea = {
      marginLeft: "5px",
      marginBottom: "5px",
    };
  }

  const handleRespuestaSubmit = (pregunta) => {
    // Obtener las respuestas seleccionadas para la pregunta actual
    const respuestas = respuestasSeleccionadas[pregunta] || {};
  
    // Filtrar las respuestas seleccionadas y obtener sus nombres
    const respuestasSeleccionadasNombres = respuestasComunes.filter(
      (respuesta) => respuestas[respuesta]
    );
  
    // Si no hay respuestas seleccionadas, devolver un valor nulo o vacío
    if (respuestasSeleccionadasNombres.length === 0) {
      onRespuestaSubmit(pregunta, null);
      return;
    }
  
    // Obtener el texto del campo de texto "Otros" si está seleccionado
    const textoOtros = respuestas[t("Otros")];
  
    // Incluir "Otros" solo si la casilla de "Otros" está marcada y el campo de texto no está vacío
    const valorOtros = typeof textoOtros === 'string' ? textoOtros.trim() : "";
  
    // Llamar a la función de devolución de llamada con las respuestas seleccionadas
    onRespuestaSubmit(
      pregunta,
      respuestasSeleccionadasNombres.join(" + ") +
        (valorOtros ? (respuestasSeleccionadasNombres.length > 0 ? " + " : "") + valorOtros : "")
    );
  };
  
  const handleCheckboxChange = (pregunta, respuesta) => {
    setRespuestasSeleccionadas((prevRespuestas) => {
      const nuevasRespuestas = {
        ...prevRespuestas,
        [pregunta]: {
          ...prevRespuestas[pregunta],
          [respuesta]: !prevRespuestas[pregunta]?.[respuesta],
        },
      };

      return nuevasRespuestas;
    });
  };

  const handleOtrosInputChange = (pregunta, e) => {
    const valor = e.target.value;
    setRespuestasSeleccionadas((prevRespuestas) => {
      const nuevasRespuestas = {
        ...prevRespuestas,
        [pregunta]: {
          ...prevRespuestas[pregunta],
          [t("Otros")]: valor, 

          
        },
      };

      // También actualiza el estado del input "Otros"
      setRespuestaOtros(valor);

      handleRespuestaSubmit(pregunta, valor);

      return nuevasRespuestas;
    });
  };

  useEffect(() => {
    // Llamar a handleRespuestaSubmit después de que el estado se haya actualizado
    handleRespuestaSubmit(preguntas[preguntas.length - 1]);
  }, [respuestasSeleccionadas]);

  return (
    <div>
      {preguntas && preguntas.map((pregunta, index) => (
        <div key={index}>
          <div style={estiloPregunta}>
            <b>{pregunta}</b>{aclaracion}
          </div>
          <table style={estiloTabla}>
            <tbody>
              <tr>
                <td style={estiloRespuestasContainer}>
                  {respuestasComunes.map((respuesta, respuestaIndex) => (
                    <label key={respuestaIndex} style={estiloRespuesta}>
                      <input
                        type="checkbox"
                        style={{ transform: "scale(1.2)", marginRight: "10px" }}
                        checked={respuestasSeleccionadas[pregunta][respuesta] || false}
                        onChange={() => handleCheckboxChange(pregunta, respuesta)}
                      />
                      {respuesta.split("\n").map((line, lineIndex) => (
                        <span key={lineIndex} style={estiloLinea}>
                          {line}
                        </span>
                      ))}
                    </label>
                  ))}
                  {respuestasSeleccionadas[pregunta][t("Otros")] && (
                    <div style={{ display: "flex", alignItems: "center", marginTop: "-0.5%" }}>
                      <input
                        type="text"
                        value={respuestaOtros}
                        onChange={(e) => handleOtrosInputChange(pregunta, e)}
                        placeholder={t("Especifique") + "..."}
                        style={{ width:"150px" }}
                      />
                    </div>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
          {index < preguntas.length - 1 && <div style={estiloSeparador}></div>}
        </div>
      ))}
    </div>
  );
}


export default CheckboxesMultiplesCuestionarioSD;
