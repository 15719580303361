import React from "react";
import { useContext, useEffect, useRef,useState } from "react";
import UserContext from "../../UserContext";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
//import { useTranslation } from "react-i18next";
import Card from "../../components/Card";
import "./VideoViewer.css"; // Reemplaza 'NombreDeTuArchivo' con el nombre real de tu archivo CSS
import ReactPlayer from "react-player";
import { FaPlay } from "react-icons/fa6";
import TituloyFlecha from "../../components/TituloyFlecha";


const VideoViewer = () => {
  const { VideoFileName } = useParams();
  const bearer = useContext(UserContext);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const VideoPath = process.env.PUBLIC_URL + "/Videos/" + VideoFileName;
  // const [t] = useTranslation("global");
  const { setEmail } = useContext(UserContext);
  const { setBearer } = useContext(UserContext);
  const navigate = useNavigate();

  const player = useRef();
  const [playing, setPlaying] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const ListaContenidos = [
    {
      nombre: "CuidandonosParaCuidar.pdf",
      nombreFront: "Cuidándonos para cuidar",
    },
    {
      nombre: "DerechosPersonasCuidadoras.pdf",
      nombreFront: "Los derechos de las personas cuidadoras",
    },
    {
      nombre: "ExplorandoMiAutocuidado.pdf",
      nombreFront: "Explorando mi autocuidado",
    },
    {
      nombre: "IdentificandoAlarmasAutocuidado.pdf",
      nombreFront: "Identificando los desafíos en mi autocuidado",

    },
    {
      nombre: "MaterialComplementarioAutocuidado.pdf",
      nombreFront: "Material complementario ",
    },
    {
      nombre: "PrincipiosIntegralesAutocuidado.pdf",
      nombreFront: "Principios Integrales del autocuidado",
    },
    {
      nombre: "SeñalesDificultadesAutocuidado.pdf",
      nombreFront: "Desafíos que dificultan el autocuidado",
    },
    {
      nombre: "CuidandonosParaCuidar.pdf",
      nombreFront: "Cuidándonos para cuidar",
    },
    {
      nombre: "DerechosPersonasCuidadoras.pdf",
      nombreFront: "Derechos de las personas cuidadoras",
    },
    {
      nombre: "ExplorandoMiAutocuidado.pdf",
      nombreFront: "Explorando mi autocuidado",
    },
    {
      nombre: "IdentificandoAlarmasAutocuidado.pdf",
      nombreFront: "Identificando los desafíos en mi autocuidado",
    },
    {
      nombre: "MaterialComplementarioAutocuidado.pdf",
      nombreFront: "Material complementario autocuidado",
    },
    {
      nombre: "PrincipiosIntegralesAutocuidado.pdf",
      nombreFront: "Principios Integrales del autocuidado",
    },
    {
      nombre: "SeñalesDificultadesAutocuidado.pdf",
      nombreFront: "Desafíos que dificultan el autocuidado",
    }
  ]
  const handlePlayPause = () => {
    setPlaying(!playing);
  };

  const handlePause = () => {
    setPlaying(false);
    setShowButton(true);
  };

  const handlePlay = () => {
    setShowButton(false);
  };
  const obtenerNombreFront = (nombreParametro) => {
    // Recorre la lista de contenidos
    for (let i = 0; i < ListaContenidos.length; i++) {
      // Comprueba si el nombre coincide con el nombreParametro
      if (ListaContenidos[i].nombre === nombreParametro) {
        // Devuelve el nombreFront correspondiente
        return ListaContenidos[i].nombreFront;
      }
    }
    // Si no se encuentra coincidencia, devuelve null o un valor predeterminado
    return null;
  }
  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  
  const retroceder = () => {
    navigate(-1);
  };

  return (
    <div>
  

      <TituloyFlecha
        title={obtenerNombreFront(VideoFileName)}
        link={"atras"}
      />

      <div className="react-player-container">
      <ReactPlayer
        ref={player}
        config={{
          file: {
            attributes: {
              controlsList: "nodownload", // Quita la opción de descargar
              crossOrigin: "anonymous"
              
            },
            tracks: [
              {
                kind: "subtitles",
                src:
                process.env.PUBLIC_URL +"/Videos/Subtitulos/SUBS_ESTRATEGIAS_DEL_CUIDADO.srt",
                srcLang: "español",
                default: false
              }
            ]
          }
        }}
        url={ process.env.PUBLIC_URL +"/Videos/Mi_Autocuidado_Planificado_NoEdit.mkv"}
        playing={playing}
        loop={false}
        muted={true}
        controls={true}
        onPause={handlePause}
        onPlay={handlePlay}
        width="75%" 
        height="55%"
        
      />
       {/* {showButton && !playing &&  windowWidth > 800 &&(
        <button className="play-buttonVideoViewer" onClick={handlePlayPause}>
         <FaPlay/>
        </button>
      )} */}
      </div>
    </div>
  );
};

export default VideoViewer;
