import { useEffect, useContext, useState } from "react";
import UserContext from "../../UserContext";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import PreguntaRespuestaSiNo from "../../components/Cuestionarios/PreguntaRespuestaSiNo";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import Card from "../../components/Card";
import TextoConCard from "../../components/Cuestionarios/TextoConCard";
import PreguntasColumnasIntercaladas from "../../components/Cuestionarios/PreguntasColumnasIntercaladas";
import TituloyFlecha from "../../components/TituloyFlecha";
import "./CuestionarioSocioDemo.css";
import CheckboxesCuestionarioSD from "../../components/Cuestionarios/CheckboxesCuestionarioSD";
const Cuestionario4 = () => {

  const navigate = useNavigate();
  const email = useContext(UserContext);
  const [pagina, sePagina] = useState(0);
  const [t] = useTranslation("global");
  const [todoRespondido, SetTodoRespondido] = useState(true);
  const numero_cuestionario = 4;
  const [respuestas1, setRespuestas1] = useState({});
  const [respuestas2, setRespuestas2] = useState({});
  const [respuestas, setRespuestas] = useState({});
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [navigate]);

  
  const preguntas = [
    t("Cuestionario4.Preguntas.1"),
    t("Cuestionario4.Preguntas.2"),
    t("Cuestionario4.Preguntas.3"),
    t("Cuestionario4.Preguntas.4"),
    t("Cuestionario4.Preguntas.5"),
    t("Cuestionario4.Preguntas.6"),
    t("Cuestionario4.Preguntas.7"),
    t("Cuestionario4.Preguntas.8"),
    t("Cuestionario4.Preguntas.9"),
    t("Cuestionario4.Preguntas.10"),
    t("Cuestionario4.Preguntas.11"),
    t("Cuestionario4.Preguntas.12"),
    t("Cuestionario4.Preguntas.13"),
  ];
  const preguntas1 = [
    t("Cuestionario4.Preguntas.1"),
    t("Cuestionario4.Preguntas.2"),
    t("Cuestionario4.Preguntas.3"),
    t("Cuestionario4.Preguntas.4"),
    t("Cuestionario4.Preguntas.5"),
    t("Cuestionario4.Preguntas.6"),
    t("Cuestionario4.Preguntas.7"),
  ];
  const preguntas2 = [
    t("Cuestionario4.Preguntas.8"),
    t("Cuestionario4.Preguntas.9"),
    t("Cuestionario4.Preguntas.10"),
    t("Cuestionario4.Preguntas.11"),
    t("Cuestionario4.Preguntas.12"),
    t("Cuestionario4.Preguntas.13"),
  ];

  const answerOptions = [
    t("Cuestionario4.Respuestas.Si"),
    t("Cuestionario4.Respuestas.No"),
  ];

  const handleRespuestasChange1 = (nuevasRespuestas) => {
    setRespuestas1(nuevasRespuestas);
    // Aquí puedes realizar cualquier otra acción con las respuestas
    console.log("Respuestas: " + nuevasRespuestas);
  };
  const handleRespuestasChange2 = (nuevasRespuestas) => {
    setRespuestas2(nuevasRespuestas);
    // Aquí puedes realizar cualquier otra acción con las respuestas
    console.log("Respuestas: " + nuevasRespuestas);
  };

  function paginar(valor) {
    SetTodoRespondido(true);
    if (valor > 0 && pagina < 6) {
      sePagina(pagina + valor);
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else if (valor < 0 && pagina > 0) {
      sePagina(pagina + valor);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }
  const mostrarRespuestasEnConsolaMovil = async () => {
    const respuestasEnTexto = preguntas
      .map((pregunta) => `"${respuestas[pregunta] || " "}"`)
      .join(",");
    const puntuacion =
      '"' +
      traducirArray(
        preguntas.map((pregunta) => `${respuestas[pregunta] || " "}`)
      ).join('","') +
      '"';
    console.log("Respuestas en texto:", respuestasEnTexto, puntuacion);
    const hayDosComillasSinValor = /""|\s*" "\s*|\[object Object\]/.test(
      respuestasEnTexto
    );

    if (hayDosComillasSinValor) {
      SetTodoRespondido(false);
    } else {
      SetTodoRespondido(true);
      try {
        const response = await axios.post(t("Recursos.ip") + "/respuesta", {
          numeroCuestionario: numero_cuestionario,
          arrayCuestionario: respuestasEnTexto,
          arrayPuntuacionCuestionario: puntuacion,
          email: email.email,
        });

        console.log(response.data);
        navigate("/Cuestionario/4/intentos");
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };
  // Función para mostrar las respuestas en la consola solo si hay respuestas
  const mostrarRespuestasEnConsola = async () => {
    try {
      const puntuacion =
        '"' +
        traducirArray(respuestas1).join('","') +
        '","' +
        traducirArray(respuestas2).join('","') +
        '"';

      const resultado =
        '"' + respuestas1.join('","') + '","' + respuestas2.join('","') + '"';

      console.log(resultado, puntuacion);
      const hayDosComillasSinValor = /""|\s*""\s*|\[object Object\]/.test(
        resultado
      );

      if (hayDosComillasSinValor) {
        SetTodoRespondido(false);
      } else {
        SetTodoRespondido(true);
        try {
          const response = await axios.post(t("Recursos.ip") + "/respuesta", {
            numeroCuestionario: numero_cuestionario,
            arrayCuestionario: resultado,
            arrayPuntuacionCuestionario: puntuacion,
            email: email.email,
          });

          console.log(response.data);
          navigate("/Cuestionario/4/intentos");
        } catch (error) {
          console.error("Error:", error);
        }
      }
    } catch (error) {
      SetTodoRespondido(false);
    }
  };
  const traducirArray = (array) => {
    return array.map((elemento) => traducirAValor(elemento));
  };
  const traducirAValor = (cadena) => {
    switch (cadena) {
      case t("Cuestionario4.Respuestas.Si"):
        return 1;
      case t("Cuestionario4.Respuestas.No"):
        return 0;
      default:
        return cadena; // Valor por defecto o manejo de error
    }
  };

  // Función para manejar las respuestas de cada instancia de PreguntaRespuesta
  const handleRespuestaSubmit = (pregunta, respuesta) => {
    // Actualizar el objeto de respuestas con la respuesta correspondiente
    setRespuestas((prevRespuestas) => ({
      ...prevRespuestas,
      [pregunta]: respuesta,
    }));
  };

  return (
    <div>
      <TituloyFlecha
        title={t("Cuestionario4.Titulo")}
        link={"/Cuestionario/4/intentos"}
      />

      <div style={{ marginTop: "2%", width: "80%", margin: "auto" }}>
        <p>{t("Cuestionario4.Intro")}</p>
      </div>
      <div
        className="cuestionarioContainer"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div style={{ display: pagina === 0 ? "block" : "none" }}>
        {windowWidth > 800 ? (
          <PreguntasColumnasIntercaladas
            questions={preguntas1}
            onRespuestasChange={handleRespuestasChange1}
            answerOptions={answerOptions}
          />  ) : (
            <CheckboxesCuestionarioSD
              preguntas={preguntas1}
              respuestasComunes={answerOptions}
              onRespuestaSubmit={handleRespuestaSubmit}
            />
          )}
        </div>
        <div style={{ display: pagina === 1 ? "block" : "none" }}>
        {windowWidth > 800 ? (
          <PreguntasColumnasIntercaladas
            questions={preguntas2}
            onRespuestasChange={handleRespuestasChange2}
            answerOptions={answerOptions}
          />  ) : (
            <CheckboxesCuestionarioSD
              preguntas={preguntas2}
              respuestasComunes={answerOptions}
              onRespuestaSubmit={handleRespuestaSubmit}
            />
          )}
        </div>

        {!todoRespondido && (
          <div>
            <br />
            <p style={{ color: "red" }}>{t("Responde-todo")}</p>
          </div>
        )}
        <div style={{ display: "flex" }}>
          <button
            onClick={() => paginar(-1)}
            style={{
              marginTop: "30px",
              marginRight: window.innerWidth <= 768 ? "10%" : "-25%",
              width: window.innerWidth <= 768 ? "50%" : "20%",
              // visibility: pagina === 0 ? "hidden" : "visible",
              background: pagina === 0 ? " #A5BBC1" : "",
            }}
            className="link-button"
          >
            {t("Atras")}
          </button>

          {pagina < 1 && (
            <button
              onClick={() => paginar(1)}
              style={{
                marginTop: "30px",
                marginLeftt: window.innerWidth <= 768 ? "-10%" : "25%",
                width: window.innerWidth <= 768 ? "50%" : "20%",
                visibility: pagina === 1 ? "hidden" : "visible",
              }}
              className="link-button"
            >
              {t("Continuar")}
            </button>
          )}
          {pagina === 1 && (
           <>
           {windowWidth > 800 ? (
             <button
               onClick={mostrarRespuestasEnConsola}
               className="link-button"
               style={{
                 marginTop: "30px",
                 width: window.innerWidth <= 768 ? "50%" : "20%",
               }}
             >
               {t("Confirmar")}
             </button>
           ) : (
             <button
               onClick={mostrarRespuestasEnConsolaMovil}
               className="link-button"
               style={{
                 marginTop: "30px",
                 width: window.innerWidth <= 768 ? "50%" : "20%",
               }}
             >
               {t("Confirmar")}
             </button>
           )}
         </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Cuestionario4;
