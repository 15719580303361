import React from "react";
import { useEffect, useContext} from "react";
import UserContext from "../../UserContext";
import "./PreguntaAnadirInfoPersonal.css";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { useTranslation } from "react-i18next";
const PreguntaAnadirInfoPersonal = () => {
  const { setEmail } = useContext(UserContext);
  const { setBearer } = useContext(UserContext);
  const bearer = useContext(UserContext);
  const navigate = useNavigate();
  const email = useContext(UserContext);
  const [t] = useTranslation("global");


  
  // Función para manejar el clic en los botones
  const handleClick = async (opcion) => {
    if (opcion === "masTarde") {
      // Lógica para "Prefiero hacerlo más tarde"
      try {
        const response2 = await axios.get(
          t("Recursos.ip") + "/estadoanimo/cubiertohoy",
          {
            params: {
              email: email.email
            }
          }
        );
        if (response2.data !== true) {
          //si no lo ha cubierto al formulario estado animo

          navigate("/CuestionarioEstadoAnimo");
        } else {
          //si lo ha cubierto al home
          navigate("/");
        }
      } catch (error) {
        console.error("Error al obtener datos:", error);
        // Manejar el error según tus necesidades
      }
    } else if (opcion === "informacionPersonal") {
      // Lógica para "Sí, quiero ir a información personal detallada"

      navigate("/CuestionarioSocioDemo");
    }
  };



  return (
    <div>
      <div className="container">
        <div className="titulo">{t("PreguntaAñadirInfoPersonal.Proporcionar-info")}</div>
        <div className="subtitulo">
          {t("PreguntaAñadirInfoPersonal.Frase-explicativa")}
        </div>
        <div className="botones">
          <button className="botoncirc" onClick={() => handleClick("masTarde")}>
            {t("PreguntaAñadirInfoPersonal.mas-tarde")}
          </button>
          <button className="botoncirc" style={{ marginLeft: "5%" }} onClick={() => handleClick("informacionPersonal")}>
            {t("PreguntaAñadirInfoPersonal.si-dar-info")}
          </button>
        </div>
      </div>

    </div>

  );
};

export default PreguntaAnadirInfoPersonal;
