import React, { useEffect, useContext, useState } from "react";
import UserContext from "../../UserContext";
import Card from "../../components/Card";
import { useTranslation } from "react-i18next";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import axios from "axios";
import "./ListaCuestionario.css";
import AgrupacionCuestionarios from "../../components/AgrupacionCuestionarios";
import { FaCircle } from "react-icons/fa";
import TituloyFlecha from "../../components/TituloyFlecha";



const ListaCuestionario = () => {
  const [t] = useTranslation("global");
  const email = useContext(UserContext);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [una, setUna] = useState(false);
  const [arrayRespuestas, setArrayRespuestas] = useState(false);
  const [contenidos, setContenidos] = useState(null);
  const [textoIntroduccion, setTextoIntroduccion] = useState(null);


  const cuestionarios = [

    {
      nombre: "EQ5D5L.pdf",
      contenido: "presentacion",
      pagina: 1,
    },
    {
      nombre: "CareQoL.pdf",
      contenido: "presentacion",
      pagina: 1,
    },
    {
      nombre: "Zarit.pdf",
      contenido: "infografia",
      pagina: 1,
    },
    {
      nombre: "EsfuerzoCuidador.pdf",
      contenido: "infografia",
      pagina: 1,
    },
    {
      nombre: "EquilibrioOcupacional.pdf",
      contenido: "tarea",
      pagina: 1,
    },
    {
      nombre: "Empoderamiento.pdf",
      contenido: "material_complementario",
      pagina: 1,
    },
    {
      nombre: "Satisfaccion.pdf",
      contenido: "video",
      pagina: 1,
    },
  ];

  useEffect(() => {
    // Verificar si el correo electrónico no es nulo antes de llamar a la función
    if ((email.email)) {

      setContenidos(cuestionarios);
      setTextoIntroduccion(t("MeCuido.TextoIntroduccion.Tema1"));

    }
  }, [email]);

  const asignarPaginas = (contenidos) => {
    const contenidosConPagina = contenidos.map((contenido, index) => {
      const pagina = Math.floor(index / 8);
      return { ...contenido, pagina };
    });
    return contenidosConPagina;
  };

  useEffect(() => {
    // Verificar si el correo electrónico no es nulo antes de llamar a la función
    if (email.email !== null && !una) {
      setUna(true);
      consulta_cuestionarios();
    }
  }, [email, una]);

  useEffect(() => {
    // Escucha el evento de cambio de tamaño de la ventana y actualiza el ancho de la ventana
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      // Limpia el evento de cambio de tamaño al desmontar el componente
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const consulta_cuestionarios = async () => {
    const currentDate = new Date();
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(currentDate.getDate() - 60);
    const formattedCurrentDate = currentDate.toISOString().split("T")[0];
    const formattedOneWeekAgo = oneWeekAgo.toISOString().split("T")[0];

    try {
      const response_durancion = await axios.get(
        t("Recursos.ip") + "/respuesta/cuestionarioscubiertos",
        {
          params: {
            email: email.email,
            startTime: formattedOneWeekAgo,
            endTime: formattedCurrentDate,
          },
        }
      );
      //Console.log(response_durancion.data);
      setArrayRespuestas(response_durancion.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div>

      <TituloyFlecha
        title={t("Evaluación")}
        link={"/"}
      />

      {window.innerWidth > 800 ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
            margin: "auto",
            maxWidth: "70%",
            minWidth: "70%",
            margin: "auto"
          }}
        >
          <h3
            className="tituloAgrupacionContenidos">
             {t("evaluacionIntro")}
          </h3>

          <div className="contenedorLeyenda">
            <div style={{ display: "flex", flexDirection: "row" }}>
              <FaCircle style={{ color: "#518ea6", marginRight: "2%" }} />
              <p style={{ justifyText: "left" }}>{t("ListaCuetionarios.Titulo1")}</p>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <FaCircle style={{ color: "#7fad96", marginRight: "2%" }} />
              <p style={{ justifyText: "left" }}>{t("ListaCuetionarios.Titulo2")}</p>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <FaCircle style={{ color: "#7063bf", marginRight: "2%" }} />
              <p style={{ justifyText: "left" }}> {t("ListaCuetionarios.Titulo3")}</p>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <FaCircle style={{ color: "#d19451", marginRight: "2%" }} />

              <p style={{ justifyText: "left" }}>{t("ListaCuetionarios.Titulo4")}</p>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <FaCircle style={{ color: "#ef9a9a", marginRight: "20px" }} />
              <p style={{ justifyText: "left" }}>{t("ListaCuetionarios.Titulo5")}</p>
            </div>

          </div>

          <AgrupacionCuestionarios
            contenidos={contenidos}
            tema="Recomendados"
            titulosPagina="Evaluación"
          />

        </div>
      ) : (
        <div style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          maxWidth: "80%",
          minWidth: "80%",
          margin: "auto"
        }}>

          <div className="contenedorLeyenda" style={{ flexDirection: "column", width: "100%" }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <FaCircle style={{ color: "#518ea6", marginRight: "2%" }} />
              <p style={{ justifyText: "left" }}>{t("ListaCuetionarios.Titulo1")}</p>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <FaCircle style={{ color: "#7063bf", marginRight: "2%" }} />
              <p style={{ justifyText: "left" }}>{t("ListaCuetionarios.Titulo2")}</p>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <FaCircle style={{ color: "#7fad96", marginRight: "2%" }} />
              <p style={{ justifyText: "left" }}> {t("ListaCuetionarios.Titulo3")}</p>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <FaCircle style={{ color: "#d19451", marginRight: "2%" }} />

              <p style={{ justifyText: "left" }}>{t("ListaCuetionarios.Titulo4")}</p>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <FaCircle style={{ color: "#ef9a9a", marginRight: "2%" }} />
              <p style={{ justifyText: "left" }}>{t("ListaCuetionarios.Titulo5")}</p>
            </div>

          </div>

          <AgrupacionCuestionarios
            contenidos={contenidos}
            tema="Recomendados"
            titulosPagina="Evaluación"
          />



        </div>
      )
      }

    </div>
  );
};

export default ListaCuestionario;
