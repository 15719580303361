import { useEffect, useContext, useState } from "react";
import UserContext from "../../UserContext";
import { useNavigate } from "react-router-dom";
import CheckboxesCuestionarioSD from "../../components/Cuestionarios/CheckboxesCuestionarioSD";
import CheckboxesMultiplesCuestionarioSD from "../../components/Cuestionarios/CheckboxesMultiplesCuestionarioSD";
import PreguntaRespuesta from "../../components/Cuestionarios/PreguntaRespuesta";
import axios from "axios";
import { useTranslation } from "react-i18next";
import "./CuestionarioSocioDemo.css";
import { useForm, Controller } from "react-hook-form";

const CuestionarioSocioDemo = () => {
  const email = useContext(UserContext);
  const navigate = useNavigate();
  const [t] = useTranslation("global");
  const [cadenallena, setCadenallena] = useState(null);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [navigate]);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  const preguntas = [
    t("cuestionarioSocioDemoyPersonal.preg1"),
    t("cuestionarioSocioDemoyPersonal.preg2"),
    t("cuestionarioSocioDemoyPersonal.preg3"),
    t("cuestionarioSocioDemoyPersonal.preg4"),
    t("cuestionarioSocioDemoyPersonal.preg5"),
    t("cuestionarioSocioDemoyPersonal.preg6"),
    t("cuestionarioSocioDemoyPersonal.preg7"),
    t("cuestionarioSocioDemoyPersonal.preg8"),
    t("cuestionarioSocioDemoyPersonal.preg9"),
    t("cuestionarioSocioDemoyPersonal.preg11"),
  ];

  const numero_cuestionario = 0;

  // Utilizar un objeto para almacenar las respuestas
  const [respuestas, setRespuestas] = useState({});

  // Función para manejar las respuestas de cada instancia de PreguntaRespuesta
  const handleRespuestaSubmit = (pregunta, respuesta) => {
    // Actualizar el objeto de respuestas con la respuesta correspondiente
    setRespuestas((prevRespuestas) => ({
      ...prevRespuestas,
      [pregunta]: respuesta,
    }));
  };

  const [userData, setUserData] = useState(null);
  useEffect(() => {
    if (email.email) {
      console.log("Email", email.email);
      const fetchUserData = async () => {
        try {
          const response = await axios.get(
            t("Recursos.ip") + "/respuesta/intento/ultimo",
            {
              params: {
                email: email.email,
                numerocuestionario: 0,
              },
            }
          );
          console.log(response.data);
          setUserData(response.data);

          // Inicializar el valor del control fechaNacimiento si userData[8] no es nulo ni vacío
          if (response.data[9]) {
            setValue("fechaNacimiento", response.data[9]);
          }
        } catch (error) {
          console.error("Error al obtener datos del usuario:", error);
        }
      };

      // Llama a la función para obtener los datos cuando el componente se monta
      fetchUserData();
    }
  }, [email]);

  useEffect(() => {
    if (email.email) {
      console.log("Email", email.email);
      const fetchUserData = async () => {
        try {
          const response = await axios.get(
            t("Recursos.ip") + "/respuesta/intento/ultimo",
            {
              params: {
                email: email.email,
                numerocuestionario: 0,
              },
            }
          );
          console.log(response.data);
          setUserData(response.data);

          // Deja que react-hook-form maneje la inicialización de los valores
        } catch (error) {
          console.error("Error al obtener datos del usuario:", error);
        }
      };

      // Llama a la función para obtener los datos cuando el componente se monta
      fetchUserData();
    }
  }, [email]);

  function verificarCadenas(cadena) {
    // Dividir la cadena en un array utilizando la coma como separador
    const partes = cadena.split(",");

    // Iterar sobre cada parte de la cadena
    for (const parte of partes) {
      // Eliminar las comillas y espacios en blanco alrededor de la palabra
      const palabra = parte.trim().replace(/^"(.*)"$/, "$1");

      // Verificar si la palabra está vacía
      if (palabra.length === 0) {
        setCadenallena(false);
        return false; // Si hay alguna palabra vacía, retornar falso
      }
    }
    setCadenallena(true);
    // Si todas las palabras tienen contenido, retornar verdadero
    return true;
  }

  // Función para mostrar todas las respuestas en la consola
  const enviarRespuestas = async () => {
    // Incluir el año de nacimiento en el objeto de respuestas
    const fechaNacimiento = getValues("fechaNacimiento");
    const miArrayRespuestas = preguntas.map((pregunta) => ({
      clave: pregunta,
      valor: respuestas[pregunta] || "",
    }));
    console.log("Respuestas", miArrayRespuestas);
    // Insertar la respuesta de fecha de nacimiento en la penúltima posición del array
    miArrayRespuestas.splice(-1, 0, {
      clave: t("Miperfil.Fecha-Nacimiento"),
      valor: fechaNacimiento,
    });
    console.log("Respuestas2", miArrayRespuestas);
    const primeraParteResultado =
      '"' + miArrayRespuestas.map((item) => item.valor).join('","') + '"';
    const resultadoanterior = miArrayRespuestas
      .map((item) => item.valor)
      .join(",");
    const puntuacion =
      '"' +
      traducirArray(miArrayRespuestas.map((item) => item.valor)).join('","') +
      '"';

    console.log("Iguales", primeraParteResultado,userData.toString() === resultadoanterior);
    if (verificarCadenas(primeraParteResultado)) {
      if (userData.toString() === resultadoanterior) {
        navigate("/CuestionarioSocioDemo1");
      } else {
        try {
          const response1 = await axios.post(t("Recursos.ip") + "/respuesta", {
            numeroCuestionario: numero_cuestionario,
            arrayCuestionario: primeraParteResultado,
            arrayPuntuacionCuestionario: puntuacion,
            email: email.email,
          });

          navigate("/CuestionarioSocioDemo1");
        } catch (error) {
          console.error("Error:", error);
        }
      }
    }
  };

  const traducirArray = (array) => {
    return array.map((elemento) => 0);
  };
  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 100; // Puedes ajustar este rango según tus necesidades
    const years = [];

    for (let year = currentYear; year >= startYear; year--) {
      years.push(year);
    }

    return years;
  };

  return (
    <div>
      <h1 style={{ marginTop: "1%" }}>{t("Informacion-personal-detallada")}</h1>
      {userData ? (
        <div className="cuestionarioContainer" style={{ marginTop: "1%" }}>
          <br />
          <p
            style={{
              color: "#518EA6",
              fontWeight: "bold",
              fontSize: "1.5em",
              textAlign: "left",
              marginLeft: "5%",
            }}
          >
            {t("cuestionarioSocioDemoyPersonal.Datos-generales")}
          </p>
          <CheckboxesCuestionarioSD
            preguntas={[preguntas[0]]}
            respuestasComunes={[
              t("cuestionarioSocioDemoyPersonal.RespuestasGenerales.1.1"),
              t("cuestionarioSocioDemoyPersonal.RespuestasGenerales.1.2"),
              t("cuestionarioSocioDemoyPersonal.RespuestasGenerales.1.3"),
              t("cuestionarioSocioDemoyPersonal.RespuestasGenerales.1.4"),
              t("cuestionarioSocioDemoyPersonal.RespuestasGenerales.1.5"),
              t("cuestionarioSocioDemoyPersonal.RespuestasGenerales.1.6"),
            ]}
            onRespuestaSubmit={handleRespuestaSubmit}
            respuestasprevias={[userData[0]]}
          />
          <CheckboxesCuestionarioSD
            preguntas={[preguntas[1]]}
            respuestasComunes={[
              t("cuestionarioSocioDemoyPersonal.RespuestasGenerales.2.1"),
              t("cuestionarioSocioDemoyPersonal.RespuestasGenerales.2.2"),
              t("cuestionarioSocioDemoyPersonal.RespuestasGenerales.2.3"),
              t("cuestionarioSocioDemoyPersonal.RespuestasGenerales.2.4"),
              t("cuestionarioSocioDemoyPersonal.RespuestasGenerales.2.5"),
              t("cuestionarioSocioDemoyPersonal.RespuestasGenerales.2.6"),
            ]}
            onRespuestaSubmit={handleRespuestaSubmit}
            respuestasprevias={[userData[1]]}
          />
          <CheckboxesCuestionarioSD
            preguntas={[preguntas[2]]}
            respuestasComunes={[
              t("cuestionarioSocioDemoyPersonal.RespuestasGenerales.3.1"),
              t("cuestionarioSocioDemoyPersonal.RespuestasGenerales.3.2"),
              t("cuestionarioSocioDemoyPersonal.RespuestasGenerales.3.3"),
            ]}
            onRespuestaSubmit={handleRespuestaSubmit}
            respuestasprevias={[userData[2]]}
          />
          <CheckboxesMultiplesCuestionarioSD
            preguntas={[preguntas[3]]}
            respuestasComunes={[
              t("cuestionarioSocioDemoyPersonal.RespuestasGenerales.4.1"),
              t("cuestionarioSocioDemoyPersonal.RespuestasGenerales.4.2"),
              t("cuestionarioSocioDemoyPersonal.RespuestasGenerales.4.3"),
              t("cuestionarioSocioDemoyPersonal.RespuestasGenerales.4.4"),
              t("cuestionarioSocioDemoyPersonal.RespuestasGenerales.4.5"),
              t("cuestionarioSocioDemoyPersonal.RespuestasGenerales.4.6"),
              t("cuestionarioSocioDemoyPersonal.RespuestasGenerales.4.7"),
              t("cuestionarioSocioDemoyPersonal.RespuestasGenerales.4.8"),
            ]}
            aclaracion={t("cuestionarioSocioDemoyPersonal.aclaracion4")}
            onRespuestaSubmit={handleRespuestaSubmit}
            respuestasprevias={[userData[3]]}
          />
          <CheckboxesCuestionarioSD
            preguntas={[preguntas[4]]}
            respuestasComunes={[
              t("cuestionarioSocioDemoyPersonal.RespuestasGenerales.5.1"),
              t("cuestionarioSocioDemoyPersonal.RespuestasGenerales.5.2"),
              t("cuestionarioSocioDemoyPersonal.RespuestasGenerales.5.3"),
              t("cuestionarioSocioDemoyPersonal.RespuestasGenerales.5.4"),
              t("cuestionarioSocioDemoyPersonal.RespuestasGenerales.5.5"),
              t("cuestionarioSocioDemoyPersonal.RespuestasGenerales.5.6"),
            ]}
            onRespuestaSubmit={handleRespuestaSubmit}
            respuestasprevias={[userData[4]]}
          />
          <CheckboxesCuestionarioSD
            preguntas={[preguntas[5]]}
            respuestasComunes={[
              t("cuestionarioSocioDemoyPersonal.RespuestasGenerales.6.1"),
              t("cuestionarioSocioDemoyPersonal.RespuestasGenerales.6.2"),
            ]}
            onRespuestaSubmit={handleRespuestaSubmit}
            respuestasprevias={[userData[5]]}
          />
          <CheckboxesCuestionarioSD
            preguntas={[preguntas[6]]}
            respuestasComunes={[
              t("cuestionarioSocioDemoyPersonal.RespuestasGenerales.7.1"),
              t("cuestionarioSocioDemoyPersonal.RespuestasGenerales.7.2"),
              t("cuestionarioSocioDemoyPersonal.RespuestasGenerales.7.3"),
              t("cuestionarioSocioDemoyPersonal.RespuestasGenerales.7.4"),
              t("cuestionarioSocioDemoyPersonal.RespuestasGenerales.7.5"),
              t("cuestionarioSocioDemoyPersonal.RespuestasGenerales.7.6"),
              t("cuestionarioSocioDemoyPersonal.RespuestasGenerales.7.7"),
              t("cuestionarioSocioDemoyPersonal.RespuestasGenerales.7.8"),
            ]}
            onRespuestaSubmit={handleRespuestaSubmit}
            respuestasprevias={[userData[6]]}
          />
          <CheckboxesMultiplesCuestionarioSD
            preguntas={[preguntas[7]]}
            respuestasComunes={[
              t("cuestionarioSocioDemoyPersonal.RespuestasGenerales.8.1"),
              t("cuestionarioSocioDemoyPersonal.RespuestasGenerales.8.2"),
              t("cuestionarioSocioDemoyPersonal.RespuestasGenerales.8.3"),
              t("cuestionarioSocioDemoyPersonal.RespuestasGenerales.8.4"),
              t("cuestionarioSocioDemoyPersonal.RespuestasGenerales.8.5"),
              t("cuestionarioSocioDemoyPersonal.RespuestasGenerales.8.6"),
              t("cuestionarioSocioDemoyPersonal.RespuestasGenerales.8.7"),
              t("cuestionarioSocioDemoyPersonal.RespuestasGenerales.8.8"),
            ]}
            onRespuestaSubmit={handleRespuestaSubmit}
            respuestasprevias={[userData[7]]}
          />

          <PreguntaRespuesta
            pregunta={[t("cuestionarioSocioDemoyPersonal.preg9")]}
            onRespuestaSubmit={handleRespuestaSubmit}
            placeholder={t("Diagnostico")}
            respuestaprevia={[userData[8]]}
            ancho="20%"
          />

          <div className="formulario">
            <p
              style={{
                fontSize: "20px",
                whiteSpace: "pre-line",
                textAlign: "justify",
                marginLeft: "5%",
              }}
            >
              <b>{t("cuestionarioSocioDemoyPersonal.preg10")}</b>
            </p>
            <Controller
              name="fechaNacimiento"
              control={control}
              render={({ field }) => (
                <div
                  style={{
                    marginLeft: "7%",
                    fontSize: "16px",

                    textAlign: "justify",
                    display: "flex",
                  }}
                >
                  <select
                    style={{ width: "150px" }}
                    {...field}
                    value={
                      field.value
                        ? new Date(field.value).getFullYear().toString()
                        : userData && userData[9] // Verificar si userData tiene un valor y si userData[9] está definido
                        ? userData[9]
                        : ""
                    }
                    onChange={(e) => field.onChange(e.target.value)}
                  >
                    <option value="" disabled hidden>
                      {t("Miperfil.Selecciona")}...
                    </option>
                    {generateYearOptions().map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>

                  {errors.fechaNacimiento && (
                    <p className="error-message">
                      {errors.fechaNacimiento.message}
                    </p>
                  )}
                </div>
              )}
              rules={{ required: t("Miperfil.CampoObligatorio") }}
            />
          </div>
          <CheckboxesMultiplesCuestionarioSD
            preguntas={[preguntas[9]]}
            respuestasComunes={[
              t("cuestionarioSocioDemoyPersonal.RespuestasGenerales.11.1"),
              t("cuestionarioSocioDemoyPersonal.RespuestasGenerales.11.2"),
              t("cuestionarioSocioDemoyPersonal.RespuestasGenerales.11.3"),
              t("cuestionarioSocioDemoyPersonal.RespuestasGenerales.11.4"),
              t("cuestionarioSocioDemoyPersonal.RespuestasGenerales.11.5"),
              t("cuestionarioSocioDemoyPersonal.RespuestasGenerales.11.6"),
            ]}
            aclaracion={t("cuestionarioSocioDemoyPersonal.aclaracion11")}
            onRespuestaSubmit={handleRespuestaSubmit}
            respuestasprevias={[userData[10]]}
          />
          {cadenallena === false && (
            <p style={{ color: "red" }}>{t("Responde-todo")}</p>
          )}
          <button
            className="botoncirc"
            style={{
              width: window.innerWidth <= 768 ? "47%" : "10%",
              marginTop: "0%",
            }}
            onClick={enviarRespuestas}
          >
            {t("Continuar")}
          </button>
        </div>
      ) : (
        <div style={{ width: "100%", marginTop: "10%", marginRight: "15%" }}>
          <b>{t("Conectando-BD")}</b>
        </div>
      )}
    </div>
  );
};

export default CuestionarioSocioDemo;
