import React, { useState, useEffect } from "react";

function Pregunta2Respuesta({
  pregunta,
  onRespuestaSubmit,
  placeholder,
  placeholder2,
  respuestaprevia,
  aclaracion,
}) {
  const [respuesta, setRespuesta] = useState(
    separarHorasMinutos(respuestaprevia).horas
  );
  const [respuesta2, setRespuesta2] = useState(
    separarHorasMinutos(respuestaprevia).minutos
  );

  useEffect(() => {
    onRespuestaSubmit(pregunta, respuestaprevia);
  }, []);

  const handleInputChange = (event) => {
    const nuevaRespuesta = event.target.value;

    if (nuevaRespuesta === "") {
      setRespuesta("");
      onRespuestaSubmit(pregunta, 0);
      return;
    }

    const respuestaNumerica = /^\d+$/.test(nuevaRespuesta) ? parseInt(nuevaRespuesta, 10) : 0;
    const minutos = respuestaNumerica * 60 + parseInt(respuesta2, 10);
    onRespuestaSubmit(pregunta, minutos);
    setRespuesta(nuevaRespuesta);
  };

  const handleInputChange2 = (event) => {
    const nuevaRespuesta2 = event.target.value;

    if (nuevaRespuesta2 === "") {
      setRespuesta2("");
      onRespuestaSubmit(pregunta, 0);
      return;
    }

    const minutos = parseInt(respuesta, 10) * 60 + parseInt(nuevaRespuesta2, 10);
    if (isNaN(nuevaRespuesta2) || isNaN(minutos)) {
      setRespuesta2("0");
      onRespuestaSubmit(pregunta, 0);
    } else {
      setRespuesta2(nuevaRespuesta2);
      onRespuestaSubmit(pregunta, minutos);
    }
  };

  const estiloPregunta = {
    fontSize: "20px",
    whiteSpace: "pre-line",
    textAlign: "justify",
    marginLeft: "5%",
  };

  const estiloRespuestasContainer = {
    padding: "5px",
    marginLeft: "7.5%",
    marginBottom: "5px",
    width: "100px",
    marginRight: "15px",
    display: "flex",
    alignItems: "center",
  };

  function separarHorasMinutos(minutosString) {
    if (minutosString == null || minutosString.trim() === "") {
      return {
        horas: "",
        minutos: "",
      };
    }
    const minutos = parseInt(minutosString, 10);
    if (isNaN(minutos)) {
      return {
        horas: "",
        minutos: "",
      };
    }
    const horas = Math.floor(minutos / 60);
    const minutosRestantes = minutos % 60;
    return {
      horas: horas.toString(),
      minutos: minutosRestantes.toString().padStart(2, "0"),
    };
  }

  return (
    <div>
      <br />
      <p style={estiloPregunta}>
        <b>{pregunta}</b>
        {aclaracion}
      </p>
      <div style={{ display: "flex" }}>
        <input
          style={estiloRespuestasContainer}
          type="text"
          value={respuesta}
          onChange={handleInputChange}
          placeholder={placeholder}
        />
        <input
          style={{ ...estiloRespuestasContainer, marginLeft: "0%" }}
          type="text"
          value={respuesta2}
          onChange={handleInputChange2}
          placeholder={placeholder2}
        />
      </div>
    </div>
  );
}

export default Pregunta2Respuesta;
