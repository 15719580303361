import React, { useState } from "react";
import "./PreguntaRespuestaCuadrados.css"


function PreguntasRespuestasCheckboxesHorizontal({
  preguntas,
  respuestasComunes,
  onRespuestaSubmit,
}) {
  const [respuestasSeleccionadas, setRespuestasSeleccionadas] = useState({});

  const handleRespuestaSubmit = (pregunta, respuesta) => {
    setRespuestasSeleccionadas({
      ...respuestasSeleccionadas,
      [pregunta]: respuesta,
    });
    onRespuestaSubmit(pregunta, respuesta);
  };

  const estiloRespuesta = {
    padding: "5px", // Reduce el espacio interior
    marginBottom: "5px", // Agrega espacio entre respuestas
    marginRight: "15px", // Agrega espacio horizontal entre respuestas
  };

  const estiloCelda = {
    textAlign: "center", // Centra el contenido de la celda
    padding: "5px", // Reduce el espacio interior
  };

  const estiloTabla = {
    width: "100%",
    margin: "0 auto",
  };

  const estiloPregunta = {
    fontSize: "20px", // Define el tamaño de la fuente más pequeño
    whiteSpace: "pre-line", // Permite saltos de línea
  };

  const estiloRespuestasContainer = {
    marginLeft: "5px", // Reduce el margen izquierdo para acercar las respuestas
  };

  const estiloSeparador = {
    // borderBottom: "1px solid #ccc", // Agrega una línea de separación
    margin: "15px 0", // Espacio entre las preguntas
  };
  const estiloLinea = {
    margin: "2px 0", // Reducir el espacio entre líneas
  };

  return (
    <div>
      {preguntas &&
        preguntas.map((pregunta, index) => (
          <div key={index}>
            <div style={estiloPregunta}><b>{pregunta}</b></div>
            <table style={estiloTabla}>
              <tbody>
                <tr>
                  <td style={estiloRespuestasContainer}>
                    {respuestasComunes.map((respuesta, respuestaIndex) => (
                      <label key={respuestaIndex} style={estiloRespuesta}>
                        <label class="checkbox">
                          <input
                            type="checkbox"
                            class="checkbox__input"
                            checked={
                              respuesta === respuestasSeleccionadas[pregunta]
                            }
                            onChange={() =>
                              handleRespuestaSubmit(pregunta, respuesta)
                            }
                          />
                          <span class="checkbox__inner"></span> 
                        </label>

                        {respuesta.split("\n").map((line, lineIndex) => (
                          <p key={lineIndex} style={estiloLinea}>
                            {line}
                          </p>
                        ))}
                      </label>
                    ))}
                  </td>
                </tr>
              </tbody>
            </table>
            {index < preguntas.length - 1 && (
              <div style={estiloSeparador}></div>
            )}
          </div>
        ))}
    </div>
  );
}
export default PreguntasRespuestasCheckboxesHorizontal;
