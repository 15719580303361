import React from 'react';
import Diario_Semanal_Icono from "../../Images/Diario_Semanal_Icono.png";
import './MiDiarioSemanalCarta.css';
import Card from '../Card';
import { useTranslation } from "react-i18next";
function MiDiarioSemanalCarta() {
  const [t] = useTranslation("global");
  return (
    <div style={{ fontSize: "2vh" }}>
      <div style={{ width: "30vh", margin: "0 auto", marginTop: "-2.5vh" }}>
        <Card content={<h1 style={{
          fontSize: "3vh",
          marginTop: "0.5vh",
          color: "white"
        }}>{t("Mi-Diario-Semanal")}</h1>}
          alto="50%"
          ancho="100%" className={"cardMiDiarioSemanalCarta"} />
      </div>
      <div style={{ marginTop: "0.5vh" }}>
        {/* Texto en la parte superior */}
        <p style={{ color: "black" }}>{t("Mi-Diario-Semanal-texto")}</p>

        <img style={{ marginBottom: "1vh"}} src={Diario_Semanal_Icono} alt={t("Mi-Diario-Semanal-Icono")} className="imagenMiDiarioSemanalCarta" />
      </div>
    </div>
  )
}


export default MiDiarioSemanalCarta;