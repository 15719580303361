import { useEffect, useContext, useState } from "react";
import UserContext from "../../UserContext";
import Card from "../../components/Card";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import "./Mecuido.css";
import axios from "axios";
import AgrupacionContenidos from "../../components/AgrupacionContenidos";
import PaginacionTemas from "../../components/PaginacionTemas";
import { useTranslation } from "react-i18next";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { FaStar } from "react-icons/fa";
import { TfiArrowCircleLeft } from "react-icons/tfi";
import { TfiArrowCircleRight } from "react-icons/tfi";
import TituloyFlecha from "../../components/TituloyFlecha";

const Mecuido = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [t] = useTranslation("global");
  const { temaSeleccionado } = useParams();
  const email = useContext(UserContext);
  const [contenidos, setContenidos] = useState(null);
  const [textoIntroduccion, setTextoIntroduccion] = useState(null);
  const [tema, setTema] = useState("Recomendados");
  const [titulo, setTitulo] = useState(null);
  const location = useLocation();
  const [anterior, setAnterior] = useState(null);
  const [siguiente, setSiguiente] = useState(null);
  const navigate = useNavigate();
  const titulosRecomendados = [t("MeCuido.TextoIntroduccion.Recomendados")];
  const titulosMiautocuidadoConsciente = [
    t("MeCuido.TextoIntroduccion.Tema1"),
    "Principios, señales y derechos relacionados con el cuidado ",
  ];
  const MiAutocuidadoConsciente = [
    {
      nombre: "CuidandonosParaCuidar.pdf",
      nombreFront: "Cuidándonos para cuidar",
      contenido: "presentacion",
      pagina: 1,
    },
    {
      nombre: "SeñalesDificultadesAutocuidado.pdf",
      nombreFront: "Desafíos que dificultan el autocuidado",
      contenido: "infografia",
      pagina: 1,
    },
    {
      nombre: "DerechosPersonasCuidadoras.pdf",
      nombreFront: "Los derechos de las personas cuidadoras",
      contenido: "infografia",
      pagina: 1,
    },

    {
      nombre: "PrincipiosIntegralesAutocuidado.pdf",
      nombreFront: "Principios Integrales del autocuidado",
      contenido: "infografia",
      pagina: 1,
    },

    {
      nombre: "ExplorandoMiAutocuidado.pdf",
      nombreFront: "Explorando mi autocuidado",
      contenido: "tarea",
      pagina: 1,
    },
    {
      nombre: "IdentificandoAlarmasAutocuidado.pdf",
      nombreFront: "Identificando los desafíos en mi autocuidado",
      contenido: "tarea",
      pagina: 1,
    },
    {
      nombre: "MaterialComplementarioAutocuidado.pdf",
      nombreFront: "Material complementario ",
      contenido: "material_complementario",
      pagina: 1,
    },
  ];
  const titulosMiautocuidadoSaludable = [
    t("MeCuido.TextoIntroduccion.Tema2"),
    "Recomendaciones generales para llevar una vida saludable",
    "Movimiento y salud: claves para el bienestar",
    "Sueño saludable: estrategias de higiene y rutina del sueño",
    "Mis actividades significativas: transcendiendo a lo cotidiano",
  ];
  const MiAutocuidadoSaludable1 = [
    {
      nombre: "HabitosVidaSaludable.pdf",
      nombreFront: "Hábitos de vida saludable para mejorar la calidad de vida",
      contenido: "presentacion",
      pagina: 1,
    },
    {
      nombre: "GestionSalud.pdf",
      nombreFront: "Estrategias para la gestión de mi salud",
      contenido: "infografia",
      pagina: 1,
    },
    {
      nombre: "HabitosAlimenticios.pdf",
      nombreFront: "Hábitos alimenticios",
      contenido: "infografia",
      pagina: 1,
    },
    {
      nombre: "MisHabitosSaludable.pdf",
      nombreFront: "Mis hábitos de vida saludable",
      contenido: "tarea",
      pagina: 1,
    },
    {
      nombre: "MaterialComplementario.pdf",
      nombreFront: "Material complementario",
      contenido: "material_complementario",
      pagina: 1,
    },
  ];
  const MiAutocuidadoSaludable2 = [
    {
      nombre: "EjercicioSeguro.pdf",
      nombreFront: "Actividad física segura",
      contenido: "infografia",
      pagina: 2,
    },
    {
      nombre: "HigienePostural.pdf",
      nombreFront: "Higiene postural y ergonomía",
      contenido: "infografia",
      pagina: 2,
    },
    {
      nombre: "ActividadFisica.pdf",
      nombreFront: "Promoviendo la actividad física en la vida diaria",
      contenido: "infografia",
      pagina: 2,
    },
    {
      nombre: "MantenerseActivos.pdf",
      nombreFront: "Cómo mantener la motivación para hacer actividad física",
      contenido: "infografia",
      pagina: 2,
    },
  ];
  const MiAutocuidadoSaludable3 = [
    {
      nombre: "HigieneSueno.pdf",
      nombreFront: "Recomendaciones para una buena higiene del sueño",
      contenido: "infografia",
      pagina: 3,
    },
    {
      nombre: "MantenerRutinaSueno.pdf",
      nombreFront:
        "Estrategias para mantener y establecer una rutina del sueño",
      contenido: "infografia",
      pagina: 3,
    },
    {
      nombre: "AdaptacionEntornos.pdf",
      nombreFront: "Pautas para crear un ambiente de sueño",
      contenido: "infografia",
      pagina: 3,
    },
    {
      nombre: "CalidadSueno.pdf",
      nombreFront:
        "¿Cómo mejorar tu descanso y sueño? Pautas y recomendaciones",
      contenido: "infografia",
      pagina: 3,
    },
  ];
  const MiAutocuidadoSaludable4 = [
    {
      nombre: "ActividadesSignificativas.pdf",
      nombreFront: "La importancia de lo significativo en nuestra vida diaria",
      contenido: "presentacion",
      pagina: 4,
    },
    {
      nombre: "DescubriendoActividadesSignificativas.pdf",
      nombreFront: "Descubriendo mis actividades significativas",
      contenido: "tarea",
      pagina: 4,
    },
  ];
  const titulosMiAutocuidadoPlanificado = [
    t("MeCuido.TextoIntroduccion.Tema3"),
    "Recomendaciones para la gestión del tiempo",
  ];
  const MiAutocuidadoPlanificado = [
    {
      nombre: "PlanificacionVidaDiaria.pdf",
      nombreFront: "La planificación en la vida diaria",
      contenido: "presentacion",
      pagina: 1,
    },
    {
      nombre: "HerramientasPapel.pdf",
      nombreFront: "Herramientas para la planificación en papel",
      contenido: "infografia",
      pagina: 1,
    },
    {
      nombre: "HerramientasTIC.pdf",
      nombreFront: "Herramientas tecnológicas para la planificación",
      contenido: "infografia",
      pagina: 1,
    },
    {
      nombre: "PasosRutina.pdf",
      nombreFront: "Pasos para planificar mi rutina",
      contenido: "infografia",
      pagina: 1,
    },
    {
      nombre: "CreacionHorario.pdf",
      nombreFront: "Mi horario semanal",
      contenido: "tarea",
      pagina: 1,
    },
    {
      nombre: "LineaTiempo.pdf",
      nombreFront: "Mi línea del tiempo",
      contenido: "tarea",
      pagina: 1,
    },
    {
      nombre: "MaterialComplementarioPlanificado.pdf",
      nombreFront: "Material complementario",
      contenido: "material_complementario",
      pagina: 1,
    },
  ];

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Verificar si el correo electrónico no es nulo antes de llamar a la función
    if ((email.email, temaSeleccionado)) {
      switch (temaSeleccionado) {
        case "Recomendados":
          consulta_recomendados();
          setTextoIntroduccion(t("MeCuido.TextoIntroduccion.Recomendados"));
          setTema("Recomendados");
          setTitulo(titulosRecomendados);
          setAnterior(null);
          setSiguiente(null);
          break;
        case "Tema1.Video":
          setContenidos(MiAutocuidadoConsciente);
          setTextoIntroduccion(t("MeCuido.TextoIntroduccion.Tema1"));
          setTema("MI_AUTOCUIDADO_CONSCIENTE");
          setTitulo(titulosMiautocuidadoConsciente[0]);
          setSiguiente("/Mecuido/Tema1.1");
          setAnterior(null);
          break;
        case "Tema1.1":
          setContenidos(MiAutocuidadoConsciente);
          setTextoIntroduccion(t("MeCuido.TextoIntroduccion.Tema1"));
          setTema(null);
          setTitulo(titulosMiautocuidadoConsciente[1]);
          setSiguiente(null);
          setAnterior("/Mecuido/Tema1.Video");
          break;
        case "Tema2.Video":
          setContenidos(MiAutocuidadoSaludable1);
          setTextoIntroduccion(t("MeCuido.TextoIntroduccion.Tema2"));
          setTema("MI_AUTOCUIDADO_SALUDABLE");
          setTitulo(titulosMiautocuidadoSaludable[0]);
          setSiguiente("/Mecuido/Tema2.1");
          setAnterior(null);
          break;
        case "Tema2.1":
          setContenidos(MiAutocuidadoSaludable1);
          setTextoIntroduccion(t("MeCuido.TextoIntroduccion.Tema2"));
          setTema(null);
          setTitulo(titulosMiautocuidadoSaludable[1]);
          setSiguiente("/Mecuido/Tema2.2");
          setAnterior("/Mecuido/Tema2.Video");
          break;
        case "Tema2.2":
          setContenidos(MiAutocuidadoSaludable2);
          setTextoIntroduccion(t("MeCuido.TextoIntroduccion.Tema2"));
          setTema(null);
          setTitulo(titulosMiautocuidadoSaludable[2]);
          setSiguiente("/Mecuido/Tema2.3");
          setAnterior("/Mecuido/Tema2.1");
          break;
        case "Tema2.3":
          setContenidos(MiAutocuidadoSaludable3);
          setTextoIntroduccion(t("MeCuido.TextoIntroduccion.Tema2"));
          setTema(null);
          setTitulo(titulosMiautocuidadoSaludable[3]);
          setSiguiente("/Mecuido/Tema2.4");
          setAnterior("/Mecuido/Tema2.2");
          break;
        case "Tema2.4":
          setContenidos(MiAutocuidadoSaludable4);
          setTextoIntroduccion(t("MeCuido.TextoIntroduccion.Tema2"));
          setTema(null);
          setTitulo(titulosMiautocuidadoSaludable[4]);
          setSiguiente(null);
          setAnterior("/Mecuido/Tema2.3");
          break;
        case "Tema3.Video":
          setContenidos(MiAutocuidadoPlanificado);
          setTextoIntroduccion(t("MeCuido.TextoIntroduccion.Tema3"));
          setTema("MI_AUTOCUIDADO_PLANIFICADO");
          setTitulo(titulosMiAutocuidadoPlanificado[0]);
          setSiguiente("/Mecuido/Tema3.1");
          setAnterior(null);
          break;
        case "Tema3.1":
          setContenidos(MiAutocuidadoPlanificado);
          setTextoIntroduccion(t("MeCuido.TextoIntroduccion.Tema3"));
          setTema(null);
          setTitulo(titulosMiAutocuidadoPlanificado[1]);
          setSiguiente(null);
          setAnterior("/Mecuido/Tema3.Video");
          break;

        default:
      }
    }
  }, [email, temaSeleccionado]);

  const asignarPaginas = (contenidos) => {
    const contenidosConPagina = contenidos.map((contenido, index) => {
      const pagina = Math.floor(index / 8);
      return { ...contenido, pagina };
    });
    return contenidosConPagina;
  };

  const consulta_recomendados = async () => {
    try {
      const response = await axios.get(
        t("Recursos.ip") + "/recomendaciones/autocuidado",
        {
          params: {
            email: email.email,
          },
        }
      );
      console.log("Mecuido recomendaciones: ", response.data);
      const contenidosConPagina = asignarPaginas(response.data);
      setContenidos(contenidosConPagina);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const temaanterior = () => {
    navigate(anterior);
  };
  const temasiguiente = () => {
    navigate(siguiente);
  };
  return (
    <div>

      <TituloyFlecha
        title={t("Mecuido")}
        link={"/"}
      />

      <div className="botonesalienadosMecuido">
        <Link to="/Mecuido/Recomendados" style={{ flex: "1" }}>
          <button
            className={
              temaSeleccionado === "Recomendados"
                ? "botonTemaSeleccionadoMecuido"
                : "botonTemaDeseleccionadoMecuido"
            }
          >
            &nbsp; {t("Recomendados")} &nbsp;&nbsp;{" "}
            <FaStar style={{ marginBottom: "2%" }} /> &nbsp;
          </button>
        </Link>
        <Link to="/Mecuido/Tema1.Video" style={{ flex: "1" }}>
          <button
            className={
              temaSeleccionado === "Tema1.Video" ||
                temaSeleccionado === "Tema1.1"
                ? "botonTemaSeleccionadoMecuido"
                : "botonTemaDeseleccionadoMecuido"
            }
          >
            {t("Mi-Autocuidado-Consciente")}
          </button>
        </Link>
        <Link to="/Mecuido/Tema2.Video" style={{ flex: "1" }}>
          <button
            className={
              temaSeleccionado === "Tema2.Video" ||
                temaSeleccionado === "Tema2.1" ||
                temaSeleccionado === "Tema2.2" ||
                temaSeleccionado === "Tema2.3" ||
                temaSeleccionado === "Tema2.4"
                ? "botonTemaSeleccionadoMecuido"
                : "botonTemaDeseleccionadoMecuido"
            }
          >
            {t("Mi-Autocuidado-Saludable")}
          </button>
        </Link>
        <Link to="/Mecuido/Tema3.Video" style={{ flex: "1" }}>
          <button
            className={
              temaSeleccionado === "Tema3.Video" ||
                temaSeleccionado === "Tema3.1"
                ? "botonTemaSeleccionadoMecuido"
                : "botonTemaDeseleccionadoMecuido"
            }
          >
            {t("Mi-autocuidado-planificado")}
          </button>
        </Link>
      </div>



      {window.innerWidth > 800 ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            maxWidth: "70%",
            minWidth: "70%",
            margin: "auto",
            height: "75vh"
          }}
        >
          <button
            className="botoncircPaginacionTemas"
            onClick={() => temaanterior()}
            style={{
              marginRight: "5%",
              visibility: anterior ? "visible" : "hidden",
              height: "7vh",
              width: "7vh",
              verticalAlign: "center",
              margin: "auto",
              borderRadius: "50px"
            }}
          >
            <TfiArrowCircleLeft style={{ fontSize: "3vh" }} />

          </button>
          {temaSeleccionado === "Recomendados" ? (
            <PaginacionTemas
              contenidos={contenidos}
              tema={tema}
              titulosPagina={titulo}
            />
          ) : (
            <AgrupacionContenidos
              contenidos={contenidos}
              tema={tema}
              titulosPagina={titulo}
            />
          )}
          <button
            className="botoncircPaginacionTemas"
            onClick={() => temasiguiente()}
            style={{
              marginLeft: "5%",
              visibility: siguiente ? "visible" : "hidden",
              height: "7vh",
              width: "7vh",
              verticalAlign: "center",
              margin: "auto",
              borderRadius: "50px"
            }}
          >
            <TfiArrowCircleRight style={{ fontSize: "3vh" }} />

          </button>
        </div>
      ) : (
        <div style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          maxWidth: "100%",
          minWidth: "100%",
          margin: "auto"
        }}>


          {temaSeleccionado === "Recomendados" ? (
            <PaginacionTemas
              contenidos={contenidos}
              tema={tema}
              titulosPagina={titulo}
            />
          ) : (
            <AgrupacionContenidos
              contenidos={contenidos}
              tema={tema}
              titulosPagina={titulo}
            />
          )}
          <div style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "2%"
          }}>
            <button
              className="botoncircPaginacionTemas"
              onClick={() => temaanterior()}
              style={{
                marginRight: "5%",
                visibility: anterior ? "visible" : "hidden",
                height: "7vh",
                width: "7vh",
                verticalAlign: "center",
                margin: "auto",
                borderRadius: "50px"
              }}
            >
              <TfiArrowCircleLeft style={{ fontSize: "3vh" }} />

            </button>
            <button
              className="botoncircPaginacionTemas"
              onClick={() => temasiguiente()}
              style={{
                marginLeft: "5%",
                visibility: siguiente ? "visible" : "hidden",
                height: "7vh",
                width: "7vh",
                verticalAlign: "center",
                margin: "auto",
                borderRadius: "50px"
              }}
            >
              <TfiArrowCircleRight style={{ fontSize: "3vh" }} />

            </button>
          </div>

        </div>
      )}



    </div>
  );
};

export default Mecuido;
