import { useState, useContext, useEffect } from "react";
import LinesChart from "../../components/Graficos/LinesChart";
import UserContext from "../../UserContext";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import axios from "axios";
import Card from "../../components/Card";
import "./PulsacionesPage.css";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import TituloyFlecha from "../../components/TituloyFlecha";
const PulsacionesPage = () => {
  const email = useContext(UserContext);
  const [t] = useTranslation("global");
  const [chartData, setChartData] = useState(null);
  const [valores_dia, setValores_dia] = useState(null);
  const [minutos_dia, setMinutos_dia] = useState(null);
  const [fecha, setFecha] = useState(null);
  const [nfecha, setNfecha] = useState(0);
  const [listadias, setListadias] = useState(null);
  const [periodicidad] = useState(30);//no sé por qué pero tienes que poner la mitad de lo que quieres que se muestre en el eje x :)

  useEffect(() => {
    // Verificar si el correo electrónico no es nulo antes de llamar a la función
    if (email.email) {
      consulta_pulsaciones();
    }
  }, [email]);

  useEffect(() => {
    if (valores_dia && minutos_dia) {
      setChartData(getChartData());
    }
  }, [valores_dia, minutos_dia]);

  const consulta_pulsaciones = async () => {
    const currentDate = new Date();
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(currentDate.getDate() - 60);
    const formattedCurrentDate = currentDate.toISOString().split("T")[0];
    const formattedOneWeekAgo = oneWeekAgo.toISOString().split("T")[0];

    try {
      const response = await axios.get(
        t("Recursos.ip") + "/pulsaciones",
        {
          params: {
            email: email.email,
            startTime: formattedOneWeekAgo,
            endTime: formattedCurrentDate,
          },
        }
      );
      ////Console.log("Pulsaciones: ", response.data);

      const listad = agruparPorDiaYOrdenar(response.data);
      setListadias(listad);
      setFecha(listad[0].fecha);
      const valores = obtenerFpValPorPosicion(listad, 0);
      setValores_dia(calcularMediaCadaIntervalo(valores, periodicidad));
      setMinutos_dia(minutosDelDiaConIntervalo(periodicidad));
    } catch (error) {
      console.error("Error:", error);
    }
  };

  function agruparPorDiaYOrdenar(array) {
    // Utilizamos reduce para agrupar por día
    const agrupadoPorDia = array.reduce((resultado, elemento) => {
      // Extraemos la fecha del timestamp
      const fecha = new Date(elemento.timestamp).toLocaleDateString();

      // Si la fecha no está en el resultado, la inicializamos con un array vacío
      if (!resultado[fecha]) {
        resultado[fecha] = [];
      }

      // Agregamos el elemento al array correspondiente a la fecha
      resultado[fecha].push(elemento);

      return resultado;
    }, {});

    // Obtenemos las fechas únicas y las ordenamos de manera descendente
    const fechasOrdenadas = Object.keys(agrupadoPorDia).sort(
      (a, b) => new Date(b) - new Date(a)
    );

    // Creamos un array final ordenado por fecha
    const resultadoFinal = fechasOrdenadas.map((fecha) => ({
      fecha,
      datos: agrupadoPorDia[fecha],
    }));

    return resultadoFinal;
  }

  function fpval_ultimodia(data) {
    // Obtén la fecha más reciente
    const latestDate = new Date(
      Math.max(...data.map((item) => new Date(item.timestamp)))
    );

    // Crea un objeto para almacenar los valores de datos para cada minuto del día
    const minuteData = {};

    // Llena el objeto con valores existentes de datos
    data.forEach((item) => {
      const itemDate = new Date(item.timestamp);
      if (itemDate.toDateString() === latestDate.toDateString()) {
        const hours = itemDate.getHours();
        const minutes = itemDate.getMinutes();
        const key = `${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes
          }`;
        minuteData[key] = item.fpVal;
      }
    });

    // Crea el array resultante con 1440 elementos, rellenando con 0 cuando no hay datos
    const resultArray = [];
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 60; j++) {
        const key = `${i < 10 ? "0" + i : i}:${j < 10 ? "0" + j : j}`;
        const fpVal = minuteData[key] || 0;
        resultArray.push({
          timestamp: `${latestDate.getFullYear()}-${latestDate.getMonth() + 1
            }-${latestDate.getDate()} ${key}:00.001`,
          fpVal,
        });
      }
    }
    return resultArray;
  }
  function obtenerFpValPorPosicion(listaCompleta, posicion) {
    // Obtener el conjunto de datos correspondiente a la posición indicada
    const datosDiaSeleccionado = listaCompleta[posicion]?.datos;

    if (datosDiaSeleccionado) {
      // Aplicar la función fpval_ultimodia al conjunto de datos del día seleccionado
      const resultadoDia = fpval_ultimodia(datosDiaSeleccionado);


      // Devolver el resultado
      return resultadoDia;
    } else {
      ////Console.log("No se encontraron datos para la posición indicada.");
      return [];
    }
  }
  function calcularMediaCadaIntervalo(datos, minutosIntervalo) {
    const mediaArray = [];
    let sum = 0;
    let count = 0;

    for (let i = 0; i < datos.length; i++) {
      const valor = datos[i].fpVal;

      // Si el valor no es 0, agrégalo a la suma
      if (valor !== 0) {
        sum += valor;
        count++;
      }

      // Si ha pasado el intervalo de tiempo especificado o hemos llegado al final de los datos
      if (
        i === datos.length - 1 ||
        (i > 0 && (i + 1) % minutosIntervalo === 0)
      ) {
        // Calcula la media para el intervalo actual (o menos si es el final de los datos)
        if (count > 0) {
          const media = sum / count;
          mediaArray.push(media);
        } else {
          // Si no hubo valores distintos de 0 en el intervalo actual, agrega NaN
          mediaArray.push(0);
        }
        // Reinicia el contador y la suma
        count = 0;
        sum = 0;
      }
    }

    return mediaArray;
  }

  const minutosDelDiaConIntervalo = (intervaloMinutos) => {
    const minutos = [];

    for (let hora = 0; hora < 24; hora++) {
      for (let minuto = 0; minuto < 60; minuto += intervaloMinutos) {
        const horaFormateada = hora < 10 ? `0${hora}` : `${hora}`;
        const minutoFormateado = minuto < 10 ? `0${minuto}` : `${minuto}`;
        minutos.push(`${horaFormateada}:${minutoFormateado}`);
      }
    }

    return minutos;
  };

  function calcularMediaSinCeros(arr) {
    let sum = 0;
    let count = 0;

    for (let i = 0; i < arr.length; i++) {
      if (arr[i] !== 0) {
        sum += arr[i];
        count++;
      }
    }

    if (count === 0) {
      return 0.0; // En caso de que no haya valores distintos de 0 en el array
    }

    return (sum / count).toLocaleString("es-ES", { minimumFractionDigits: 0, maximumFractionDigits: 1 });
  }

  function cambiarNfecha(n) {
    ////Console.log("lista", listadias);
    if ((n < 0 && nfecha > 0) || (n > 0 && nfecha < listadias.length - 1)) {
      setFecha(listadias[nfecha + n].fecha);
      const valores = obtenerFpValPorPosicion(listadias, nfecha + n);

      setValores_dia(calcularMediaCadaIntervalo(valores, periodicidad));
      setNfecha(nfecha + n);
    }
  }

  const getChartData = () => {
    return {
      labels: minutos_dia,
      datasets: [
        {
          label: t("pulsaciones-bpm"),
          data: valores_dia,
          tension: 0.5,
          fill: true,
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgba(255, 99, 132, 0.5)",
          pointRadius: 1,
          pointBorderColor: "rgba(255, 99, 132)",
          pointBackgroundColor: "rgba(255, 99, 132)",
        },
      ],
    };
  };

  const misoptions = {
    plugins: {
      legend: {
        display: false, // Deshabilita la leyenda (incluido el botón para ocultar líneas)
      },
    },
    scales: {
      y: {
        min: 0,
        title: {
          display: true,
          text: t("pulsaciones-bpm"),
          color: "black",
        },
      },
      x: {
        title: {
          display: true,
          text: t("Hora"),
          color: "black",
        },
        ticks: { color: "black" },
      },
    },
  };

  return (
    <div>
      <TituloyFlecha
        title={t("Mi_Frecuencia_Cardiaca")}
        link={"/MiSalud"}
      />
    
      <div style={{ marginTop: "1%", marginBottom: "1%", margin: window.innerWidth <= 768 ? "3%" : "1%" }}>
        <button className="botongranuralidadPulsacionesPage" style={{ backgroundColor: "#518ea6", color: "white" }}>{t("Dia")} </button>
        <Link to="/FrecuenciaCardiaca/semana">
          <button className="botongranuralidadPulsacionesPage">{t("Semana")}</button>
        </Link>
        <Link to="/FrecuenciaCardiaca/mes">
          <button className="botongranuralidadPulsacionesPage">{t("Mes")}</button>
        </Link>
        <Link to="/FrecuenciaCardiaca/ano">
          <button className="botongranuralidadPulsacionesPage">{t("Ano")}</button>
        </Link>
      </div>

      <div className="containerBodyPulsaciones">

        {chartData && fecha ? (
          <div className="containerGrafFechaPulsaciones">
            <div className="centerPulsacionesPage1">
              {nfecha < listadias.length - 1 ? (
                <div style={{ visibility: "visible" }}>
                  <button className="botoncircPulsacionesPage" onClick={() => cambiarNfecha(1)}>{t("Anterior")}</button>
                </div>
              ) : (
                <div style={{ visibility: "hidden" }}>
                  <button onClick={() => cambiarNfecha(1)}>{t("Anterior")}</button>
                </div>
              )}
              <p style={{ fontSize: "1.1rem", color: "black", width: "150%", marginLeft: "-25%" }}>
                <b> {" "}{fecha}{" "}</b>
              </p>
              {nfecha > 0 ? (
                <div style={{ visibility: "visible" }}>
                  <button className="botoncircPulsacionesPage" onClick={() => cambiarNfecha(-1)}>{t("Siguiente")}</button>
                </div>
              ) : (
                <div style={{ visibility: "hidden" }}>
                  <button onClick={() => cambiarNfecha(-1)}>{t("Siguiente")}</button>
                </div>
              )}
            </div>
            <div style={{ height: "100%" }}>
              <div className="graficoPulsacionesPage">
                <LinesChart data={chartData} options={misoptions} />
              </div>
              <div
                style={{
                  color: "black",
                  alignContent: "center",
                  marginTop: "1%",
                }}
              >

              </div>
            </div>
          </div>
        ) : (
          <div style={{ width: "100%", marginTop: "10%", marginRight: "15%" }}>
            <b>{t("Conectando-BD")}</b>
          </div>
        )}
        <div className="centerPulsacionePage3" style={{ marginBottom: "2%" }}>
          <Card
            content={
              <div style={{ color: "black" }}>
                <h3 style={{ textAlign: "center", marginTop: "0.5%", }} className="titulocardsPulsacionesPage">
                  {t("Frecuencia-cardiaca-del-dia")}{" "}
                </h3>

                {/* Elemento arriba */}
                <div style={{ textAlign: "center", marginTop: "0%" }}>
                  <b>{t("Frecuencia_cardiaca_media")}</b>
                  <br />
                  {chartData && fecha ? (
                    <div>{calcularMediaSinCeros(valores_dia).toLocaleString("es-ES")} {t("bpm")}</div>
                  ) : (
                    <div>{t("Conectando-BD")}</div>
                  )}
                  <p>

                  </p>
                </div>

                {/* Dos elementos debajo */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "1%",
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                      textAlign: "left",
                      marginLeft: "3%",
                    }}
                  >
                    <b>{t("Pulsaciones-minimas")}</b>
                    <br />
                    {chartData && fecha ? (
                      <div>{obtenerFpValPorPosicion(listadias, nfecha).reduce(
                        (minimo, minuto) => {
                          // Ignora los valores cero al buscar el mínimo
                          if (minuto.fpVal !== 0) {
                            return minuto.fpVal < minimo
                              ? minuto.fpVal
                              : minimo;
                          }
                          return minimo;
                        },
                        Infinity
                      )}  {t("bpm")}
                      </div>
                    ) : (
                      <div>
                        {t("Conectando-BD")}
                      </div>
                    )}

                  </div>
                  <div
                    style={{
                      flex: 1,
                      textAlign: "right",
                      marginRight: "3%",
                    }}
                  >
                    <b> {t("Pulsaciones-maximas")}</b>
                    <br />
                    {chartData && fecha ? (
                      <div>{obtenerFpValPorPosicion(listadias, nfecha).reduce(
                        (maximo, minuto) => {
                          // Compara el valor actual con el máximo actual y devuelve el mayor
                          return minuto.fpVal > maximo
                            ? minuto.fpVal
                            : maximo;
                        },
                        -Infinity
                      )}  {t("bpm")} </div>
                    ) : (
                      <div>{t("Conectando-BD")}</div>
                    )}

                  </div>

                </div>
              </div>
            }
            className={"cardPulsacionesPage"}
          ></Card>
        </div>
      </div>
      <br></br>
    </div>
  );
};
export default PulsacionesPage;
