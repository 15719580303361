import React, { useState, useEffect } from "react";
import "./PreguntaRespuestaCuadrados.css";
import { useTranslation } from "react-i18next";
import "./CheckboxesCuestionarioSD.css";
function CheckboxesCuestionarioSD({
  preguntas,
  aclaracion,
  respuestasComunes,
  onRespuestaSubmit,
  respuestasprevias,
}) {
  // Asegurarse de que respuestasprevias es un array
  respuestasprevias = respuestasprevias || [];

  // Crear el objeto inicial para las respuestas seleccionadas y extraer "Otros" si está presente
  const respuestasSeleccionadasInicial = {};
  let otrosTextosIniciales = {};

  preguntas.forEach((pregunta, index) => {
    const respuestaPrevia = respuestasprevias[index] || "";
    if (respuestaPrevia.includes("Otros + ")) {
      // Extraer la parte específica de la respuesta "Otros"
      const [otros, especifico] = respuestaPrevia.split(" + ");
      respuestasSeleccionadasInicial[pregunta] = otros.trim();
      otrosTextosIniciales[pregunta] = especifico.trim();
    } else {
      respuestasSeleccionadasInicial[pregunta] = respuestaPrevia;
    }
  });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [respuestasSeleccionadas, setRespuestasSeleccionadas] = useState(
    respuestasSeleccionadasInicial
  );
  const [otrosTextos, setOtrosTextos] = useState(otrosTextosIniciales);
  const [t] = useTranslation("global");

  useEffect(() => {
    // Crear un objeto para almacenar las respuestas seleccionadas
    const respuestasSeleccionadasActualizadas = {};

    // Iterar sobre las preguntas y asignar las respuestas previas correspondientes
    preguntas.forEach((pregunta, index) => {
      // Verificar si hay una respuesta previa para esta pregunta
      const respuestaPrevia = respuestasprevias[index];
      // Asignar la respuesta previa si existe, de lo contrario asignar una cadena vacía
      respuestasSeleccionadasActualizadas[pregunta] =
        respuestaPrevia !== undefined ? respuestaPrevia : "";
      onRespuestaSubmit(pregunta, respuestaPrevia);
    });
  }, []); // Este efecto se ejecutará cuando las respuestas previas cambien

  useEffect(() => {
    // Escucha el evento de cambio de tamaño de la ventana y actualiza el ancho de la ventana
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      // Limpia el evento de cambio de tamaño al desmontar el componente
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  let estiloRespuesta,
    estiloTabla,
    estiloPregunta,
    estiloRespuestasContainer,
    estiloSeparador,
    estiloLinea;

  if (window.innerWidth >= 768) {
    // Estilo para pantalla grande
    estiloRespuesta = {
      padding: "3px",
      marginBottom: "5px",
      marginRight: "15px",
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "10%",
    };

    estiloTabla = {
      width: "100%",
      marginLeft: "4%",
    };

    estiloPregunta = {
      fontSize: "20px",
      whiteSpace: "pre-line",
      textAlign: "justify",
      marginLeft: "5%",
    };

    estiloRespuestasContainer = {
      textAlign: "justify",
      display: "flex",
    };

    estiloSeparador = {
      margin: "15px 0",
    };

    estiloLinea = {
      marginLeft: "5px",
      marginBottom: "5px",
    };
  } else {
    // Estilo para pantalla pequeña (móvil)
    estiloRespuesta = {
      padding: "5px",
      marginBottom: "5px",
      display: "flex",
      alignItems: "center",
      marginLeft: "5%"
    };

    estiloTabla = {
      width: "100%",
      marginLeft: "0%",
    };

    estiloPregunta = {
      fontSize: "18px",
      whiteSpace: "pre-line",
      textAlign: "justify",
      marginLeft: "5%",
      marginRight: "3%"
    };

    estiloSeparador = {
      margin: "15px 0",
    };

    estiloLinea = {
      marginLeft: "5px",
      marginBottom: "5px",
    };
  }

  const handleRespuestaSubmit = (pregunta, respuesta) => {
    const isOtros = respuesta === t("Otros") || respuesta === t("Otro");

    if (isOtros) {
      const valorTextoOtros = otrosTextos[pregunta] || "";
      setRespuestasSeleccionadas({
        ...respuestasSeleccionadas,
        [pregunta]: respuesta,
      });
      onRespuestaSubmit(pregunta, respuesta + " + " + valorTextoOtros);
    } else {
      const valorRespuesta =
        respuesta === respuestasSeleccionadas[pregunta] ? "" : respuesta;
      setRespuestasSeleccionadas({
        ...respuestasSeleccionadas,
        [pregunta]: valorRespuesta,
      });
      onRespuestaSubmit(pregunta, valorRespuesta);
    }
  };

  const handleOtrosTextChange = (pregunta, text) => {
    setOtrosTextos({
      ...otrosTextos,
      [pregunta]: text,
    });
    setRespuestasSeleccionadas({
      ...respuestasSeleccionadas,
      [pregunta]: t("Otros"),
    });
    onRespuestaSubmit(pregunta, t("Otros") + " + " + text);
  };

  return (
    <div>
      {preguntas.map((pregunta, index) => (
        <div key={index}>
          <div style={estiloPregunta}>
            <b>{pregunta}</b>
            {aclaracion}
          </div>
          <table style={estiloTabla}>
            <tbody>
              <tr>
                <td style={estiloRespuestasContainer}>
                  {respuestasComunes.map((respuesta, respuestaIndex) => (
                    <label key={respuestaIndex} style={estiloRespuesta}>
                      <label className="checkbox">
                        <input
                          type="checkbox"
                          className="checkbox__input"
                          checked={
                            respuesta === respuestasSeleccionadas[pregunta]
                          }
                          onChange={() =>
                            handleRespuestaSubmit(pregunta, respuesta)
                          }
                        />
                        <span className="checkbox__inner"></span>
                      </label>

                      <div className="respuestaChecknoxes">
                        {windowWidth > 800 ? (
                          <>
                            {respuesta.split("\n").map((line, lineIndex) => (
                              <span key={lineIndex} style={estiloLinea}>
                                {line}
                              </span>
                            ))}
                          </>
                        ) : (
                          <span style={estiloLinea}>{respuesta}</span>
                        )}
                        {(respuesta === t("Otros") ||
                          respuesta === t("Otro")) &&
                          respuesta === respuestasSeleccionadas[pregunta] && (
                            <input
                              type="text"
                              value={otrosTextos[pregunta] || ""}
                              onChange={(e) =>
                                handleOtrosTextChange(pregunta, e.target.value)
                              }
                              placeholder={t("Especifique") + "..."}
                              style={{ marginLeft: "10px", width: "150px" }}
                            />
                          )}
                      </div>
                    </label>
                  ))}
                </td>
              </tr>
            </tbody>
          </table>
          {index < preguntas.length - 1 && <div style={estiloSeparador}></div>}
        </div>
      ))}
    </div>
  );
}

export default CheckboxesCuestionarioSD;
