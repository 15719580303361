// Publicidad.js
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import publicidad from "../Images/publicidad.png";
import "./Publicidad.css";

function Publicidad() {
  const [t] = useTranslation("global");



  return (
    <div className="contenedor-publicidad" >
      <img
        className="imagen-publicidad "
        src={publicidad}
        alt={t("NextGeneration-texto")}
      />
      <p style={{ fontSize: "1vh", zIndex: "1", marginTop: "0.5%", color: "white", marginBottom: "0.1%", marginLeft: "1%" }}>
        {t("NextGeneration-texto1")}
        {t("NextGeneration-texto2")}
        {t("NextGeneration-texto3")}
      </p>
    </div>
  );
}

export default Publicidad;
