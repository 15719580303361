import { useEffect, useContext, useState } from "react";

import UserContext from "../../UserContext";
import { useNavigate } from "react-router-dom";
import CheckboxesCuestionarioSD from "../../components/Cuestionarios/CheckboxesCuestionarioSD";
import PreguntaRespuesta from "../../components/Cuestionarios/PreguntaRespuesta";
import Pregunta2Respuesta from "../../components/Cuestionarios/Pregunta2Respuesta";
import axios from "axios";
import { useTranslation } from "react-i18next";
import "./CuestionarioSocioDemo.css";

const CuestionarioSocioDemo1 = () => {
  const email = useContext(UserContext);
  const navigate = useNavigate();
  const { mostrar, setMostrar } = useContext(UserContext);
  const [t] = useTranslation("global");
  const [todoRespondido, SetTodoRespondido] = useState(true);
  const [userData, setUserData] = useState(null);
  const [errores, setErrores] = useState([]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [navigate]);

  useEffect(() => {
    if (email.email) {
      console.log("Email", email.email);
      const fetchUserData = async () => {
        try {
          const response = await axios.get(
            t("Recursos.ip") + "/accounts/getinfodetallada",
            {
              params: {
                email: email.email,
              },
            }
          );
          console.log(response.data);
          setUserData(response.data);
        } catch (error) {
          console.error("Error al obtener datos del usuario:", error);
        }
      };
      fetchUserData();
    }
  }, [email]);

  const preguntas = [
    t("cuestionarioSocioDemoyPersonal.preg12"),
    t("cuestionarioSocioDemoyPersonal.preg13"),
    t("cuestionarioSocioDemoyPersonal.preg14"),
    t("cuestionarioSocioDemoyPersonal.preg15"),
    t("cuestionarioSocioDemoyPersonal.preg16"),
  ];

  const [respuestas, setRespuestas] = useState({});

  const handleRespuestaSubmit = (pregunta, respuesta) => {
    setRespuestas((prevRespuestas) => ({
      ...prevRespuestas,
      [pregunta]: respuesta,
    }));
  };

  const enviarRespuestas = async () => {
    const miArrayRespuestas = preguntas.map((pregunta) => ({
      clave: pregunta,
      valor: respuestas[pregunta] || "",
    }));

    const primeraParteResultado = miArrayRespuestas.map((item) => item.valor);

    // Validar respuestas numéricas para las preguntas 2 y 3
    const nuevaErrores = ["", "", "", "", ""];
    console.log("dw", primeraParteResultado[0])
    if (
      
      primeraParteResultado[0].length === 0
    ) {
      nuevaErrores[0] = t("cuestionarioSocioDemoyPersonal.error0");
      nuevaErrores.push("W");
    } else {
      nuevaErrores[0] = "";
    }
    if (
      !/^\d*\.?\d*$/.test(primeraParteResultado[1]) ||
      primeraParteResultado[1].length === 0
    ) {
      nuevaErrores[1] = t("cuestionarioSocioDemoyPersonal.error1");
      nuevaErrores.push("W");
    } else {
      nuevaErrores[1] = "";
    }
    if (
      !/^\d*\.?\d*$/.test(primeraParteResultado[2]) ||
      primeraParteResultado[2].length === 0
    ) {
      nuevaErrores[2] = t("cuestionarioSocioDemoyPersonal.error2");
      nuevaErrores.push("W");
    } else {
      nuevaErrores[2] = "";
    }
    if (
      !/^\d*\.?\d*$/.test(primeraParteResultado[3]) ||
      primeraParteResultado[3].length === 0
    ) {
      nuevaErrores[3] = t("cuestionarioSocioDemoyPersonal.error3");
      nuevaErrores.push("W");
    } else {
      nuevaErrores[3] = "";
    }

    if (
      !/^\d*\.?\d*$/.test(primeraParteResultado[4]) ||
      primeraParteResultado[4].length === 0
    ) {
      nuevaErrores[4] = t("cuestionarioSocioDemoyPersonal.error4");
      nuevaErrores.push("W");
    } else {
      nuevaErrores[4] = "";
    }
    setErrores(nuevaErrores);

    if (nuevaErrores.length <= 5) {
      SetTodoRespondido(true);
      try {
        const response1 = await axios.post(
          t("Recursos.ip") + "/accounts/addDetails",
          {
            dominancia: primeraParteResultado[0],
            peso: primeraParteResultado[1],
            altura: primeraParteResultado[2],
            email: email.email,
          }
        );

        const response3_1 = await axios.post(t("Recursos.ip") + "/objetivo", {
          valor: primeraParteResultado[3],
          email: email.email,
          parametro: "pasos",
        });

        const response3_2 = await axios.post(t("Recursos.ip") + "/objetivo", {
          valor: primeraParteResultado[4],
          email: email.email,
          parametro: "sueno",
        });

        const response2 = await axios.get(
          t("Recursos.ip") + "/estadoanimo/cubiertohoy",
          {
            params: {
              email: email.email,
            },
          }
        );

        if (response2.data !== true) {
          navigate("/CuestionarioEstadoAnimo");
        } else {
          setMostrar(true);
          navigate("/pantalladeCarga");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      SetTodoRespondido(false);
    }
  };

  const anterior = async () => {
    navigate("/CuestionarioSocioDemo");
  };

  return (
    <div>
      <h1 style={{ marginTop: "1%" }}>{t("Informacion-personal-detallada")}</h1>
      {userData ? (
        <div className="cuestionarioContainer" style={{ marginTop: "1%" }}>
          <p
            style={{
              color: "#518EA6",
              fontWeight: "bold",
              fontSize: "1.5em",
              textAlign: "left",
              marginLeft: "5%",
              marginTop: "2%",
            }}
          >
            {t("cuestionarioSocioDemoyPersonal.Datos-pulsera")}
          </p>
          <CheckboxesCuestionarioSD
            preguntas={[preguntas[0]]}
            respuestasComunes={[
              t("cuestionarioSocioDemoyPersonal.RespuestasPulsera.1.1"),
              t("cuestionarioSocioDemoyPersonal.RespuestasPulsera.1.2"),
              t("cuestionarioSocioDemoyPersonal.RespuestasPulsera.1.3"),
            ]}
            onRespuestaSubmit={handleRespuestaSubmit}
            respuestasprevias={[userData.dominancia]}
          />
          <p style={{ textAlign: "left", marginLeft: "5%", color: "red", marginTop:"-1%" }}>
            {errores[0]}
          </p>
          <div style={{ marginTop: "-2%" }}>
            <PreguntaRespuesta
              pregunta={[t("cuestionarioSocioDemoyPersonal.preg13")]}
              aclaracion={[t("cuestionarioSocioDemoyPersonal.aclaracion13")]}
              onRespuestaSubmit={handleRespuestaSubmit}
              placeholder={"Kg"}
              respuestaprevia={userData.peso}
               ancho="100px"
            />
            <p style={{ textAlign: "left", marginLeft: "5%", color: "red", marginBottom:"-1%" }}>
              {errores[1]}
            </p>
          </div>
          <PreguntaRespuesta
            pregunta={[t("cuestionarioSocioDemoyPersonal.preg14")]}
            aclaracion={[t("cuestionarioSocioDemoyPersonal.aclaracion14")]}
            onRespuestaSubmit={handleRespuestaSubmit}
            placeholder={"Cm"}
            respuestaprevia={userData.altura}
             ancho="100px"
          />
          <p style={{ textAlign: "left", marginLeft: "5%", color: "red", marginBottom:"-1%" }}>
            {errores[2]}
          </p>
          <PreguntaRespuesta
            pregunta={[t("cuestionarioSocioDemoyPersonal.preg15")]}
            aclaracion={[t("cuestionarioSocioDemoyPersonal.aclaracion15")]}
            onRespuestaSubmit={handleRespuestaSubmit}
            placeholder={"Pasos"}
            respuestaprevia={userData.objetivoPasos}
            ancho="100px"
          />
          <p style={{ textAlign: "left", marginLeft: "5%", color: "red", marginBottom:"-1%" }}>
            {errores[3]}
          </p>
          <Pregunta2Respuesta
            pregunta={[t("cuestionarioSocioDemoyPersonal.preg16")]}
            aclaracion={[t("cuestionarioSocioDemoyPersonal.aclaracion16")]}
            onRespuestaSubmit={handleRespuestaSubmit}
            placeholder={t("cuestionarioSocioDemoyPersonal.Horas")}
            placeholder2={t("cuestionarioSocioDemoyPersonal.Minutos")}
            respuestaprevia={userData.objetivoSueno}
          />
          <p style={{ textAlign: "left", marginLeft: "5%", color: "red", marginBottom:"-1%" }}>
            {errores[4]}
          </p>
          {!todoRespondido && (
            <div>
              <p style={{ color: "red" }}>{t("Responde-todo")}</p>
            </div>
          )}
          <div>
            <button
              className="botoncirc"
              style={{
                width: window.innerWidth <= 768 ? "40%" : "20%",
                marginTop: "2%",
                marginRight: "5%",
              }}
              onClick={anterior}
            >
              {t("Atras")}
            </button>
            <button
              className="botoncirc"
              style={{
                width: window.innerWidth <= 768 ? "40%" : "20%",
                marginTop: "2%",
              }}
              onClick={enviarRespuestas}
            >
              {t("Confirmar")}
            </button>
          </div>
        </div>
      ) : (
        <div style={{ width: "100%", marginTop: "10%", marginRight: "15%" }}>
          <b>{t("Conectando-BD")}</b>
        </div>
      )}
    </div>
  );
};

export default CuestionarioSocioDemo1;
